import { Control, FieldValues } from 'react-hook-form';
import * as S from "./style"

export interface IOption {
    label: string,
    value: string | number
}

interface ISelect {
    id: string;
    name: string;
    error?: string | any;
    control: Control<FieldValues, object>;
    label?: string;
    required?: boolean;
    [key: string]: any;
    options: IOption[];
    isDefaultEnabled?: boolean;
}

const Select = ({
    id,
    name,
    error,
    control,
    label,
    required = false,
    options,
    isDefaultEnabled = false,
    ...rest
}: ISelect) => {
    return (
        <S.SelectContainer>
            {
                label && (
                    <S.StyledLabel htmlFor={id}>{label}</S.StyledLabel>
                )
            }
            <S.StyledSelect id={id} {...control?.register(name)} error={error} {...rest}>
                {
                    isDefaultEnabled ? (
                        <option>Selecione</option>
                    ) : (
                        <option disabled selected value="Selecione">Selecione</option>
                    )
                }
                {
                    options.map((item, index) => {
                        return (
                            <option key={index} value={item.value}>{item.label}</option>
                        )
                    })
                }
            </S.StyledSelect>
            {
                error && (
                    <S.StyledError>{error}</S.StyledError>
                )
            }
        </S.SelectContainer>
    )
}

export default Select