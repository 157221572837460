import * as S from "../../style"
import { useState, useEffect } from 'react';
import { obterListaRiscoFasePorCasoJuridico, obterListaDistribuicaoRiscoCasoJuridicoPorRiscoFase, salvarDistribuicaoCasoJuridicoPorRiscoFase, obterListaJustificativaCasoJuridicoPorRiscoFase, salvarJustificativaCasoJuridicoPorRiscoFase } from '../../../../services/api/CasoJuridico/index';
import Select from "../../../../components/Select";
import { ArrayPath, DeepPartial, DeepRequired, FieldArray, FieldError, FieldErrorsImpl, FieldValues, FormState, Path, PathValue, useForm, UseFormRegisterReturn, Validate, ValidationRule } from "react-hook-form";
import WithLabel from "../../../../components/WithLabel";
import Button from "../../../../components/Button";
import Container from "../../../../components/Container";
import PercentageInput from "../../../../components/PercentageInput";
import CurrencyInput from "../../../../components/CurrencyInput";
import ItemsList, { HeaderList, ListItem } from "../../../../components/ItemsList";
import { convertCurrency, convertToNumber } from '../../../../utils/index';
import { IObterListaDistribuicaoRiscoCasoJuridicoPorRiscoFaseResponse, ISalvarJustificativaCasoJuridicoPorRiscoFase } from '../../../../services/models/CasoJuridico/index';
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import Popup from '../../../../components/Popup/index';
import TextArea from "../../../../components/TextArea";
import ActionButton from "../../../../components/ActionButton";
import { FiEdit } from "react-icons/fi";
import Input from "../../../../components/Input";
import { usePermissionsStore } from "../../../../stores/PermissionsStore";

interface IDistribuicao {
    storedId: string;
    caseFaseProvisao: number | null;
}

interface ITotalExito {
    naoAtribuido: number;
    perdaProvavel: number;
    perdaPossivel: number;
    perdaRemota: number;
}

const blankTotalExito: ITotalExito = {
    naoAtribuido: 0,
    perdaProvavel: 0,
    perdaPossivel: 0,
    perdaRemota: 0
}

const blankCurrentItem = {
    id: null,
    idCaso: null,
    idRiscoFase: null,
    idRiscoTitulo: null,
    descricaoRiscoTitulo: null,
    valorTotal: null,
    percentualNaoAtribuido: null,
    percentualPerdaRemota: null,
    percentualPerdaProvavel: null,
    percentualPerdaPossivel: null
}

const testPercentage = (value: string) => {
    let vlr = parseInt(value)
    if ((vlr >= 0) && (vlr <= 100)) {
        return true
    }
    return false
}

const DistribuicaoSchema = yup.object({
    naoAtribuido: yup.string(),
    perdaProvavel: yup.string(),
    perdaPossivel: yup.string(),
    perdaRemota: yup.string()
})

const Distribuicao = ({
    storedId,
    caseFaseProvisao
}: IDistribuicao) => {
    const [listaFasePorCaso, setListaFasePorCaso] = useState<any>([])
    const [listaDistribuicao, setListaDistribuicao] = useState<any>([])
    const [totalExito, setTotalExito] = useState<ITotalExito>(blankTotalExito)
    const [currentItem, setCurrentItem] = useState<any>(blankCurrentItem)
    const [popupJustificativa, setPopupJustificativa] = useState<boolean>(false)
    const [listaJustificativa, setListaJustificativa] = useState<any>(null)
    const [justificativaSelecionada, setJustificativaSelecionada] = useState<any>(null)

    useEffect(() => {
        ObterListaRiscoFasePorCasoJuridico()
        setValue("fasesDoCaso", caseFaseProvisao)
    }, [])

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isDirty,
            isValid
        },
        trigger,
        getValues,
        setValue,
        register,
        getFieldState,
        reset,
        watch,
        setError,
        clearErrors
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(DistribuicaoSchema)
    })

    useEffect(() => {
        let fase = watch("fasesDoCaso")
        if (!fase) return;
        if (fase === 0) return;
        if (fase === null) return;
        setCurrentItem(blankCurrentItem)
        if (fase !== undefined && fase !== "Selecione") {
            ObterListaDistribuicaoRiscoCasoJuridicoPorRiscoFase(+fase)
            ObterListaJustificativaCasoJuridicoPorRiscoFase(+storedId, +fase)
        }
    }, [watch("fasesDoCaso")])

    useEffect(() => {
        if (!listaDistribuicao) return;
        if (listaDistribuicao.length <= 0) return

        let listaDistribuicaoAux = [...listaDistribuicao]

        let totalNaoAtribuido: number = 0
        let totalPerdaProvavel: number = 0
        let totalPerdaPossivel: number = 0
        let totalPerdaRemota: number = 0

        listaDistribuicaoAux.map((item: IObterListaDistribuicaoRiscoCasoJuridicoPorRiscoFaseResponse) => {
            totalNaoAtribuido = totalNaoAtribuido + (item.percentualNaoAtribuido / 100) * item.valorTotal
            totalPerdaProvavel = totalPerdaProvavel + (item.percentualPerdaProvavel / 100) * item.valorTotal
            totalPerdaPossivel = totalPerdaPossivel + (item.percentualPerdaPossivel / 100) * item.valorTotal
            totalPerdaRemota = totalPerdaRemota + (item.percentualPerdaRemota / 100) * item.valorTotal
        })

        setTotalExito({
            naoAtribuido: totalNaoAtribuido,
            perdaProvavel: totalPerdaProvavel,
            perdaPossivel: totalPerdaPossivel,
            perdaRemota: totalPerdaRemota
        })
    }, [listaDistribuicao])

    const ObterListaRiscoFasePorCasoJuridico = async () => {
        let res = await obterListaRiscoFasePorCasoJuridico(+storedId)
        setListaFasePorCaso(res)
    }

    const ObterListaDistribuicaoRiscoCasoJuridicoPorRiscoFase = async (idRiscoFase: number) => {
        let res = await obterListaDistribuicaoRiscoCasoJuridicoPorRiscoFase(+storedId, idRiscoFase)
        setListaDistribuicao(res)
        setCurrentItem(res[0])
    }

    const SaveCurrentItem = (id: number) => {
        let listaDistribuicaoAux = [...listaDistribuicao]
        let currentItemAux = { ...currentItem }

        listaDistribuicaoAux.forEach((item: any) => {
            if (item.id === id) {
                item.percentualNaoAtribuido = currentItemAux.percentualNaoAtribuido
                item.percentualPerdaProvavel = currentItemAux.percentualPerdaProvavel
                item.percentualPerdaPossivel = currentItemAux.percentualPerdaPossivel
                item.percentualPerdaRemota = currentItemAux.percentualPerdaRemota
            }
        })

        trigger()
        SalvarDistribuicaoCasoJuridicoPorRiscoFase()
    }

    useEffect(() => {
        let perdaProvavel = parseInt(watch("perdaProvavel"))

        let newItem = { ...currentItem }

        if (isNaN(perdaProvavel)) {
            perdaProvavel = 0
            setValue("perdaProvavel", "0")
        }

        newItem.percentualPerdaProvavel = perdaProvavel

        const naoAtribuido = newItem.percentualNaoAtribuido

        const somaTodos = newItem.percentualPerdaPossivel +
            newItem.percentualPerdaRemota +
            perdaProvavel

        if (somaTodos > 100) {
            newItem.percentualNaoAtribuido = 100 - perdaProvavel
            newItem.percentualPerdaPossivel = 0
            newItem.percentualPerdaRemota = 0
        } else {
            newItem.percentualNaoAtribuido = 100 - (perdaProvavel + newItem.percentualPerdaPossivel + newItem.percentualPerdaRemota)
        }

        if (perdaProvavel >= 100) {
            newItem.percentualNaoAtribuido = 0
            newItem.percentualPerdaPossivel = 0
            newItem.percentualPerdaRemota = 0
        }


        setCurrentItem(newItem)
    }, [watch("perdaProvavel")])

    useEffect(() => {
        let perdaPossivel = parseInt(watch("perdaPossivel"))


        if (isNaN(perdaPossivel)) {
            perdaPossivel = 0
            setValue("perdaPossivel", "0")
        }

        let newItem = { ...currentItem }

        newItem.percentualPerdaPossivel = perdaPossivel

        const naoAtribuido = newItem.percentualNaoAtribuido

        const somaTodos = newItem.percentualPerdaProvavel +
            newItem.percentualPerdaRemota +
            perdaPossivel

        if (somaTodos > 100) {
            newItem.percentualNaoAtribuido = 100 - perdaPossivel
            newItem.percentualPerdaProvavel = 0
            newItem.percentualPerdaRemota = 0
        } else {
            newItem.percentualNaoAtribuido = 100 - (
                perdaPossivel +
                newItem.percentualPerdaProvavel +
                newItem.percentualPerdaRemota
            )
        }

        if (perdaPossivel >= 100) {
            newItem.percentualNaoAtribuido = 0
            newItem.percentualPerdaProvavel = 0
            newItem.percentualPerdaRemota = 0
        }


        setCurrentItem(newItem)
    }, [watch("perdaPossivel")])

    useEffect(() => {
        let perdaRemota = parseInt(watch("perdaRemota"))

        let newItem = { ...currentItem }

        if (isNaN(perdaRemota)) {
            perdaRemota = 0
            setValue("perdaRemota", "0")
        }

        newItem.percentualPerdaRemota = perdaRemota

        const somaTodos = newItem.percentualPerdaProvavel +
            newItem.percentualPerdaPossivel +
            perdaRemota

        if (somaTodos > 100) {
            newItem.percentualNaoAtribuido = 100 - perdaRemota
            newItem.percentualPerdaProvavel = 0
            newItem.percentualPerdaPossivel = 0
        } else {
            newItem.percentualNaoAtribuido = 100 - (
                perdaRemota +
                newItem.percentualPerdaProvavel +
                newItem.percentualPerdaPossivel
            )
        }

        if (perdaRemota >= 100) {
            newItem.percentualNaoAtribuido = 0
            newItem.percentualPerdaProvavel = 0
            newItem.percentualPerdaPossivel = 0
        }

        setCurrentItem(newItem)
    }, [watch("perdaRemota")])

    useEffect(() => {
        let reaisPerdaProvavelString = watch("reaisPerdaProvavel")

        if (
            !reaisPerdaProvavelString ||
            isNaN(convertToNumber(reaisPerdaProvavelString))
        ) {
            setValue("reaisPerdaProvavel", "0")
            reaisPerdaProvavelString = "R$ 0,00"
        }

        let reaisPerdaProvavel = convertToNumber(reaisPerdaProvavelString)

        let newItem = { ...currentItem }

        let perdaProvavel = (100 * reaisPerdaProvavel) / newItem.valorTotal

        const naoAtribuido = newItem.percentualNaoAtribuido

        newItem.percentualPerdaProvavel = perdaProvavel

        const somaTodos = newItem.percentualPerdaRemota +
            newItem.percentualPerdaPossivel +
            perdaProvavel

        if (somaTodos > 100) {
            newItem.percentualNaoAtribuido = 100 - perdaProvavel
            newItem.percentualPerdaPossivel = 0
            newItem.percentualPerdaRemota = 0
        } else {
            newItem.percentualNaoAtribuido = 100 - (
                perdaProvavel +
                newItem.percentualPerdaPossivel +
                newItem.percentualPerdaRemota
            )
        }

        if (perdaProvavel >= 100) {
            newItem.percentualNaoAtribuido = 0
            newItem.percentualPerdaPossivel = 0
            newItem.percentualPerdaRemota = 0
        }

        setCurrentItem(newItem)
    }, [watch("reaisPerdaProvavel")])

    useEffect(() => {
        let reaisPerdaPossivelString = watch("reaisPerdaPossivel")

        if (
            !reaisPerdaPossivelString ||
            isNaN(convertToNumber(reaisPerdaPossivelString))
        ) {
            setValue("reaisPerdaPossivel", "0")
            reaisPerdaPossivelString = "R$ 0,00"
        }

        let reaisPerdaPossivel = convertToNumber(reaisPerdaPossivelString)

        let newItem = { ...currentItem }

        let perdaPossivel = (100 * reaisPerdaPossivel) / newItem.valorTotal

        const naoAtribuido = newItem.percentualNaoAtribuido

        newItem.percentualPerdaPossivel = perdaPossivel

        const somaTodos = newItem.percentualPerdaProvavel +
            newItem.percentualPerdaRemota +
            perdaPossivel

        if (somaTodos > 100) {
            newItem.percentualNaoAtribuido = 100 - perdaPossivel
            newItem.percentualPerdaProvavel = 0
            newItem.percentualPerdaRemota = 0
        } else {
            newItem.percentualNaoAtribuido = 100 - (
                perdaPossivel +
                newItem.percentualPerdaProvavel +
                newItem.percentualPerdaRemota
            )
        }

        if (perdaPossivel >= 100) {
            newItem.percentualNaoAtribuido = 0
            newItem.percentualPerdaProvavel = 0
            newItem.percentualPerdaRemota = 0
        }

        setCurrentItem(newItem)
    }, [watch("reaisPerdaPossivel")])

    useEffect(() => {
        let reaisPerdaRemotaString = watch("reaisPerdaRemota")

        if (
            !reaisPerdaRemotaString ||
            isNaN(convertToNumber(reaisPerdaRemotaString))
        ) {
            setValue("reaisPerdaRemota", "0")
            reaisPerdaRemotaString = "R$ 0,00"
        }

        let reaisPerdaRemota = convertToNumber(reaisPerdaRemotaString)

        let newItem = { ...currentItem }

        let perdaRemota = (100 * reaisPerdaRemota) / newItem.valorTotal

        const naoAtribuido = newItem.percentualNaoAtribuido

        newItem.percentualPerdaRemota = perdaRemota

        const somaTodos = newItem.percentualPerdaProvavel +
            newItem.percentualPerdaPossivel +
            perdaRemota

        if (somaTodos > 100) {
            newItem.percentualNaoAtribuido = 100 - perdaRemota
            newItem.percentualPerdaProvavel = 0
            newItem.percentualPerdaPossivel = 0
        } else {
            newItem.percentualNaoAtribuido = 100 - (
                perdaRemota +
                newItem.percentualPerdaProvavel +
                newItem.percentualPerdaPossivel
            )
        }

        if (perdaRemota >= 100) {
            newItem.percentualNaoAtribuido = 0
            newItem.percentualPerdaProvavel = 0
            newItem.percentualPerdaPossivel = 0
        }

        setCurrentItem(newItem)
    }, [watch("reaisPerdaRemota")])

    const SalvarDistribuicaoCasoJuridicoPorRiscoFase = async () => {
        await salvarDistribuicaoCasoJuridicoPorRiscoFase(listaDistribuicao)

        let listaDistribuicaoAux = [...listaDistribuicao]

        let totalNaoAtribuido: number = 0
        let totalPerdaProvavel: number = 0
        let totalPerdaPossivel: number = 0
        let totalPerdaRemota: number = 0

        listaDistribuicaoAux.map((item: IObterListaDistribuicaoRiscoCasoJuridicoPorRiscoFaseResponse) => {
            totalNaoAtribuido = totalNaoAtribuido + (item.percentualNaoAtribuido / 100) * item.valorTotal
            totalPerdaProvavel = totalPerdaProvavel + (item.percentualPerdaProvavel / 100) * item.valorTotal
            totalPerdaPossivel = totalPerdaPossivel + (item.percentualPerdaPossivel / 100) * item.valorTotal
            totalPerdaRemota = totalPerdaRemota + (item.percentualPerdaRemota / 100) * item.valorTotal
        })

        setTotalExito({
            naoAtribuido: totalNaoAtribuido,
            perdaProvavel: totalPerdaProvavel,
            perdaPossivel: totalPerdaPossivel,
            perdaRemota: totalPerdaRemota
        })
    }

    const ObterListaJustificativaCasoJuridicoPorRiscoFase = async (idCaso: number, idRiscoFase: number) => {
        let res = await obterListaJustificativaCasoJuridicoPorRiscoFase(idCaso, idRiscoFase)
        setListaJustificativa(res)
    }

    const SalvarJustificativaCasoJuridicoPorRiscoFase = async () => {

        let fase = watch("fasesDoCaso")

        let body: ISalvarJustificativaCasoJuridicoPorRiscoFase = {
            id: justificativaSelecionada ? justificativaSelecionada.id : 0,
            descricao: watch("justificativa"),
            idCaso: +storedId,
            idRiscoFase: +fase
        }

        await salvarJustificativaCasoJuridicoPorRiscoFase(body)
        ObterListaJustificativaCasoJuridicoPorRiscoFase(+storedId, +fase)
        setJustificativaSelecionada(null)
        setValue("justificativa", null)
    }

    useEffect(() => {
        if (!justificativaSelecionada) return

        setValue("justificativa", justificativaSelecionada.descricao)
    }, [justificativaSelecionada])

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(17);

    const {
        permiteAlterar,
        permiteInserir,
        permiteExcluir
    } = permissions

    // const permiteAlterar = true
    // const permiteExcluir = true
    // const permiteInserir = true

    const fieldsDisabled = !(permiteAlterar || permiteInserir);

    return (
        <>
            <S.GridItem cols={2}>
                {
                    listaFasePorCaso &&
                        listaFasePorCaso.length > 0 ? (
                        <Select
                            id="fasesDoCaso"
                            name="fasesDoCaso"
                            label="Fase"
                            defaultValue={1}
                            control={control}
                            disabled={fieldsDisabled}
                            options={
                                listaFasePorCaso.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    ) : (
                        <WithLabel text="&nbsp;">
                            Nenhuma fase
                        </WithLabel>
                    )
                }
                {
                    (permiteAlterar || permiteInserir) && (
                        <WithLabel text="&nbsp;">
                            <Button
                                disabled={
                                    watch("fasesDoCaso") === "Selecione"
                                }
                                onClick={() => setPopupJustificativa(true)}
                            >
                                Justificativa
                            </Button>
                        </WithLabel>
                    )
                }
            </S.GridItem>
            <S.GridItem cols={2}>
                {
                    currentItem &&
                        currentItem !== blankCurrentItem &&
                        currentItem !== null ? (
                        <Container title={`Título: ${currentItem.descricaoRiscoTitulo || "N/A"}`}>
                            <S.GridItem cols={2}>
                                <PercentageInput
                                    id="naoAtribuido"
                                    name="naoAtribuido"
                                    label="Não Atribuído (%)"
                                    disabled
                                    control={control}
                                    error={errors.naoAtribuido?.message}
                                    value={currentItem.percentualNaoAtribuido}
                                />
                                <CurrencyInput
                                    id="reaisNaoAtribuido"
                                    name="reaisNaoAtribuido"
                                    label="R$"
                                    control={control}
                                    error={errors.reaisNaoAtribuido?.message}
                                    value={(currentItem.percentualNaoAtribuido / 100) * currentItem.valorTotal}
                                    disabled
                                />
                            </S.GridItem>
                            <S.GridItem cols={2}>
                                <PercentageInput
                                    id="perdaProvavel"
                                    name="perdaProvavel"
                                    label="Perda Provável (%)"
                                    control={control}
                                    error={errors.perdaProvavel?.message}
                                    value={currentItem.percentualPerdaProvavel}
                                    disabled={fieldsDisabled}
                                />
                                <CurrencyInput
                                    id="reaisPerdaProvavel"
                                    name="reaisPerdaProvavel"
                                    label="R$"
                                    control={control}
                                    disabled={fieldsDisabled}
                                    error={errors.reaisPerdaProvavel?.message}
                                    value={(currentItem.percentualPerdaProvavel / 100) * currentItem.valorTotal}
                                />
                            </S.GridItem>
                            <S.GridItem cols={2}>
                                <PercentageInput
                                    id="perdaPossivel"
                                    name="perdaPossivel"
                                    label="Perda Possível (%)"
                                    disabled={fieldsDisabled}
                                    control={control}
                                    error={errors.perdaPossivel?.message}
                                    value={currentItem.percentualPerdaPossivel}
                                />
                                <CurrencyInput
                                    id="reaisPerdaPossivel"
                                    name="reaisPerdaPossivel"
                                    disabled={fieldsDisabled}
                                    label="R$"
                                    control={control}
                                    error={errors.reaisPerdaPossivel?.message}
                                    value={(currentItem.percentualPerdaPossivel / 100) * currentItem.valorTotal}
                                />
                            </S.GridItem>
                            <S.GridItem cols={2}>
                                <PercentageInput
                                    id="perdaRemota"
                                    name="perdaRemota"
                                    label="Perda Remota (%)"
                                    disabled={fieldsDisabled}
                                    control={control}
                                    error={errors.perdaRemota?.message}
                                    value={currentItem.percentualPerdaRemota}
                                />
                                <CurrencyInput
                                    id="reaisPerdaRemota"
                                    name="reaisPerdaRemota"
                                    label="R$"
                                    disabled={fieldsDisabled}
                                    control={control}
                                    error={errors.reaisPerdaRemota?.message}
                                    value={(currentItem.percentualPerdaRemota / 100) * currentItem.valorTotal}
                                />
                            </S.GridItem>
                            {
                                !fieldsDisabled && (
                                    <S.GridItem cols={3}>
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <WithLabel text="">&nbsp;</WithLabel>
                                        <Button
                                            disabled={fieldsDisabled}
                                            onClick={() => {
                                                SaveCurrentItem(currentItem.id)
                                            }}>Salvar</Button>
                                    </S.GridItem>
                                )
                            }
                        </Container>
                    ) : (
                        <WithLabel text="&nbsp;">
                            Nenhum item selecionado
                        </WithLabel>
                    )
                }
                {
                    watch("fasesDoCaso") !== "Selecione" ? (
                        <Container title="Total por Êxito">
                            <S.GridItem cols={2}>
                                <CurrencyInput
                                    id="exitoNaoAtribuido"
                                    name="exitoNaoAtribuido"
                                    label="Não Atribuído (R$)"
                                    control={control}
                                    error={errors.exitoNaoAtribuido?.message}
                                    value={totalExito.naoAtribuido}
                                    disabled
                                />
                            </S.GridItem>
                            <S.GridItem cols={2}>
                                <CurrencyInput
                                    id="exitoPerdaProvavel"
                                    name="exitoPerdaProvavel"
                                    label="Perda Provável (R$)"
                                    control={control}
                                    error={errors.exitoPerdaProvavel?.message}
                                    value={totalExito.perdaProvavel}
                                    disabled
                                />
                            </S.GridItem>
                            <S.GridItem cols={2}>
                                <CurrencyInput
                                    id="exitoPerdaPossivel"
                                    name="exitoPerdaPossivel"
                                    label="Perda Possível (R$)"
                                    control={control}
                                    error={errors.exitoPerdaPossivel?.message}
                                    value={totalExito.perdaPossivel}
                                    disabled
                                />
                            </S.GridItem>
                            <S.GridItem cols={2}>
                                <CurrencyInput
                                    id="exitoPerdaRemota"
                                    name="exitoPerdaRemota"
                                    label="Perda Remota (R$)"
                                    control={control}
                                    error={errors.exitoNaoAtribuido?.message}
                                    value={totalExito.perdaRemota}
                                    disabled
                                />
                            </S.GridItem>
                        </Container>
                    ) : (
                        <WithLabel text="&nbsp;">
                            Nenhuma fase selecionada
                        </WithLabel>
                    )
                }
            </S.GridItem>
            <S.GridItem cols={1}>
                {
                    listaDistribuicao &&
                        listaDistribuicao.length > 0 ? (
                        <ItemsList>
                            <HeaderList items={[
                                "Objeto",
                                "Valor Total (R$)",
                                "% Não Atribuído",
                                "% Perda Provável",
                                "% Perda Possível",
                                "% Perda Remota"
                            ]} big={[0]} />
                            {
                                listaDistribuicao.map((item: any, index: number) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            onClick={() => {
                                                setCurrentItem(item)
                                            }}
                                            style={{ backgroundColor: currentItem === item && "#00000020" }}
                                        >
                                            <div className="big">{item.descricaoRiscoTitulo || "N/A"}</div>
                                            <div>{convertCurrency(item.valorTotal)}</div>
                                            <div>{item.percentualNaoAtribuido}%</div>
                                            <div>{item.percentualPerdaProvavel}%</div>
                                            <div>{item.percentualPerdaPossivel}%</div>
                                            <div>{item.percentualPerdaRemota}%</div>
                                        </ListItem>
                                    )
                                })
                            }
                        </ItemsList>
                    ) : (
                        <WithLabel text="&nbsp;">
                            Não existem registros para a fase selecionada.
                        </WithLabel>
                    )
                }
            </S.GridItem>
            <Popup
                minWidth="90%"
                show={popupJustificativa}
                onClose={() => {
                    setPopupJustificativa(false)
                    setJustificativaSelecionada(null)
                    setValue("justificativa", null)
                }}
                title={"Justificativa"}
                confirmAction={() => {
                    setPopupJustificativa(false)
                    setJustificativaSelecionada(null)
                    setValue("justificativa", null)
                }}
            >
                <S.GridItem cols={2}>
                    <Button
                        onClick={() => {
                            setValue("justificativa", null)
                            setJustificativaSelecionada(null)
                        }}
                        disabled={
                            !watch("justificativa") ||
                            watch("justificativa") === "" ||
                            watch("justificativa") === undefined
                        }
                    >Limpar</Button>
                    <Button
                        onClick={SalvarJustificativaCasoJuridicoPorRiscoFase}
                        disabled={
                            !watch("justificativa") ||
                            watch("justificativa") === "" ||
                            watch("justificativa") === undefined
                        }
                    >
                        {justificativaSelecionada ? "Salvar" : "Adicionar"}
                    </Button>
                </S.GridItem>
                {
                    justificativaSelecionada && (
                        <S.GridItem cols={2}>
                            <Input
                                id="dataJustificativa"
                                name="dataJustificativa"
                                label="Data"
                                control={control}
                                value={justificativaSelecionada.dataJustificativa}
                                disabled
                            />
                            <Input
                                id="usuarioJustificativa"
                                name="usuarioJustificativa"
                                label="Usuário"
                                control={control}
                                value={justificativaSelecionada.nomePessoaUsuario}
                                disabled
                            />
                        </S.GridItem>
                    )
                }
                <S.GridItem cols={1}>
                    <TextArea
                        id="justificativa"
                        name="justificativa"
                        control={control}
                        cols={100}
                        rows={3}
                    />
                </S.GridItem>
                <S.GridItem cols={1}>
                    {
                        listaJustificativa &&
                            listaJustificativa.length > 0 ? (
                            <ItemsList>
                                <HeaderList
                                    items={[
                                        "Data",
                                        "Descricao",
                                        "Usuário",
                                        "Ações"
                                    ]}
                                />
                                {
                                    listaJustificativa.map((item: any, index: number) => {
                                        return (
                                            <ListItem>
                                                <div>{item.dataJustificativa}</div>
                                                <div>
                                                    <span
                                                        style={{
                                                            display: "inline-block",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                            verticalAlign: "middle",
                                                            maxWidth: "20rem",
                                                        }}
                                                    >
                                                        {item.descricao}
                                                    </span>
                                                </div>
                                                <div>{item.nomePessoaUsuario}</div>
                                                <div>
                                                    <ActionButton act="edit" onClick={() => setJustificativaSelecionada(item)}>
                                                        <FiEdit />
                                                    </ActionButton>
                                                </div>
                                            </ListItem>
                                        )
                                    })
                                }
                            </ItemsList>
                        ) : (
                            <WithLabel text="&nbsp;">
                                Nenhuma justificativa.
                            </WithLabel>
                        )
                    }
                </S.GridItem>
            </Popup>
        </>
    )
}

export default Distribuicao