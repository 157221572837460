import * as S from "../../style"
import { useForm } from "react-hook-form"
import MaskedInput from "../../../../components/MaskInput";
import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import Container from "../../../../components/Container";
import { useState, useEffect, SetStateAction, useContext } from 'react';
import { obterListaUnidade } from "../../../../services/api/Configuracoes/Unidade";
import { obterListaCasoJuridicoArea } from "../../../../services/api/Configuracoes/CasoJuridicoArea";
import { baixarCasoJuridico, obterCasoJuridico, obterDetalheCasoJuridico, obterListaRiscoFasePorCasoJuridico, obterListaTipoRisco } from "../../../../services/api/CasoJuridico";
import { obterListaRiscoFase } from "../../../../services/api/Configuracoes/RiscoFase";
import { salvarCasoJuridico, reativarCasoJuridico, excluirCasoJuridico } from '../../../../services/api/CasoJuridico/index';
import { useNavigate } from 'react-router-dom';
import Button from "../../../../components/Button";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Popup from '../../../../components/Popup/index';
import CurrencyInput from "../../../../components/CurrencyInput";
import { obterListaMotivoBaixa } from "../../../../services/api/Configuracoes/MotivoBaixa";
import { obterListaPoloCliente } from "../../../../services/api/Configuracoes/PoloCliente";
import { obterListaComarca, obterListaUF } from "../../../../services/api/Comarca";
import WithLabel from "../../../../components/WithLabel";
import { usePermissionsStore } from "../../../../stores/PermissionsStore";

interface IDadosDoCaso {
    storedId: string | null;
    setStoredId: React.Dispatch<any>;
    pageSelected: number;
    setCaseIsSctr: React.Dispatch<boolean>;
    setCaseFaseProvisao: React.Dispatch<number | null>;
}

const DadosDoCasoSchema = yup.object({
    numeroProcesso: yup.string().required("Número do Processo é obrigatório."),
    isConfidencial: yup.string().not(["Selecione"], "Confidencial é obrigatório."),
    dataDistribuicao: yup.string().required("Data da Distribuição é obrigatório."),
    dataCitacao: yup.string().required("Data da Citação é obrigatório."),
    isProvisiona: yup.string().oneOf(["true", "false"], "Provisiona é obrigatório."),
    isSctr: yup.string().oneOf(["true", "false"], "Origem SCTR é obrigatório."),
    idUnidade: yup.string().not(["Selecione"], "Unidade é obrigatória."),
    idCasoJuridicoArea: yup.string().not(["Selecione"], "Área Jurídica é obrigatória."),
    idTipoRisco: yup.string().not(["Selecione"], "Tipo do Risco é obrigatório."),
    idMotivoBaixa: yup.string(),
    siglaUf: yup.string().notOneOf(["", "Selecione"], "UF é obrigatório."),
    idComarca: yup.string().notOneOf(["", "Selecione"], "Comarca é obrigatório.")
})

const DadosDoCaso = ({ storedId, setStoredId, pageSelected, setCaseIsSctr, setCaseFaseProvisao }: IDadosDoCaso) => {
    const [listaUnidade, setListaUnidade] = useState<any>(null)
    const [listaAreaJuridica, setListaAreaJuridica] = useState<any>(null)
    const [listaTipoRisco, setListaTipoRisco] = useState<any>(null)
    const [listaFaseProvisao, setListaFaseProvisao] = useState<any>(null)
    const [casoBaixado, setCasoBaixado] = useState<boolean>(false)
    const [popupBaixarCaso, setPopupBaixarCaso] = useState<boolean>(false)
    const [listaMotivoBaixa, setListaMotivoBaixa] = useState<any>(null)
    const [poloClienteList, setPoloClienteList] = useState<any>(null)
    const [listaComarca, setListaComarca] = useState<any>(null)
    const [listaUF, setListaUF] = useState<any>(null)

    const navigate = useNavigate()

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isDirty,
            isValid
        },
        trigger,
        getValues,
        setValue,
        register,
        getFieldState,
        reset,
        watch,
        setError
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(DadosDoCasoSchema)
    })

    useEffect(() => {
        if (storedId) {
            ObterCasoJuridico(+storedId)
            ObterListaRiscoFasePorCasoJuridico(+storedId)
        } else {
            setValue("isSctr", "false")
        }

        ObterListaUnidade()
        ObterListaAreaJuridica()
        ObterListaTipoRisco()
        ObterListaMotivoBaixa()
        ObterListaPoloCliente()
        getUF()
    }, [storedId])

    const ObterListaUnidade = async () => {
        let res = await obterListaUnidade(1)
        setListaUnidade(res)
    }

    const getUF = async () => {
        let res = await obterListaUF()
        setListaUF(res)
    }

    const getComarca = async (uf: string) => {
        if (!uf) return;
        let res = await obterListaComarca({ uf: uf })
        setListaComarca(res)
    }

    useEffect(() => {
        let sigla = watch("siglaUf")
        if (sigla === "Selecione") return;
        getComarca(sigla)
    }, [watch("siglaUf")])

    const ObterListaAreaJuridica = async () => {
        let res = await obterListaCasoJuridicoArea(1)
        setListaAreaJuridica(res)
    }

    const ObterListaTipoRisco = async () => {
        let res = await obterListaTipoRisco()
        setListaTipoRisco(res)
    }

    const ObterListaRiscoFasePorCasoJuridico = async (idCaso: number) => {
        let res = await obterListaRiscoFasePorCasoJuridico(idCaso)
        setListaFaseProvisao(res)
    }

    const ObterListaMotivoBaixa = async () => {
        let res = await obterListaMotivoBaixa(1)
        setListaMotivoBaixa(res)
    }

    const ObterListaPoloCliente = async () => {
        let res = await obterListaPoloCliente(1)
        setPoloClienteList(res);
    }

    const ObterCasoJuridico = async (idCaso: number) => {
        let res = await obterCasoJuridico(idCaso).then((response: any) => {
            if (response?.response?.data?.status === 601) {
                navigate("/casos-juridicos/buscar")
                setStoredId(null)
            }
            return response
        })
        if (res && res.isBaixado) {
            ObterDetalheCasoJuridico(idCaso)
        }
        if (res) {
            reset(res)
            setValue("siglaUf", res.comarca.siglaUf || null)
            setCasoBaixado(res.isBaixado)
            setCaseIsSctr(res.isSctr)
            setCaseFaseProvisao(res.idRiscoFaseProvisao)
        }
    }

    const ObterDetalheCasoJuridico = async (idCaso: number) => {
        let res = await obterDetalheCasoJuridico(idCaso)
        reset(res)
    }

    const SalvarCasoJuridico = async () => {

        let newAnoMesProvisao = +watch("anoMesProvisao").replaceAll("-", "")

        let newIdRiscoFaseProvisao = +watch("idRiscoFaseProvisao")

        let body = {
            id: storedId ? +storedId : 0,
            isConfidencial: watch("isConfidencial") === "true",
            idExterno: watch("idExterno"),
            idUnidade: +watch("idUnidade"),
            numeroProcesso: watch("numeroProcesso"),
            idCasoJuridicoArea: +watch("idCasoJuridicoArea"),
            idTipoRisco: +watch("idTipoRisco"),
            dataDistribuicao: watch("dataDistribuicao"),
            isProvisiona: watch("isProvisiona") === "true",
            dataCitacao: watch("dataCitacao"),
            idRiscoFaseProvisao: newIdRiscoFaseProvisao === 0 ? null : newIdRiscoFaseProvisao,
            isSctr: watch("isSctr") === "true",
            anoMesProvisao: newAnoMesProvisao === 0 ? null : newAnoMesProvisao,
            idCasoPoloCliente: +watch("idCasoPoloCliente") || null,
            idComarca: +watch("idComarca"),
            idCasoMotivoBaixa: +watch("idMotivoBaixa")
        }

        await salvarCasoJuridico(body).then((res: any) => {
            if (!storedId) {
                navigate(`/casos-juridicos/dados?idCaso=${res.id}`)
                window.location.reload()
            } else {
                ObterCasoJuridico(+storedId)
            }
        })
    }

    const SalvarPrimeiroStep = () => {
        trigger()
        isValid && SalvarCasoJuridico()
    }

    const BaixarCasoJuridico = async () => {
        if (!storedId) return
        let body = {
            idCaso: +storedId,
            dataBaixa: watch("dtBaixa"),
            valorLiquidacao: +(watch("valorBaixa").replaceAll("R$ ", "").replaceAll(".", "").replaceAll(",", ".")),
            idMotivoBaixa: +watch("idMotivoBaixa")
        }
        await baixarCasoJuridico(body).then(() => {
            ObterCasoJuridico(+storedId)
            setPopupBaixarCaso(false)
        })
    }

    const ReativarCasoJuridico = async () => {
        if (!storedId) return
        await reativarCasoJuridico(+storedId).then(() => {
            ObterCasoJuridico(+storedId)
        })
    }

    const ExcluirCasoJuridico = async () => {
        if (!storedId) return
        await excluirCasoJuridico(+storedId).then(() => {
            navigate("/casos-juridicos/buscar")
            setStoredId(null)
        })
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(17);

    const {
        permiteAlterar,
        permiteInserir,
        permiteExcluir
    } = permissions

    const fieldsDisabled = !(permiteAlterar || permiteInserir);

    return (
        <>
            {
                permiteAlterar &&
                isDirty && !casoBaixado && (
                    <div style={{
                        position: "absolute",
                        right: "0rem",
                        top: "-10.5rem"
                    }}>
                        <Button onClick={SalvarPrimeiroStep}>
                            Salvar Dados Do Caso
                        </Button>
                    </div>
                )
            }
            {
                permiteAlterar &&
                storedId && (
                    <S.GridItem cols={4}>
                        <div />
                        <div />
                        {
                            casoBaixado ? (
                                <Button onClick={() => ReativarCasoJuridico()}>Reativar Caso</Button>
                            ) : (
                                <Button onClick={() => setPopupBaixarCaso(true)}>Baixar Caso</Button>
                            )
                        }
                        <Button onClick={() => ExcluirCasoJuridico()}>Excluir Caso</Button>
                    </S.GridItem>
                )
            }
            <br />
            <S.GridItem cols={2}>
                {
                    storedId && (
                        <MaskedInput
                            id="sequencial"
                            name="sequencial"
                            label="Sequencial"
                            mask="999999"
                            control={control}
                            error={errors.sequencial?.message}
                            disabled
                        />
                    )
                }
                <Select
                    id="isConfidencial"
                    name="isConfidencial"
                    label="Confidencial *"
                    control={control}
                    disabled={fieldsDisabled}
                    error={errors.isConfidencial?.message}
                    options={[
                        { value: 'true', label: "Sim" },
                        { value: 'false', label: "Não" }
                    ]}
                />
            </S.GridItem>
            <S.GridItem cols={2}>
                <Input
                    id="idExterno"
                    name="idExterno"
                    label="ID Externo"
                    control={control}
                    disabled={fieldsDisabled}
                    error={errors.idExterno?.message}
                />
                {
                    listaUnidade ? (
                        <Select
                            id="idUnidade"
                            name="idUnidade"
                            label="Unidade *"
                            control={control}
                            error={errors.idUnidade?.message}
                            disabled={fieldsDisabled}
                            options={
                                listaUnidade.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    ) : (
                        <WithLabel text="Unidade">
                            <span style={{ fontSize: ".8rem" }}>Carregando...</span>
                        </WithLabel>
                    )
                }
            </S.GridItem>
            <S.GridItem cols={2}>
                <Input
                    id="numeroProcesso"
                    name="numeroProcesso"
                    label="Número do Processo *"
                    disabled={fieldsDisabled}
                    control={control}
                    error={errors.numeroProcesso?.message}
                />
                {
                    listaAreaJuridica ? (
                        <Select
                            id="idCasoJuridicoArea"
                            name="idCasoJuridicoArea"
                            label="Área Jurídica *"
                            disabled={fieldsDisabled}
                            control={control}
                            error={errors.idCasoJuridicoArea?.message}
                            options={
                                listaAreaJuridica.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    ) : (
                        <WithLabel text="Área Jurídica">
                            <span style={{ fontSize: ".8rem" }}>Carregando...</span>
                        </WithLabel>
                    )
                }
            </S.GridItem>
            <S.GridItem cols={2}>
                {
                    listaTipoRisco && (
                        <Select
                            id="idTipoRisco"
                            name="idTipoRisco"
                            label="Tipo do Risco *"
                            disabled={fieldsDisabled}
                            control={control}
                            error={errors.idTipoRisco?.message}
                            options={
                                listaTipoRisco.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    )
                }
                <Input
                    type="date"
                    id="dataDistribuicao"
                    name="dataDistribuicao"
                    label="Data da Distribuição *"
                    disabled={fieldsDisabled}
                    control={control}
                    error={errors.dataDistribuicao?.message}
                />
            </S.GridItem>
            <S.GridItem cols={2}>
                <Select
                    id="isProvisiona"
                    name="isProvisiona"
                    label="Provisiona *"
                    disabled={fieldsDisabled}
                    control={control}
                    error={errors.isProvisiona?.message}
                    options={[
                        { value: 'true', label: "Sim" },
                        { value: 'false', label: "Não" }
                    ]}
                />
                <Input
                    type="date"
                    id="dataCitacao"
                    disabled={fieldsDisabled}
                    name="dataCitacao"
                    label="Data da Citação *"
                    control={control}
                    error={errors.dataCitacao?.message}
                />
            </S.GridItem>
            <S.GridItem cols={2}>
                {
                    listaFaseProvisao && (
                        <Select
                            id="idRiscoFaseProvisao"
                            name="idRiscoFaseProvisao"
                            label="Fase Provisão"
                            disabled={fieldsDisabled}
                            control={control}
                            options={
                                listaFaseProvisao.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    )
                }
                <Select
                    id="isSctr"
                    name="isSctr"
                    label="Origem ADEMAC *"
                    control={control}
                    error={errors.isSctr?.message}
                    disabled
                    options={[
                        { value: "true", label: "Sim" },
                        { value: "false", label: "Não" }
                    ]}
                />
                <Input
                    type="month"
                    id="anoMesProvisao"
                    name="anoMesProvisao"
                    label="Provisionar a partir de"
                    control={control}
                    error={errors.anoMesProvisao?.message}
                    disabled={fieldsDisabled}
                />
            </S.GridItem>
            <S.GridItem cols={2}>
                {
                    poloClienteList ? (
                        <Select
                            id="idCasoPoloCliente"
                            name="idCasoPoloCliente"
                            label="Polo do Cliente"
                            control={control}
                            error={errors.idCasoPoloCliente?.message}
                            disabled={fieldsDisabled}
                            options={
                                poloClienteList.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    ) : (
                        <WithLabel text="Polo do Cliente">
                            <span style={{ fontSize: ".8rem" }}>Carregando...</span>
                        </WithLabel>
                    )
                }
            </S.GridItem>
            <S.GridItem cols={2}>
                {
                    listaUF ? (
                        <Select
                            id="uf"
                            name="siglaUf"
                            disabled={fieldsDisabled}
                            label="UF *"
                            control={control}
                            error={errors.siglaUf?.message}
                            options={
                                listaUF ?
                                    listaUF.map((item: any) => ({
                                        value: item.sigla,
                                        label: item.descricao
                                    })) : []
                            }
                        />
                    ) : (
                        <WithLabel text="UF *">
                            <span style={{ fontSize: ".8rem" }}>Carregando...</span>
                        </WithLabel>
                    )
                }
                {
                    listaComarca ? (
                        <Select
                            id="idComarca"
                            name="idComarca"
                            disabled={fieldsDisabled}
                            label="Comarca *"
                            control={control}
                            error={errors.idComarca?.message}
                            options={
                                listaComarca.map((item: any) => ({
                                    value: item.id,
                                    label: item.nomeComarca
                                }))
                            }
                        />
                    ) : (
                        <Input disabled type="text" name="x" label="Comarca*" id="x" value="Selecione o UF" control={control} />
                    )
                }
            </S.GridItem>
            {
                casoBaixado && (
                    <Container title="Informações sobre a Baixa">
                        <S.Line>
                            <Input disabled type="date" id="dataBaixa" name="dataBaixa" label="Data da Baixa" control={control} error={errors.dataHora?.message} />
                            <Input disabled id="baixadoPor" name="baixadoPor" label="Baixado por" control={control} error={errors.pessoaResponsavel?.message} />
                            <Input disabled id="valorLiquidacao" name="valorLiquidacao" label="Valor da liquidação" control={control} error={errors.evento?.message} />
                        </S.Line>
                    </Container>
                )
            }
            {
                (permiteAlterar || permiteInserir) &&
                popupBaixarCaso && (
                    <Popup
                        show={popupBaixarCaso}
                        onClose={() => setPopupBaixarCaso(false)}
                        title={"Baixar Caso"}
                        confirmAction={BaixarCasoJuridico}
                        confirmDisabled={!watch("dtBaixa") || !watch("valorBaixa")}
                    >
                        <S.GridItem cols={1}>
                            <Input type="date" id="dtBaixa" name="dtBaixa" label="Data da Baixa" control={control} error={errors.dtBaixa?.message} />
                            <br />
                            <CurrencyInput id="valorBaixa" name="valorBaixa" label="Valor Baixa" control={control} error={errors.valorBaixa?.message} />
                            {
                                listaMotivoBaixa && (
                                    <>
                                        <br />
                                        <Select
                                            id="idMotivoBaixa"
                                            name="idMotivoBaixa"
                                            label="Motivo"
                                            control={control}
                                            error={errors.idMotivoBaixa?.message}
                                            options={
                                                listaMotivoBaixa.map((item: any) => ({
                                                    value: item.id,
                                                    label: item.descricao
                                                }))
                                            }
                                        />
                                    </>
                                )
                            }
                        </S.GridItem>
                    </Popup>
                )
            }
        </>
    )
}

export default DadosDoCaso