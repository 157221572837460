import { Control, Controller, FieldValues } from "react-hook-form";
import * as S from "./style"
import { InputHTMLAttributes } from 'react';
import ReactInputMask from "react-input-mask"

const InputMask = ReactInputMask as any

interface IMaskedInput extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    id: string;
    control: Control<FieldValues, object>;
    name: string;
    type?: string;
    error?: any;
    mask: string | RegExp;
}

const MaskedInput = ({
    label,
    id,
    control,
    name,
    type = "text",
    error,
    mask,
    ...props
}: IMaskedInput) => {
    return (
        <S.MaskedInputContainer>
            {
                label && (
                    <S.StyledMaskedLabel htmlFor={id}>{label}</S.StyledMaskedLabel>
                )
            }
            {/* <S.StyledMaskedInput id={id} type={type} {...control?.register(name)} error={error} {...props}/> */}
            {/* <InputMask
                mask={mask}
                maskChar={null}
                {...control?.register(name)}
                {...props}
            >
                {(...inputProps: any) => (
                    <S.StyledMaskedInput
                        id={id}
                        error={error}
                        name={name}
                        {...inputProps}
                    />
                )}
            </InputMask> */}
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <InputMask
                        mask={mask}
                        maskChar={null}
                        value={field.value}
                        onChange={field.onChange}
                        {...props}
                    >
                        {(...inputProps: any) => (
                            <S.StyledMaskedInput
                                id={id}
                                error={error}
                                disabled={props.disabled}
                                {...inputProps}
                            />
                        )}
                    </InputMask>
                )}
            />
            {
                error && (
                    <S.StyledMaskedError>
                        {error}
                    </S.StyledMaskedError>
                )
            }
        </S.MaskedInputContainer>
    )
}

export default MaskedInput