import get, { privateGet } from "../../methods/get"
import post, { authPost, privatePost } from "../../methods/post"
import { IAlterarSenhaUsuario, IAlterarSenhaUsuarioPorToken, IRecuperarSenha } from '../../models/Autenticar/index';
import {
    IAutenticar,
    IObterListaClienteMacdata,
    IObterUsuarioAutenticacao, IObterUsuarioAutenticacaoResponse, IUsuarioObterListaClienteMacdata
} from "../../models/Autenticar"
import put, { resetPassPut } from "../../methods/put";

export const autenticar = (idClienteMacdata: number, body: IAutenticar) => {
    return authPost<string>("/autenticar", idClienteMacdata, body)
}

export const obterUsuarioAutenticacao = (body: IObterUsuarioAutenticacao) => {
    return privateGet<IObterUsuarioAutenticacaoResponse>(`/autenticar/obterusuarioautenticacao`, {
        LoginEmail: body.loginEmail,
        Senha: body.senha
    })
}

export const obterListaClienteMacdata = (body: IUsuarioObterListaClienteMacdata) => {
    return privateGet<IObterListaClienteMacdata[]>(`/autenticar/obterlistaclientemacdata?idUsuario=${body.idUsuario}`)
}

export const recuperarSenha = (body: IRecuperarSenha) => {
    return post<string>(`/autenticar/recuperarsenha`, body)
}

export const alterarSenhaUsuarioPorToken = (body: IAlterarSenhaUsuarioPorToken) => {
    return resetPassPut<string>(`/autenticar/alterarsenhausuarioportoken`, body)
}
export const alterarSenhaUsuario = (body: IAlterarSenhaUsuario) => {
    return resetPassPut<string>(`/autenticar/alterarsenhausuario`, body)
}

export const validarToken = (tokenRecuperacaoSenha: string) => {
    return get<string>(`/autenticar/recuperarsenha/token/validar?tokenRecuperacaoSenha=${tokenRecuperacaoSenha}`)
}