import { useEffect, useState } from "react"
import PageTitle from "../../../components/PageTitle"
import TabSwitcher from "../../../components/TabSwitcher"
import Template from "../../../components/Template"
import * as S from "../style"
import ItemsList, { HeaderList, ListItem } from "../../../components/ItemsList"
import { GridItem } from '../style';
import Input from "../../../components/Input"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Button from "../../../components/Button"
import { IAlterarMotivoBaixa, IObterListaMotivoBaixaResponse } from "../../../services/models/Configuracoes/MotivoBaixa"
import { adicionarMotivoBaixa, alterarMotivoBaixa, excluirMotivoBaixa, obterListaMotivoBaixa } from "../../../services/api/Configuracoes/MotivoBaixa"
import ActionButton from "../../../components/ActionButton"
import { PencilSimple, Trash } from "phosphor-react"
import ModalAction from "../../../components/ModalAction"
import { usePermissionsStore } from "../../../stores/PermissionsStore"

const AddMotivoBaixaSchema = yup.object({
    descricao: yup.string().required("O nome é obrigatório.")
})

type item = IObterListaMotivoBaixaResponse;

const MotivoBaixa = () => {
    const [view, setView] = useState<number>(0)
    const [MotivoBaixaList, setMotivoBaixaList] = useState<any>(null);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [itemToDelete, setItemToDelete] = useState<item | null>(null)
    const [itemToEdit, setItemToEdit] = useState<item | null>(null)

    useEffect(() => {
        view === 0 && ObterListaMotivoBaixa();
    }, [view])

    const {
        handleSubmit,
        control,
        reset,
        formState: {
            errors,
            isValid
        }
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(AddMotivoBaixaSchema)
    })

    const ObterListaMotivoBaixa = async () => {
        let res = await obterListaMotivoBaixa()
        setMotivoBaixaList(res);
    }

    const AdicionarMotivoBaixa = async (data: any) => {
        let body = {
            ...data
        }

        await adicionarMotivoBaixa(body).then(() => {
            reset({
                descricao: null
            })
        })
    }

    const ExcluirMotivoBaixa = async (idCasoMotivoBaixa: number) => {
        await excluirMotivoBaixa({ idCasoMotivoBaixa });
        ObterListaMotivoBaixa()
    }

    const EditarMotivoBaixa = async (newMotivoBaixa: IAlterarMotivoBaixa) => {
        await alterarMotivoBaixa(newMotivoBaixa)
        ObterListaMotivoBaixa()
    }

    const handleShowDeleteModal = (item: item) => {
        setItemToDelete(item)
        setShowDeleteModal(true)
    }

    const handleShowEditModal = (item: item) => {
        setItemToEdit(item)
        setShowEditModal(true)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(23);

    // PARA TESTE
    // const permissions = {
    //     permiteInserir: false,
    //     permiteAlterar: false,
    //     permiteExcluir: false,
    // }

    const {
        permiteInserir,
        permiteAlterar,
        permiteExcluir
    } = permissions;

    const menuItems = permiteInserir ?
        [
            "Dados",
            "Adicionar"
        ] : [
            "Dados"
        ]

    const headerList = permiteAlterar || permiteExcluir ?
        [
            "Descrição",
            "Status",
            "Ações"
        ] : [
            "Descrição",
            "Status"
        ]

    return (
        <Template>
            <PageTitle>
                Motivo da Baixa
            </PageTitle>
            <TabSwitcher
                list={menuItems}
                view={view}
                setView={setView}
            />
            <S.Content>
                <S.ControlView active={view === 0}>
                    {
                        MotivoBaixaList ? (
                            <ItemsList>
                                <HeaderList items={headerList} />
                                {
                                    MotivoBaixaList.length > 0 ?
                                    MotivoBaixaList.map((item: item, index: number) => {
                                        return (
                                            <ListItem key={index}>
                                                <div>{item.descricao}</div>
                                                <div>{item.isAtivo ? "Ativo" : "Inativo"}</div>
                                                {
                                                    (
                                                        permiteAlterar ||
                                                        permiteExcluir
                                                    ) && (
                                                        <div>
                                                            {
                                                                permiteAlterar && (
                                                                    <ActionButton act="edit" onClick={() => handleShowEditModal(item)}>
                                                                        <PencilSimple />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                            {
                                                                permiteExcluir && (
                                                                    <ActionButton act="delete" onClick={() => handleShowDeleteModal(item)}>
                                                                        <Trash />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </ListItem>
                                        )
                                    }) : (
                                        <span>Nenhum item</span>
                                    )
                                }
                            </ItemsList>
                        ) : (
                            <p>Carregando...</p>
                        )
                    }
                </S.ControlView>
                <S.ControlView
                    active={view === 1}
                    id="addMotivoBaixa"
                    onSubmit={handleSubmit((data) => AdicionarMotivoBaixa(data))}
                >
                    <S.GridItem cols={2}>
                        <Input
                            id="descricao"
                            name="descricao"
                            label="Descrição"
                            control={control}
                            error={errors.descricao?.message}
                        />
                    </S.GridItem>
                    {
                        isValid && (
                            <S.GridItem cols={6}>
                                <Button type="submit" form="addMotivoBaixa">Adicionar</Button>
                            </S.GridItem>
                        )
                    }
                </S.ControlView>
            </S.Content>
            {
                showDeleteModal && itemToDelete && (
                    <ModalAction
                        type="delete"
                        onConfirm={() => ExcluirMotivoBaixa(itemToDelete.id)}
                        onClose={() => setShowDeleteModal(false)}
                        title="Excluir Motivo da Baixa"
                        item={itemToDelete}
                    />
                )
            }
            {
                showEditModal && itemToEdit && (
                    <ModalAction
                        type="edit"
                        onConfirm={(e) => EditarMotivoBaixa(e)}
                        onClose={() => setShowEditModal(false)}
                        title="Editar Motivo da Baixa"
                        item={itemToEdit}
                    />
                )
            }
        </Template>
    )
}

export default MotivoBaixa