import * as S from "./style"
import {
    FiChevronRight,
    FiChevronLeft,
    FiChevronsRight,
    FiChevronsLeft
} from "react-icons/fi"
import { useState, useEffect } from 'react';

interface IPaginator {
    paginaAtual: number,
    totalItens: number,
    totalPaginas: number,
    first: () => void,
    last: () => void,
    change: React.Dispatch<React.SetStateAction<number>> | ((arg: number) => void)
}

const Paginator = ({
    paginaAtual,
    totalItens,
    totalPaginas,
    first,
    last,
    change
}: IPaginator) => {
    const increase = () => {
        let getNumber = paginaAtual
        getNumber += 1
        change(getNumber)
    }

    const decrease = () => {
        let getNumber = paginaAtual
        getNumber -= 1
        change(getNumber)
    }

    const setFirst = () => {
        first()
    }
    
    const setLast = () => {
        last()
    }

    const changeInput = (val: string) => {
        let toInt = parseInt(val)

        if (!isNaN(toInt)) {
            if (toInt > totalPaginas) {
                change(totalPaginas)
            } else if (toInt < 1) {
                change(1)
            } else {
                change(toInt)
            }
        }
    }

    return (
        <S.PaginatorWrapper>
            <S.PaginatorButtonsContainer>
                <S.PaginatorButton type="button" onClick={setFirst} disabled={paginaAtual === 1}>
                    <FiChevronsLeft />
                </S.PaginatorButton>
                <S.PaginatorButton type="button" onClick={() => decrease()} disabled={paginaAtual === 1}>
                    <FiChevronLeft />
                </S.PaginatorButton>
                <S.CenterContainer>
                    <S.PaginatorInput value={paginaAtual} onBlur={(e) => changeInput(e.target.value)} onChange={(e) => change(parseInt(e.target.value))} /> de {totalPaginas}
                </S.CenterContainer>
                <S.PaginatorButton type="button" onClick={() => increase()} disabled={paginaAtual === totalPaginas}>
                    <FiChevronRight />
                </S.PaginatorButton>
                <S.PaginatorButton type="button" onClick={setLast} disabled={paginaAtual === totalPaginas}>
                    <FiChevronsRight/>
                </S.PaginatorButton>
            </S.PaginatorButtonsContainer>
            <S.RecordsLabel>
                {
                    totalItens ? `${totalItens} ${totalItens <= 1 ? "registro" : "registros"}` : "0 registros"
                }
            </S.RecordsLabel>
        </S.PaginatorWrapper>
    )
}

export default Paginator