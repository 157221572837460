import { Control, FieldValues } from "react-hook-form";
import * as S from "./style"

interface IRadioButon {
    id: string;
    name: string;
    control: Control<FieldValues, object>;
    checked?: boolean;
    label?: string;
    value: string;
    [key: string]: any;
}

const RadioButton = ({
    id,
    name,
    control,
    checked = false,
    label,
    value,
    ...rest
}: IRadioButon) => {
    return (
        <S.RadioButtonContainer>
            <input
                type="radio"
                id={id}
                defaultChecked={checked}
                value={value}
                disabled={rest.disabled}
                {...control?.register(name)}
            />
            {
                label && (
                    <S.RadioLabel htmlFor={id}>
                        {label}
                    </S.RadioLabel>
                )
            }
        </S.RadioButtonContainer>
    )
}

export default RadioButton