import { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { obterListaMenuPermissao } from '../../services/api/Menu';
import getIdClienteMacdata from '../../utils/getIdClienteMacdata';
import * as S from "./style";
import { MenuPermissao, usePermissionsStore } from '../../stores/PermissionsStore';

const SideBar = () => {
    const [currentItem, setCurrentItem] = useState<number>(-1)
    const [searchParams, setSearchParams] = useSearchParams()

    const permissions = usePermissionsStore((state) => state.permissions);

    const location = useLocation()
    const navigate = useNavigate()

    if (
        (location.pathname === "/") ||
        (location.pathname === "/not-found") ||
        (location.pathname === "/redefinir-senha")
    ) {
        return <></>
    }

    const handleOpen = (number: number) => {
        if (currentItem === number) {
            setCurrentItem(-1)
        } else {
            setCurrentItem(number)
        }
    }

    const navTo = (path: string, params?: boolean) => {
        navigate({
            pathname: path,
            search: params ? searchParams.toString() : ""
        })
    }

    return (
        <S.SideBarWrapper>
            {
                permissions &&
                permissions.length > 0 ? (
                    permissions.map((item: MenuPermissao, index: number) => {
                        return (
                            <Fragment key={index}>
                                <S.SideBarItem onClick={() => handleOpen(index)} tabIndex={0} key={index}>
                                    {item.nomeAgrupamento}
                                </S.SideBarItem>
                                {
                                    currentItem === index && (
                                        <div>
                                            {
                                                permissions[index].listaMenu.map((subMenuItem: any, subMenuIndex: number) => {
                                                    return (
                                                        <S.SideBarSubItem
                                                            key={subMenuIndex}
                                                            onClick={() => navTo(subMenuItem.rota)}
                                                            active={location.pathname === subMenuItem.rota}
                                                        >
                                                            {subMenuItem.descricaoMenu}
                                                        </S.SideBarSubItem>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                }

                            </Fragment>
                        )
                    })
                ) : (
                    <span style={{ color: "var(--white)", marginLeft: "1rem" }}>
                        Nenhuma opção disponível
                    </span>
                )
            }
        </S.SideBarWrapper>
    )
}

export default SideBar