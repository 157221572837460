import axios from "axios";
import { toast } from "react-toastify";
import getIdClienteMacdata from "../../utils/getIdClienteMacdata";

const deleteMethod = async <T> (route: string, body?: any): Promise<T> => {
    return await axios
        .delete(`${process.env.REACT_APP_BACKEND_API}${route}`, body)
        .then(r => r.data)
}

export const privateDelete= async<T> (route: string, body?: any, noToast?: boolean): Promise<T> => {

    const user = localStorage.getItem("user")
    const token = user && JSON.parse(user).token

    const privateAxios = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        headers: {
            'Authorization': `Bearer ${token}`,
            idClienteMacdata: getIdClienteMacdata()
        }
    })

    return await privateAxios
        .delete(`${process.env.REACT_APP_BACKEND_API}${route}`, { params: body })
        .then((r) => {
            toast.dismiss()
            toast.success(r.data, { toastId: "deleteToast" })
            return r.data
        })
        .catch((e) => {
            toast.dismiss()
            !noToast && toast.error(e.response.data.detail, { toastId: "deleteErrorToast" })
            return false
        })
}

export default deleteMethod