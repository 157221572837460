import { Control, FieldValues } from "react-hook-form";
import * as S from "./style"

interface ICheckbox {
    id: string;
    name: string;
    control: Control<FieldValues, object>;
    checked?: boolean;
    label?: string;
    value: string;
    [key: string]: any;
}

const Checkbox = ({
    id,
    name,
    control,
    checked = false,
    label,
    value,
    ...rest
}: ICheckbox) => {
    return (
        <S.CheckboxContainer>
            <input
                type="checkbox"
                id={id}
                defaultChecked={checked}
                value={value}
                disabled={rest.disabled}
                {...control?.register(name)}
            />
            {
                label && (
                    <S.CheckboxLabel htmlFor={id}>
                        {label}
                    </S.CheckboxLabel>
                )
            }
        </S.CheckboxContainer>
    )
}

export default Checkbox