import { useForm } from "react-hook-form"
import PageTitle from "../../../components/PageTitle"
import TabSwitcher from "../../../components/TabSwitcher"
import Template from "../../../components/Template"
import * as S from "./style"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useEffect, useState } from "react"
import { IObterListaPerfil } from "../../../services/models/Perfil"
import { obterListaPerfil } from "../../../services/api/Perfil"
import Select from "../../../components/Select"
import Button from "../../../components/Button"
import WithLabel from "../../../components/WithLabel"
import Input from "../../../components/Input"
import Checkbox from "../../../components/Checkbox"
import { obterListaPaginadaPesquisaPessoa, obterPessoaComUsuario, obterPessoaSemUsuario } from "../../../services/api/Pessoa"
import { ControlView } from '../../LegalCases/style';
import ItemsList, { HeaderList, ListItem } from "../../../components/ItemsList"
import Paginator from "../../../components/Paginator"
import { IPessoaObterListaPaginadaPesquisaPessoa } from "../../../services/models/Pessoa"
import MaskedInput from "../../../components/MaskInput"
import { toast } from "react-toastify"
import ActionButton from "../../../components/ActionButton"
import { Trash } from "phosphor-react"
import { adicionarUsuario, alterarUsuario } from "../../../services/api/Usuario"
import { IUsuarioAlterar } from "../../../services/models/Usuario"
import { usePermissionsStore } from "../../../stores/PermissionsStore"

const PeopleDataSchema = yup.object({
    docPesquisa: yup.string(),
    nomePesquisa: yup.string().max(100, "Limite de 100 caracteres."),
    senha: yup.string().min(5, "Mínimo de 5 caracteres.").required("Campo obrigatório.").not([""]).test(
        "numeros",
        "Deve conter números",
        val => /\d/.test(val)
    ).test(
        "maiuscula",
        "Deve conter letra maiúscula",
        val => /[A-Z]/.test(val)
    ),
    alterarSenhaProximoLogin: yup.boolean().oneOf([true, false]),
    email: yup.string().email("E-mail inválido.").required("Campo obrigatório.")
})

const UsuarioCadastro = () => {
    const [view, setView] = useState<number>(0)
    const [listaPerfis, setListaPerfis] = useState<any>(null)
    const [listaPessoas, setListaPessoas] = useState<any>(null)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [pessoa, setPessoa] = useState<any>(null)
    const [dadosPessoa, setDadosPessoa] = useState<any>(null)
    const [hasUser, setHasUser] = useState<boolean>(false)
    const [novaListaPerfil, setNovaListaPerfil] = useState<any[]>([])

    useEffect(() => {
        triggerSearch({
            tipoSituacao: "Ativos"
        })
        getPerfis()
    }, [])


    const getPerfis = async () => {
        let res = await obterListaPerfil()
        setListaPerfis(res)
    }

    const getPessoaSemUsuario = async (idPessoa: string) => {
        let res = await obterPessoaSemUsuario(idPessoa)
        if (res) {
            setDadosPessoa(res)
            setValue("email", res.email)
        }
    }

    const getPessoaComUsuario = async (idPessoa: string) => {
        let res = await obterPessoaComUsuario(idPessoa)
        if (res) {
            setDadosPessoa(res)
            setValue("email", res.email)
            setValue("senha", res.senha)
            setValue("alterarSenhaProximoLogin", res.alterarSenhaProximoLogin.toString())
            setNovaListaPerfil(res.perfis)
        }
    }

    const salvarUsuario = async () => {
        const payload = {
            id: hasUser ? dadosPessoa.id : null,
            idPessoa: hasUser ? dadosPessoa.idPessoa : dadosPessoa.id,
            senha: watch("senha"),
            email: watch("email") === "" ? null : watch("email"),
            alterarSenhaProximoLogin: !!watch("alterarSenhaProximoLogin"),
            perfis: novaListaPerfil.map((item) => (
                item.id
            ))
        }

        if (hasUser) {
            await alterarUsuario(payload as any)
        } else {
            await adicionarUsuario(payload as any)
        }

        mudarPessoa()
    }

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isValid
        },
        setValue,
        getValues,
        register,
        reset,
        watch,
        setError
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(PeopleDataSchema)
    })

    const limpar = () => {
        reset({
            nomePesquisa: "",
            docPesquisa: ""
        })
        triggerSearch({}, currentPage)
    }

    useEffect(() => {
        pesquisa()
    }, [currentPage])

    const pesquisa = () => {
        triggerSearch({
            nome: watch("nomePesquisa"),
            cnpjCpf: watch("docPesquisa")
        }, currentPage)
    }

    const triggerSearch = async (data: any, page?: any) => {
        !page && setCurrentPage(1)

        let body: IPessoaObterListaPaginadaPesquisaPessoa = {
            limite: 5,
            pagina: page ? page : 1,
            tipoSituacao: "Ambos",
            ...data
        }
        let res = await obterListaPaginadaPesquisaPessoa(body)
        setListaPessoas(res)
    }

    const handlePessoa = (pessoa: any) => {
        setHasUser(pessoa.existeUsuario)
        setPessoa(pessoa)
        setView(1)
    }

    const mudarPessoa = () => {
        setPessoa(null)
        setValue("email", null)
        setNovaListaPerfil([])
        reset()
        setView(0)
        pesquisa()
    }

    useEffect(() => {
        if (!pessoa) return
        if (hasUser) {
            getPessoaComUsuario(pessoa.id)
        } else {
            getPessoaSemUsuario(pessoa.id)
        }
    }, [pessoa])

    const removeProfile = (itemID?: number) => {
        if (itemID) {
            listaPerfis.forEach((item: any) => {
                if (itemID === item.id) {
                    let novo: any = []
                    novaListaPerfil?.forEach((iti: any) => {
                        if (iti.id !== item.id) {
                            novo.push(iti)
                        }
                    })
                    setNovaListaPerfil([...novo])
                    if (novo.length < 1) {
                        toast.dismiss();
                        toast.info("Usuário sem perfis perderá o acesso ao sistema!", { toastId: "lostAccessToast" })
                    }
                }
            })
        } else {
            setNovaListaPerfil([])
            toast.dismiss();
            toast.info("Usuário sem perfis perderá o acesso ao sistema!", { toastId: "lostAccessToast" })
        }
    }

    const insertProfile = () => {
        let selectedProfile = watch("profile")

        if (selectedProfile !== "Selecione") {

            listaPerfis.forEach((item: any) => {
                if (item.id === Number(selectedProfile)) {
                    if (novaListaPerfil) {
                        let v = 0
                        novaListaPerfil.forEach((it: any) => {
                            if (it.id === item.id) {
                                v = 1
                            }
                        })
                        if (v > 0) {
                            toast.dismiss()
                            toast.error("Perfil já incluído.", { toastId: "profileToast" })
                        } else { setNovaListaPerfil([...novaListaPerfil, item]) }
                    } else {
                        setNovaListaPerfil([item])
                    }
                }
            })

            setValue("profile", "Selecione")
        }
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(19);

    const {
        permiteAlterar,
        permiteInserir,
        permiteExcluir
    } = permissions

    return (
        <Template>
            <PageTitle>
                Cadastro de Usuário
            </PageTitle>
            <S.Content>
                <S.ControlView active={view === 0}>
                    <form>
                        <S.GridItem cols={3}>
                            <Input id="nomePesquisa" name="nomePesquisa" label="Nome" control={control} error={errors.nomePesquisa?.message} />
                            <MaskedInput id="docPesquisa" name="docPesquisa" label="CPF" control={control} mask={"999.999.999-99"} />
                        </S.GridItem>
                        <S.ButtonsWrapper>
                            <Button onClick={() => limpar()}>Limpar</Button>
                            <Button type="button"
                                onClick={pesquisa}
                            >Pesquisar</Button>
                        </S.ButtonsWrapper>
                        {
                            listaPessoas &&
                                listaPessoas.itens.length > 0 ? (
                                <ItemsList>
                                    <HeaderList
                                        items={[
                                            "NOME",
                                            "CPF"
                                        ]}
                                    />
                                    <>
                                        {
                                            listaPessoas.itens.map((item: any, index: number) => {
                                                return (
                                                    <ListItem key={index} onClick={() => handlePessoa(item)}>
                                                        <div>{item.nome}</div>
                                                        <div>{item.cnpjCpf || "N/A"}</div>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                        <br />
                                        <Paginator
                                            paginaAtual={currentPage}
                                            totalItens={listaPessoas.totalItens}
                                            totalPaginas={listaPessoas.totalPaginas}
                                            first={() => setCurrentPage(1)}
                                            last={() => setCurrentPage(listaPessoas.totalPaginas)}
                                            change={setCurrentPage}
                                        />
                                    </>
                                </ItemsList>
                            ) : (
                                <span>Nenhum resultado.</span>
                            )
                        }
                    </form>
                </S.ControlView>
                {
                    view === 1 && (

                        <S.ControlView active={view === 1}>
                            {
                                dadosPessoa &&
                                pessoa && (
                                    <>
                                        <S.GridItem cols={2}>
                                            <WithLabel text="&nbsp;">
                                                <Button
                                                    onClick={mudarPessoa}
                                                >
                                                    Voltar para pesquisa
                                                </Button>
                                            </WithLabel>
                                        </S.GridItem>
                                        <br />
                                        <S.GridItem cols={4}>
                                            <Input
                                                id="idPessoaSelecionada"
                                                name="idPessoaSelecionada"
                                                control={control}
                                                type="text"
                                                label="ID"
                                                value={pessoa.id}
                                                disabled
                                            />
                                            <Input
                                                id="nomePessoaSelecionada"
                                                name="nomePessoaSelecionada"
                                                control={control}
                                                type="text"
                                                label="Nome"
                                                value={pessoa.nome}
                                                disabled
                                            />
                                            <Input
                                                type="text"
                                                id="email"
                                                name="email"
                                                label="Email"
                                                control={control}
                                                error={errors.email?.message}
                                            />
                                        </S.GridItem>
                                    </>
                                )
                            }
                            {
                                dadosPessoa && (
                                    <>
                                        <S.GridItem cols={4}>
                                            <Input
                                                type="password"
                                                id="senha"
                                                name="senha"
                                                label="Nova senha"
                                                control={control}
                                                error={errors.senha?.message}
                                            />
                                            <WithLabel text="Situação">
                                                <Checkbox
                                                    id="alterarSenhaProximoLogin"
                                                    name="alterarSenhaProximoLogin"
                                                    label="Alterar senha no próximo login"
                                                    value="true"
                                                    control={control}
                                                />
                                            </WithLabel>
                                        </S.GridItem>
                                        <S.GridItem cols={4}>
                                            {
                                                listaPerfis &&
                                                listaPerfis.length > 0 && (
                                                    <Select
                                                        id="profile"
                                                        name="profile"
                                                        label="Perfis"
                                                        control={control}
                                                        options={
                                                            listaPerfis.map((item: any) => ({
                                                                value: item.id,
                                                                label: item.nomePerfil
                                                            }))
                                                        }
                                                    />
                                                )
                                            }
                                            <WithLabel text="&nbsp;">
                                                <Button
                                                    disabled={watch("profile") === "Selecione"}
                                                    onClick={insertProfile}
                                                >
                                                    Inserir
                                                </Button>
                                            </WithLabel>
                                        </S.GridItem>
                                        <br />
                                        <S.GridItem cols={2}>
                                            {
                                                novaListaPerfil &&
                                                    novaListaPerfil.length > 0 ?
                                                    (
                                                        <ItemsList>
                                                            <HeaderList
                                                                items={permiteExcluir ? ["Nome", "Ações"] : ["Nome"]}
                                                                big={[0]}
                                                            />
                                                            <>
                                                                {
                                                                    novaListaPerfil.map((item: any, index: any) => {
                                                                        return (
                                                                            <ListItem key={index}>
                                                                                <div className="big">{item.nomePerfil || "N/A"}</div>
                                                                                {
                                                                                    permiteExcluir && (
                                                                                        <div>
                                                                                            <ActionButton act="delete" onClick={() => removeProfile(item.id)}>
                                                                                                <Trash />
                                                                                            </ActionButton>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </ListItem>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        </ItemsList>
                                                    ) : (<span>Nenhum perfil.</span>)
                                            }
                                        </S.GridItem>
                                        <br />
                                        <S.GridItem cols={5}>
                                            <Button
                                                disabled={(!permiteAlterar || !permiteInserir) && !isValid}
                                                onClick={salvarUsuario}
                                            >
                                                {hasUser ? "Salvar" : "Adicionar"}
                                            </Button>
                                        </S.GridItem>
                                    </>
                                )
                            }
                        </S.ControlView>
                    )
                }
            </S.Content>
        </Template>
    )
}

export default UsuarioCadastro