import { useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import Footer from "./components/Footer";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import CaseSearch from './pages/CaseSearch/index';
import CadastroClienteMacdata from './pages/ClienteMacdata/Cadastro/index';
import Home from './pages/Home/index';
import LegalCases from "./pages/LegalCases/index";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import PeopleData from './pages/People/PeopleData/index';
import PeopleSearch from './pages/People/PeopleSearch/index';
import Qualification from './pages/People/Qualification/index';
import RedefinirSenha from './pages/RedefinirSenha/index';
import ProvisioningReport from "./pages/Reports/Provisioning";
import LegalCaseArea from './pages/Settings/LegalCaseArea/index';
import MotivoBaixa from "./pages/Settings/MotivoBaixa";
import PeopleRelation from './pages/Settings/PeopleRelation/index';
import PoloCliente from "./pages/Settings/PoloCliente";
import Profiles from './pages/Settings/Profiles/index';
import RiskGroupTitle from './pages/Settings/RiskGroupTitle/index';
import RiskPhases from './pages/Settings/RiskPhases/index';
import RiskReason from './pages/Settings/RiskReason/index';
import RiskSubtitle from "./pages/Settings/RiskSubtitle";
import RiskTitle from './pages/Settings/RiskTitle/index';
import Unit from './pages/Settings/Unit/index';
import UsuarioCadastro from "./pages/Usuario/Cadastro";
import { useAuthStore } from './stores/AuthStore';
import { AppStyle, PagesWrapper } from './styles/app';
import GlobalStyle from "./styles/global";

interface IPrivate {
  children: JSX.Element
}

const App = () => {

  const [authenticated, isAuthenticated, user] = useAuthStore((state) => [state.authenticated, state.isAuthenticated, state.user]);

  const Private = ({ children }: IPrivate) => {
    if (authenticated) {
      return children
    } else {
      return <Navigate to="/" />
    }
  }

  useEffect(() => {
    isAuthenticated();
  }, [isAuthenticated])

  return (
    <AppStyle>
      <GlobalStyle />
      <Router>
        {
          authenticated && user?.tipoStatusLogin === "Ativo" && (
            <Header />
          )
        }
        <PagesWrapper>
          <SideBar />
          <Routes>
            <Route path="/" element={authenticated ? <Navigate to="/inicio" /> : <Login />} />
            <Route path="/inicio" element={<Private><Home /></Private>} />
            <Route path="/casos-juridicos/buscar" element={<Private><CaseSearch /></Private>} />
            <Route path="/casos-juridicos/dados" element={<Private><LegalCases /></Private>} />
            {/* PESSOAS */}
            <Route path="/pessoas/buscar-pessoa" element={<Private><PeopleSearch /></Private>} />
            <Route path="/pessoas/dados-pessoa" element={<Private><PeopleData /></Private>} />
            {/* CONFIGURACOES */}
            <Route path="/configuracoes/relacao-pessoa" element={<Private><PeopleRelation /></Private>} />
            <Route path="/configuracoes/fases-risco" element={<Private><RiskPhases /></Private>} />
            <Route path="/configuracoes/risco-grupo-titulo" element={<Private><RiskGroupTitle /></Private>} />
            <Route path="/configuracoes/risco-titulo" element={<Private><RiskTitle /></Private>} />
            <Route path="/configuracoes/risco-subtitulo" element={<Private><RiskSubtitle /></Private>} />
            <Route path="/configuracoes/risco-motivo" element={<Private><RiskReason /></Private>} />
            <Route path="/configuracoes/caso-juridico-area" element={<Private><LegalCaseArea /></Private>} />
            <Route path="/configuracoes/unidade" element={<Private><Unit /></Private>} />
            <Route path="/configuracoes/perfis" element={<Private><Profiles /></Private>} />
            <Route path="/configuracoes/qualificacao" element={<Private><Qualification /></Private>} />
            <Route path="/motivo-baixa/cadastro" element={<Private><MotivoBaixa /></Private>} />
            <Route path="/polo-cliente/cadastro" element={<Private><PoloCliente /></Private>} />
            {/* CLIENTE MACDATA */}
            <Route path="/cliente-macdata/cadastro" element={<Private><CadastroClienteMacdata /></Private>} />
            {/* NOT FOUND 404 */}
            <Route path="*" element={<Navigate to="/not-found" />} />
            <Route path="/not-found" element={<NotFound />} />
            {/* REDEFINIR SENHA */}
            <Route path="/redefinir-senha" element={<RedefinirSenha />} />
            {/* RELATÓRIOS */}
            <Route path="/relatorios/provisionamento" element={<Private><ProvisioningReport /></Private>} />
            {/* USUARIO */}
            <Route path="/usuario/cadastro" element={<Private><UsuarioCadastro /></Private>} />
          </Routes>
        </PagesWrapper>
      </Router>
      <Footer />
      <ToastContainer
        containerId="ToastContainer"
        position="top-center"
        autoClose={2000}
        transition={Slide}
        limit={2}
      />
    </AppStyle>
  )
}

export default App;
