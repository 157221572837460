import { ButtonHTMLAttributes } from "react"
import * as S from "./style"

interface IActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    act: "edit" | "delete" | "confirm"
}

const ActionButton = ({ ...props }: IActionButtonProps) => {
    return (
        <S.StyledActionButton type="button" {...props} act={props.act} title={props.act === "edit" ? "Editar" : props.act === "confirm" ? "Confirmar" : "Excluir"}>
            {props.children}
        </S.StyledActionButton>
    )
}

export default ActionButton