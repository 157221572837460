import { createGlobalStyle } from "styled-components"
import LoginBackground from "../assets/svg/loginBackground.svg"

const GlobalStyle = createGlobalStyle`
    :root {
        --blue-primary: #0057d8;
        --light-blue: #529efe;
        --very-light-blue: #719bd9;
        --medium-blue: #0248b0;
        --dark-blue: #01337d;
        --disabled-blue: #1c3459;
        --text-blue: #505f79;
        --light-gray: #eeeeee;
        --medium-gray: #858585;
        --soft-gray: #37474F;
        --dark-gray: #404347;
        --black: #151516;
        --black-70: #15151670;
        --white: #FCFCFC;
        --pure-white: #FFFFFF;
        --red: #DC4141;
        --yellow: #ff8d43;
        --green: #00a65a;
    }

    html {
        margin: 0;
        padding: 0;
    }

    body {
        -webkit-font-smoothing: antialiased;
        background-color: var(--white);
        color: var(--black);
        width: 100%;
        min-height: 100vh;
        font-weight: 500;

        background-color: #003152;
        background-image: url(${LoginBackground});
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;     
        font-family: "Sora", sans-serif;
    }

    /* *:not(button) {
        font-size: 95%;
    } */

    button {
        border: none;
        outline: none;
        cursor: pointer;
    }

    input[type="checkbox"] {
        cursor: pointer;
    }

    .error {
        color: var(--red);
        font-size: .875rem;
    }
    
`

export default GlobalStyle