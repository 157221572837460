import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useSearchParams } from 'react-router-dom';
import PageTitle from "../../../components/PageTitle";
import TabSwitcher from "../../../components/TabSwitcher";
import Template from '../../../components/Template/index';
import * as S from "./style";
import GerarRelatorios from "./views/Filter";
import RelatoriosGerados from "./views/Generated";


const MenuList = (storedId: string | null, geracaoRelatorio?: any) => {
    return [
        "Gerar Relatório",
        "Relatórios Gerados"
    ]
}

const ProvisioningReport = () => {

    const [searchParams] = useSearchParams()
    const [pageSelected, setPageSelected] = useState<number>(0)
    const [storedId, setStoredId] = useState<string | null>(searchParams.get("idCaso"))
    const [projecaoCasoJuridico, setProjecaoCasoJuridico] = useState<any>(null)

    useEffect(() => {
        let idCaso = searchParams.get("idCaso")

    }, [searchParams.get("idCaso")])

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isValid
        },
        getValues,
        setValue,
        register,
        getFieldState,
        reset,
        watch,
        setError
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    })

    return (
        <Template>
            <PageTitle>
                Relatório de Gestão de Provisionamento
            </PageTitle>
            <S.ReportsContent>
                <TabSwitcher
                    minimal
                    list={MenuList(storedId, projecaoCasoJuridico)}
                    view={pageSelected}
                    setView={setPageSelected}
                />
                <S.PageHandlerContainer>
                    {/* GERAR RELATÓRIO */}
                    <S.ControlView active={pageSelected === 0}>
                        <GerarRelatorios
                            storedId={storedId}
                            setStoredId={setStoredId}
                            pageSelected={pageSelected}
                        />
                    </S.ControlView>
                    {/* RELATÓRIOS GERADOS */}
                    {
                        pageSelected === 1 && (
                            <S.ControlView active={pageSelected === 1}>
                                <RelatoriosGerados />
                            </S.ControlView>
                        )
                    }
                </S.PageHandlerContainer>
            </S.ReportsContent>
        </Template>
    )
}

export default ProvisioningReport