import { Control, FieldValues } from "react-hook-form";
import * as S from "./style"

interface ITextArea {
    type?: string;
    id: string;
    name: string;
    error?: string | any;
    control: Control<FieldValues, object>;
    label?: string;
    required?: boolean;
    [key: string]: any;
}

export const TextArea = ({
    type = "text",
    id,
    name,
    error,
    control,
    label,
    required = false,
    ...rest
}: ITextArea) => {
    return (
        <S.TextAreaContainer>
            {
                label && (
                    <S.StyledLabel htmlFor={id}>{label}</S.StyledLabel>
                )
            }
            <S.StyledTextArea id={id} {...control?.register(name)} error={error} {...rest}/>
            {
                error && (
                    <S.StyledError>
                        {error}
                    </S.StyledError>
                )
            }
        </S.TextAreaContainer>
    )
}

export default TextArea