import styled from "styled-components";

export const AppStyle = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
`

export const PagesWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
`