import * as S from "./style"

const Loader = () => {
    return (
        <S.LoaderContainer>
            <S.LoaderWrapper>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </S.LoaderWrapper>
        </S.LoaderContainer>
    )
}

export default Loader