import * as S from "./style"

interface IContainer {
    title: string;
    children: JSX.Element | JSX.Element[] | string;
}

const Container = ({
    title,
    children
}: IContainer) => {
    return (
        <S.ContainerWrapper>
            <S.ContainerHeader>
                {title}
            </S.ContainerHeader>
            <S.ContainerBody>
                {children}
            </S.ContainerBody>
        </S.ContainerWrapper>
    )
}

export default Container