import * as S from "./style"

interface IPageTitle {
    children: JSX.Element | string;
    maxWidth?: string;
}

const PageTitle = ({ children, maxWidth = "150" }: IPageTitle) => {
    return (
        <S.StyledPageTitle maxWidth={maxWidth}>
            {children}
        </S.StyledPageTitle>
    )
}

export default PageTitle