import styled from "styled-components";

interface ITabSwitcherWrapper {
    minimal?: boolean;
}

export const TabSwitcherWrapper = styled.div<ITabSwitcherWrapper>`
    width: 100%;
    max-width: ${props => props.minimal ? "100%" : "100%"};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: ${props => props.minimal ? "0" : "0 3rem"};
    border-bottom: 1px solid #033152;

    @media (min-width: 1350px) {
        font-size: .5rem !important;
    }
`

interface ITabSwitcherHandlerButton {
    active: boolean;
    minimal?: boolean;
}

export const TabSwitcherHandlerButton = styled.button<ITabSwitcherHandlerButton>`
    font-size: .8rem;
    background-color: #033152;
    border-radius: .4rem .4rem 0 0;
    padding: .5rem 0;
    color: var(--white);
    width: 9rem;
    transition: filter .2s ease;
    filter: brightness(${props => props.active ? "1.8" : "1"});
    font-weight: ${props => props.active ? "500" : "400"};

    :not(:first-child) {
        border-left: 1px solid var(--white);
    }

    :hover {
        filter: brightness(1.5);
    }

    @media (max-width: 1350px) {
        font-size: ${props => props.minimal && ".6rem"};
    }

    @media (max-width: 1115px) {
        height: 2.5rem;
    }
`