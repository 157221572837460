import styled from "styled-components";

export const TemplateWrapper = styled.div`
    width: 100%;
    max-width: calc(100% - 14rem);
    /* height: calc(100vh - 7.3125rem); */
    min-height: calc(100vh - 7.3125rem);
    padding: 2rem;
`

export const TemplateContent = styled.div`
    width: 100%;    
    margin: 0 auto;
    background: var(--white);
    border: 1px solid var(--dark-gray);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2rem;
    padding: 1rem 0;
    border-radius: .25rem;
    -webkit-box-shadow: 0px 0px 9px 0px #00000060; 
    box-shadow: 0px 0px 9px 0px #00000060;
    position: relative;
    /* overflow-y: scroll; */
`