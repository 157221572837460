import * as S from "../../style"
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { obterListaObjetoCasoJuridico, obterListaParteCasoJuridico, obterProjecaoCasoJuridico } from "../../../../services/api/CasoJuridico";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import WithLabel from "../../../../components/WithLabel";
import Container from "../../../../components/Container";
import { obterListaRiscoMotivo } from '../../../../services/api/Configuracoes/RiscoMotivo/index';
import ItemsList, { HeaderList, ListItem } from "../../../../components/ItemsList";
import { convertDate, convertCurrency } from '../../../../utils/index';

interface IProjecao {
    storedId?: string;
}

const Projecao = ({ storedId }: IProjecao) => {
    const [listaPartesContrarias, setListaPartesContrarias] = useState<any>([])
    const [listaObjetos, setListaObjetos] = useState<any>([])
    const [tipoCalculo, setTipoCalculo] = useState<number | null>(null)
    const [listaMotivo, setListaMotivo] = useState<any>([])
    const [projecaoCasoJuridico, setProjecaoCasoJuridico] = useState<any>(null)

    useEffect(() => {
        if (storedId) {
            ObterListaParteCasoJuridico(+storedId)
            ObterListaObjetoCasoJuridico(+storedId)
            ObterProjecoesCasoJuridico(+storedId)
        }
        ObterListaRiscoMotivo()
    }, [storedId])

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isDirty,
            isValid
        },
        trigger,
        getValues,
        setValue,
        register,
        getFieldState,
        reset,
        watch,
        setError
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    })

    useEffect(() => {
        let tCalculo = watch("tipoCalculo")
        tCalculo && tCalculo !== "Selecione" && setTipoCalculo(+tCalculo)
    }, [watch("tipoCalculo")])

    const ObterListaParteCasoJuridico = async (idCaso: number) => {
        let res = await obterListaParteCasoJuridico(idCaso)
        let partesContrarias = res.filter((item: any) => {
            return item.idPessoaRelacao === 2
        })
        setListaPartesContrarias(partesContrarias)
    }

    const ObterListaObjetoCasoJuridico = async (idCaso: number) => {
        let res = await obterListaObjetoCasoJuridico(idCaso)
        setListaObjetos(res)
    }

    const ObterListaRiscoMotivo = async () => {
        let res = await obterListaRiscoMotivo()
        setListaMotivo(res)
    }

    const ObterProjecoesCasoJuridico = async (idCaso: number) => {
        let res = await obterProjecaoCasoJuridico(idCaso)
        setProjecaoCasoJuridico(res)
        setValue("parteContraria", res.idParteContraria)
        setValue("objeto", res.idRiscoTitulo)

        if (res.isInterromperAnoMesAposDataInicio) { // TEMPO
            setValue("tipoCalculo", "3")
            setValue("tempoAnos", res.qtdeAnosInterromper)
            setValue("tempoMeses", res.qtdeMesesInterromper)
        }
        if (res.isInterromperDataEspecifica) { // DATA/MOTIVO
            setValue("tipoCalculo", "1")
            setValue("data", res.dataInterrupcao.substring(0, 10))
            setValue("motivo", res.idRiscoMotivoInterrupcaoProjecao)
        }
        if (res.isInterromperPorEstimativaVida) { // ESTIMATIVA VIDA
            setValue("tipoCalculo", "2")
            setValue("vidaAnos", res.qtdeAnosInterromper)
            setValue("vidaMeses", res.qtdeMesesInterromper)
        }
    }

    if (projecaoCasoJuridico === null) {
        return (
            <>
                <span>Não existem dados de Projeção.</span>
            </>
        )
    }

    return (
        <>
            <S.GridItem cols={2}>
                {
                    listaPartesContrarias &&
                        listaPartesContrarias.length > 0 ? (
                        <Select
                            disabled
                            id="parteContraria"
                            name="parteContraria"
                            label="Parte Contrária"
                            control={control}
                            options={
                                listaPartesContrarias.map((item: any) => (
                                    { value: item.idPessoa, label: item.nomePessoa }
                                ))
                            }
                        />
                    ) : (
                        <span>
                            Nenhuma parte contrária.
                        </span>
                    )
                }
            </S.GridItem>
            <S.GridItem cols={1}>
                <Container title="Projeção">
                    {
                        listaObjetos && (
                            <S.GridItem cols={1}>
                                <Select
                                    disabled
                                    id="objeto"
                                    name="objeto"
                                    label="Objeto"
                                    control={control}
                                    options={
                                        listaObjetos.map((item: any) => (
                                            { value: item.idRiscoTitulo, label: item.descricaoRiscoTitulo }
                                        ))
                                    }
                                />
                            </S.GridItem>
                        )
                    }
                    <>
                        {
                            watch("objeto") !== "Selecione" && (
                                <S.GridItem cols={2}>
                                    <Select
                                        id="tipoCalculo"
                                        name="tipoCalculo"
                                        label="Tipo"
                                        control={control}
                                        options={[
                                            { value: "1", label: "Data/Motivo" },
                                            { value: "2", label: "Estimativa de Vida" },
                                            { value: "3", label: "Tempo" }
                                        ]}
                                    />
                                </S.GridItem>
                            )
                        }
                    </>
                    <>
                        {
                            tipoCalculo === 1 && (
                                <S.GridItem cols={2}>
                                    <Input
                                        disabled
                                        type="date"
                                        id="data"
                                        name="data"
                                        control={control}
                                        label="Cesar Projeção em"
                                    />
                                    <Select
                                        disabled
                                        id="motivo"
                                        name="motivo"
                                        label="Motivo"
                                        control={control}
                                        options={
                                            listaMotivo.map((item: any) => (
                                                { value: item.id, label: item.descricao }
                                            ))
                                        }
                                    />
                                </S.GridItem>
                            )
                        }
                        {
                            tipoCalculo === 2 && (
                                <S.GridItem cols={2}>
                                    <S.GridItem cols={2}>
                                        <Input
                                            disabled
                                            type="number"
                                            id="vidaAnos"
                                            name="vidaAnos"
                                            label="Anos"
                                            control={control}
                                        />
                                        <Input
                                            disabled
                                            type="number"
                                            id="vidaMeses"
                                            name="vidaMeses"
                                            label="Meses"
                                            control={control}
                                        />
                                    </S.GridItem>
                                    <WithLabel text="&nbsp;">
                                        Interrupção em {convertDate(projecaoCasoJuridico.dataInterrupcao.substring(0, 10))}
                                    </WithLabel>
                                </S.GridItem>
                            )
                        }
                        {
                            tipoCalculo === 3 && (
                                <S.GridItem cols={4}>
                                    <Input
                                        type="number"
                                        id="tempoAnos"
                                        name="tempoAnos"
                                        label="Anos"
                                        control={control}
                                    />
                                    <Input
                                        type="number"
                                        id="tempoMeses"
                                        name="tempoMeses"
                                        label="Meses"
                                        control={control}
                                    />
                                </S.GridItem>
                            )
                        }
                    </>
                </Container>
            </S.GridItem>
            {
                projecaoCasoJuridico && (
                    <S.GridItem cols={1}>
                        <Container title="Valores">
                            <ItemsList>
                                <HeaderList items={[
                                    "Data Início",
                                    "Valor Mensal",
                                    "INSS Emp. Mensal",
                                    "Projeção Aritmética",
                                    "Total Risco"
                                ]} />
                                <ListItem>
                                    <div>{convertDate(projecaoCasoJuridico.dataInicio.substring(0, 10))}</div>
                                    <div>{convertCurrency(projecaoCasoJuridico.valorPrincipal)}</div>
                                    <div>{convertCurrency(projecaoCasoJuridico.valorInssSelic + projecaoCasoJuridico.valorInssTst)}</div>
                                    <div>{convertCurrency(projecaoCasoJuridico.projecaoAtuarial)}</div>
                                    <div>{
                                        convertCurrency(
                                            projecaoCasoJuridico.percentualAjuste !== null ?
                                                (projecaoCasoJuridico.projecaoAtuarial * (projecaoCasoJuridico.percentualAjuste / 100)) :
                                                projecaoCasoJuridico.projecaoAtuarial
                                        )
                                    }</div>
                                </ListItem>
                            </ItemsList>
                        </Container>
                    </S.GridItem>
                )
            }
        </>
    )
}

export default Projecao