import styled from "styled-components";

export const PeopleDataHandlerWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 3rem;
    border-bottom: 1px solid #033152;
`

interface IPeopleDataHandlerButton {
    active?: boolean;
}

export const PeopleDataHandlerButton = styled.button<IPeopleDataHandlerButton>`
    font-size: .8rem;
    background-color: #033152;
    border-radius: .4rem .4rem 0 0;
    padding: 0.5rem 0;
    color: var(--white);
    width: 9rem;
    transition: filter .2s ease;
    filter: brightness(${props => props.active ? "1.8" : "1"});

    font-weight: ${props => props.active ? "500" : "400"};

    :not(:first-child) {
        border-left: 1px solid var(--white);
    }

    :hover {
        filter: brightness(1.5);
    }
`