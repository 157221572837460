import { privateDelete } from '../../../methods/delete';
import { privateGet } from '../../../methods/get';
import { privatePost } from '../../../methods/post';
import { privatePut } from '../../../methods/put';
import { IAdicionarPoloCliente, IAlterarPoloCliente, IExcluirPoloCliente, IObterListaPoloClienteResponse } from '../../../models/Configuracoes/PoloCliente';
const basePath = "/configuracao/polocliente"

export const adicionarPoloCliente = (body: IAdicionarPoloCliente) => {
    return privatePost<string>(`${basePath}/adicionar`, body)
}

export const obterListaPoloCliente = (idTipoSituação?: number) => {
    return privateGet<IObterListaPoloClienteResponse>(`${basePath}/obterlistacasopolocliente?idTipoSituacao=${idTipoSituação || 3}`)
}

export const alterarPoloCliente = (body: IAlterarPoloCliente) => {
    return privatePut<string>(`${basePath}/alterar`, body)
}

export const excluirPoloCliente = (body: IExcluirPoloCliente) => {
    return privateDelete<string>(`${basePath}/excluir`, body)
}