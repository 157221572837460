import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageTitle from "../../components/PageTitle";
import TabSwitcher from "../../components/TabSwitcher";
import Template from '../../components/Template/index';
import { obterProjecaoCasoJuridico } from '../../services/api/CasoJuridico/index';
import * as S from "./style";
import Calculos from "./views/Calculos";
import Consulta from "./views/Consulta";
import DadosDoCaso from "./views/DadosDoCaso";
import Distribuicao from "./views/Distribuicao";
import InfosAdicionais from "./views/InfosAdicionais";
import Lancamentos from "./views/Lancamentos";
import Objetos from "./views/Objetos";
import Partes from "./views/Partes";
import Projecao from "./views/Projecao";
import Button from '../../components/Button';
import { usePermissionsStore } from '../../stores/PermissionsStore';

const MenuList = (storedId: string | null, projecaoCasoJuridico?: any) => {
    if (storedId) {
        if (!projecaoCasoJuridico) {
            return [
                "Dados do Caso",
                "Partes",
                "Objetos",
                "Lançamentos",
                "Distribuição",
                "Consulta",
                "Cálculos",
                "Infos Adicionais"
            ]
        }
        return [
            "Dados do Caso",
            "Partes",
            "Objetos",
            "Lançamentos",
            "Distribuição",
            "Projeção",
            "Consulta",
            "Cálculos",
            "Infos Adicionais"
        ]
    } else {
        return [
            "Dados do Caso"
        ]
    }
}

const LegalCases = () => {
    const [searchParams] = useSearchParams()
    const [storedId, setStoredId] = useState<string | null>(searchParams.get("idCaso"))
    const [pageSelected, setPageSelected] = useState<number>(0)
    const [caseIsSctr, setCaseIsSctr] = useState<boolean>(false)
    const [caseFaseProvisao, setCaseFaseProvisao] = useState<number | null>(null)

    const [listaObjetosAdicionados, setListaObjetosAdicionados] = useState<any>([])
    const [projecaoCasoJuridico, setProjecaoCasoJuridico] = useState<any>(null)

    const navigate = useNavigate()

    useEffect(() => {
        let idCaso = searchParams.get("idCaso")

        if (idCaso) {
            ObterProjecoesCasoJuridico(+idCaso)
        }
    }, [searchParams.get("idCaso")])

    const ObterProjecoesCasoJuridico = async (idCaso: number) => {
        let res = await obterProjecaoCasoJuridico(idCaso)
        setProjecaoCasoJuridico(res)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(17);

    const {
        permiteAlterar,
        permiteInserir,
        permiteExcluir
    } = permissions

    return (
        <Template>
            <PageTitle>
                Casos Jurídicos
            </PageTitle>
            {
                !storedId && (permiteAlterar || permiteInserir) && (
                    <div style={{ fontSize: ".8rem", margin: "-1rem 0 -2rem 3rem", display: "flex", flexDirection: "column" }}>
                        <span>* Campos obrigatórios</span>
                        <span>** Insira e salve os Dados do Caso para criar o Caso Jurídico</span>
                    </div>
                )
            }
            <S.LegalCasesContent>
                {
                    storedId && (
                        <div style={{ flex: ".2" }}>
                            <Button onClick={() => navigate("/casos-juridicos/buscar")}>Voltar para pesquisa</Button>
                        </div>
                    )
                }
                <TabSwitcher
                    minimal
                    list={MenuList(storedId, projecaoCasoJuridico)}
                    view={pageSelected}
                    setView={setPageSelected}
                />
                <S.PageHandlerContainer>
                    <S.ItemDataContent id="casoJuridicoForm">
                        {/* DADOS DO CASO */}
                        <S.ControlView active={pageSelected === 0}>
                            <DadosDoCaso
                                storedId={storedId}
                                setStoredId={setStoredId}
                                pageSelected={pageSelected}
                                setCaseIsSctr={setCaseIsSctr}
                                setCaseFaseProvisao={setCaseFaseProvisao}
                            />
                        </S.ControlView>
                        {/* PARTEs */}
                        {
                            pageSelected === 1 && (
                                <S.ControlView active={pageSelected === 1}>
                                    <Partes storedId={storedId} />
                                </S.ControlView>
                            )
                        }
                        {/* OBJETOS */}
                        {
                            pageSelected === 2 &&
                            storedId && (
                                <S.ControlView active={pageSelected === 2}>
                                    <Objetos
                                        storedId={storedId}
                                        listaObjetosAdicionados={listaObjetosAdicionados}
                                        setListaObjetosAdicionados={setListaObjetosAdicionados}
                                        caseIsSctr={caseIsSctr}
                                    />
                                </S.ControlView>
                            )
                        }
                        {/* LANCAMENTOS */}
                        {
                            pageSelected === 3 &&
                            storedId && (
                                <S.ControlView active={pageSelected === 3}>
                                    <Lancamentos
                                        storedId={storedId}
                                        caseIsSctr={caseIsSctr}
                                        caseFaseProvisao={caseFaseProvisao}
                                    />
                                </S.ControlView>
                            )
                        }
                        {/* DISTRIBUICAO */}
                        {
                            pageSelected === 4 &&
                            storedId && (
                                <S.ControlView active={pageSelected === 4}>
                                    <Distribuicao storedId={storedId} caseFaseProvisao={caseFaseProvisao} />
                                </S.ControlView>
                            )
                        }
                        {/* PROJECAO */}
                        {
                            !!projecaoCasoJuridico &&
                            pageSelected === 5 &&
                            storedId && (
                                <S.ControlView active={pageSelected === 5}>
                                    <Projecao storedId={storedId} />
                                </S.ControlView>
                            )
                        }
                        {/* CONSULTA */}
                        {
                            pageSelected === (!!projecaoCasoJuridico ? 6 : 5) &&
                            storedId && (
                                <S.ControlView active={pageSelected === (!!projecaoCasoJuridico ? 6 : 5)}>
                                    <Consulta storedId={storedId} caseFaseProvisao={caseFaseProvisao} />
                                </S.ControlView>
                            )
                        }
                        {/* CALCULOS */}
                        {
                            pageSelected === (!!projecaoCasoJuridico ? 7 : 6) &&
                            storedId && (
                                <S.ControlView active={pageSelected === (!!projecaoCasoJuridico ? 7 : 6)}>
                                    <Calculos storedId={storedId} />
                                </S.ControlView>
                            )
                        }
                        {/* INFOS ADICIONAIS */}
                        {
                            pageSelected === (!!projecaoCasoJuridico ? 8 : 7) &&
                            storedId && (
                                <S.ControlView active={pageSelected === (!!projecaoCasoJuridico ? 8 : 7)}>
                                    <InfosAdicionais storedId={storedId} />
                                </S.ControlView>
                            )
                        }
                    </S.ItemDataContent>
                </S.PageHandlerContainer>
            </S.LegalCasesContent>
        </Template>
    )
}

export default LegalCases