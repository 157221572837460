import { privateGet } from '../../../methods/get';
import { privatePost } from '../../../methods/post';
import { IAdicionarCasoJuridicoArea, IAlterarCasoJuridicoArea, IExcluirCasoJuridicoArea, IObterListaCasoJuridicoAreaResponse } from '../../../models/Configuracoes/CasoJuridicoArea/index';
import { privatePut } from '../../../methods/put';
import { privateDelete } from '../../../methods/delete';
const baseApiPath = "/configuracao/casojuridicoarea"

export const adicionarCasoJuridicoArea = (body: IAdicionarCasoJuridicoArea) => {
    return privatePost<string>(`${baseApiPath}/adicionar`, body)
}

export const obterListaCasoJuridicoArea = (idTipoSituacao?: number) => {
    return privateGet<IObterListaCasoJuridicoAreaResponse>(`${baseApiPath}/obterlistacasojuridicoarea?idTipoSituacao=${idTipoSituacao || 3}`)
}

export const alterarCasoJuridicoArea = (body: IAlterarCasoJuridicoArea) => {
    return privatePut<string>(`${baseApiPath}/alterar`, body)
}

export const excluirCasoJuridicoArea = (body: IExcluirCasoJuridicoArea) => {
    return privateDelete<string>(`${baseApiPath}/excluir`, body)
}