import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from "yup";
import Button from "../../../components/Button";
import Checkbox from '../../../components/Checkbox/index';
import Input from "../../../components/Input";
import ListTable from "../../../components/List";
import PageTitle from "../../../components/PageTitle";
import Select from "../../../components/Select";
import Template from "../../../components/Template";
import TextArea from "../../../components/TextArea";
import WithLabel from "../../../components/WithLabel";
import { obterListaComarca, obterListaUF } from '../../../services/api/Comarca/index';
import { adicionarPessoa, obterPessoa } from "../../../services/api/Pessoa";
import { obterInformacaoAdicionalPessoa, obterListaTipoNaturezaPessoa, obterListaTipoSexo, excluirPessoa, alterarPessoa } from '../../../services/api/Pessoa/index';
import PeopleDataHandler from "./PeopleDataHandler";
import * as S from "./style";
import { obterListaPessoaQualificacao } from '../../../services/api/Pessoa/Qualificacao/index';
import { obterListaPerfil } from '../../../services/api/Perfil/index';
import ModalAction from '../../../components/ModalAction/index';
import MaskedInput from "../../../components/MaskInput";
import ItemsList, { HeaderList, ListItem } from "../../../components/ItemsList";
import ActionButton from '../../../components/ActionButton/index';
import { Trash } from "phosphor-react";
import { toast } from 'react-toastify';
import { defaultValues } from './defaultValues';
import { usePermissionsStore } from "../../../stores/PermissionsStore";

const PeopleDataSchema = yup.object({
    id: yup.number(),
    sequencial: yup.string().max(6, "Máximo de 6 dígitos."),
    idTipoSexo: yup.string().not(["Selecione"], "Selecione uma opção.").required("Campo obrigatório."),
    email: yup.string().email("E-mail inválido.").nullable(),
    nome: yup.string().max(100, "Limite de 100 caracteres.")
})

interface IPerfis {
    id: number;
    nomePerfil: string;
    isAdmin: boolean;
    isConfidencial: boolean;
}

const defaultBlankOption = [{ value: "N/A", label: "N/A" }]

const PeopleData = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [storedId, setStoredId] = useState<string | null>(null)

    const [view, setView] = useState<0 | 1 | 2>(0)

    const [tipoNaturezaList, setTipoNaturezaList] = useState<any>(defaultBlankOption)
    const [tipoSexoList, setTipoSexoList] = useState<any>(defaultBlankOption)
    const [listaUF, setListaUF] = useState<any>(defaultBlankOption)
    const [listaQualificacao, setListaQualificacao] = useState<any>(defaultBlankOption)
    const [listaPerfil, setListaPerfil] = useState<any>(defaultBlankOption)

    const [confirmDelete, setConfirmDelete] = useState<boolean>(false)

    const [pegouDados, setPegouDados] = useState<any>()

    const [novaListaPerfil, setNovaListaPerfil] = useState<any[]>()

    const [tel1mask, setTel1mask] = useState<string>("(99) 9999-9999*")
    const [tel2mask, setTel2mask] = useState<string>("(99) 9999-9999*")
    const [cnpjCpfMask, setCnpjCpfMask] = useState<string>("999.999.999-99")
    const [passType, setPassType] = useState<boolean>(true)
    const [hasErrorPessoaUsuario, setHasErrorPessoaUsuario] = useState<boolean>(false)

    const navigate = useNavigate()

    useEffect(() => {
        const idPessoa = searchParams.get("idPessoa")
        if (idPessoa) {
            setStoredId(idPessoa)
        }
    }, [])

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isValid
        },
        getValues,
        register,
        reset,
        watch,
        setError
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(PeopleDataSchema)
    })

    useEffect(() => {
        if (storedId) {
            getPeopleData(storedId)
            getInfoAdicional(Number(storedId))
        }
        getTipoNatureza()
        getTipoSexo()
        getUF()
        getQualificacao()
        getPerfis()
    }, [storedId])

    useEffect(() => {
        if (pegouDados) {
            reset(pegouDados)
            pegouDados?.pessoaUsuario?.perfis && setNovaListaPerfil([...pegouDados?.pessoaUsuario?.perfis])
        }
    }, [pegouDados])

    const getPeopleData = async (id: string) => {
        let res = await obterPessoa({ idPessoa: parseInt(id) })
        let ret = {
            ...res,
            siglaUf: res.siglaUf
        }
        setPegouDados(ret)
    }

    const getTipoNatureza = async () => {
        let res = await obterListaTipoNaturezaPessoa()
        setTipoNaturezaList(res)
    }

    const getTipoSexo = async () => {
        let res = await obterListaTipoSexo()
        setTipoSexoList(res)
    }

    const getQualificacao = async () => {
        let res = await obterListaPessoaQualificacao()
        setListaQualificacao(res)
    }

    const getPerfis = async () => {
        let res = await obterListaPerfil()
        setListaPerfil(res)
    }

    const getUF = async () => {
        let res = await obterListaUF()
        setListaUF(res)
    }

    const getInfoAdicional = async (id: number) => {
        let res = await obterInformacaoAdicionalPessoa({ idPessoa: id })
        reset(res)
    }

    const peopleAction = (data: any) => {
        storedId ? editPeople(data) : addPeople(data)
    }

    const addPeople = async (data: any) => {
        const body = {
            nome: data.nome,
            cnpjCpf: data.cnpjCpf,
            oab: data.oab,
            email: data.email,
            observacao: data.observacao,
            dataNascimento: data.dataNascimento,
            idTipoNaturezaPessoa: +data.idTipoNaturezaPessoa,
            idTipoSexo: +data.idTipoSexo,
            isAtivo: !!data.isAtivo,
            pessoaUsuario: validarPessoaUsuario() ? {
                idUsuario: data.pessoaUsuario.idUsuario !== "" ? data.pessoaUsuario.idUsuario : 0,
                senha: data.pessoaUsuario.senha !== "" ? data.pessoaUsuario.senha : null,
                alterarSenhaProximoLogin: !(data.pessoaUsuario.alterarSenhaProximoLogin === "sim"),
                perfis: novaListaPerfil?.map((item) => item.id)
            } : null
        }
        let res = await adicionarPessoa(body)
    }

    const validarPessoaUsuario = () => {
        if (
            (watch("pessoaUsuario.senha") !== "") &&
            (watch("pessoaUsuario.idUsuario") !== "") &&
            (novaListaPerfil)
        ) {
            return true
        } else {
            return false
        }
    }

    const editPeople = async (data: any) => {
        const body = {
            id: Number(storedId),
            idTipoNaturezaPessoa: +data.idTipoNaturezaPessoa,
            nome: data.nome,
            cnpjCpf: data.cnpjCpf,
            oab: data.oab,
            email: data.email,
            observacao: data.observacao,
            dataNascimento: data.dataNascimento,
            idTipoSexo: +data.idTipoSexo,
            isAtivo: data.isAtivo,
            pessoaUsuario: validarPessoaUsuario() ? {
                idUsuario: data.pessoaUsuario.idUsuario ? data.pessoaUsuario.idUsuario : 0,
                senha: data.pessoaUsuario.senha !== "" ? data.pessoaUsuario.senha : null,
                alterarSenhaProximoLogin: data.pessoaUsuario.alterarSenhaProximoLogin === "sim",
                perfis: novaListaPerfil?.map((item) => item.id)
            } : null
        }
        let res = await alterarPessoa(body).then(() => {
            navigate({
                pathname: "/pessoas/dados-pessoa",
                search: `?idPessoa=${storedId}`
            })
        })
    }

    const removePeople = async () => {
        let idPessoa = Number(storedId)
        await excluirPessoa({ idPessoa })
        navigate("/pessoas/buscar-pessoa")
    }

    const removeProfile = (itemID?: number) => {
        if (itemID) {
            listaPerfil.forEach((item: any) => {
                if (itemID === item.id) {
                    let novo: any = []
                    novaListaPerfil?.forEach((iti: any) => {
                        if (iti.id !== item.id) {
                            novo.push(iti)
                        }
                    })
                    setNovaListaPerfil([...novo])
                    if (novo.length < 1) {
                        toast.dismiss();
                        toast.info("Usuário sem perfis perderá o acesso ao sistema!", { toastId: "lostAccessToast" })
                    }
                }
            })
        } else {
            setNovaListaPerfil([])
            toast.dismiss();
            toast.info("Usuário sem perfis perderá o acesso ao sistema!", { toastId: "lostAccessToast" })
        }
    }

    const insertProfile = () => {
        let selectedProfile = watch("profile")

        if (selectedProfile !== "Selecione") {

            listaPerfil.forEach((item: any) => {
                if (item.id === Number(selectedProfile)) {
                    if (novaListaPerfil) {
                        let v = 0
                        novaListaPerfil.forEach((it: any) => {
                            if (it.id === item.id) {
                                v = 1
                            }
                        })
                        if (v > 0) {
                            toast.dismiss()
                            toast.error("Perfil já incluído.", { toastId: "profileToast" })
                        } else { setNovaListaPerfil([...novaListaPerfil, item]) }
                    } else {
                        setNovaListaPerfil([item])
                    }
                }
            })
        }
    }

    useEffect(() => {
        let tel1 = watch("telefone1")?.replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "")

        if (tel1?.length < 11) {
            setTel1mask("(99) 9999-9999*")
        } else {
            setTel1mask("(99) 99999-9999")
        }
    }, [watch("telefone1")])

    useEffect(() => {
        let tel2 = watch("telefone2")?.replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "")

        if (tel2?.length < 11) {
            setTel2mask("(99) 9999-9999*")
        } else {
            setTel2mask("(99) 99999-9999")
        }
    }, [watch("telefone2")])

    useEffect(() => {
        if (
            (watch("idTipoNaturezaPessoa") !== undefined) ||
            (watch("idTipoNaturezaPessoa") === "Selecione") ||
            (+watch("idTipoNaturezaPessoa") === 3)
        ) {
            let cpfCnpj = watch("cnpjCpf")?.replaceAll(".", "").replaceAll("-", "")

            if (cpfCnpj?.length < 12) {
                setCnpjCpfMask("999.999.999-99*")
            } else {
                setCnpjCpfMask("99.999.999/9999-99")
            }
        }
    }, [watch("cnpjCpf")])

    useEffect(() => {
        let idTipoNat = +watch("idTipoNaturezaPessoa")

        if (idTipoNat === 1) {
            setCnpjCpfMask("999.999.999-99")
        }
        if (idTipoNat === 2) {
            setCnpjCpfMask("99.999.999/9999-99")
        }

    }, [watch("idTipoNaturezaPessoa")])

    const newAction = () => {
        setStoredId(null)
        navigate("/pessoas/dados-pessoa")
        setNovaListaPerfil([])
        reset(defaultValues)
    }

    useEffect(() => {
        let senha = watch("pessoaUsuario.senha") || ""
        let alterarSenhaProximoLogin = watch("pessoaUsuario.alterarSenhaProximoLogin") === "sim"
        let listaPerfil = novaListaPerfil || []

        if (
            (senha === "") &&
            (alterarSenhaProximoLogin === false) &&
            (listaPerfil.length === 0)
        ) {
            setHasErrorPessoaUsuario(false)
        } else if (
            (senha !== "") &&
            (listaPerfil.length !== 0)
        ) {
            setHasErrorPessoaUsuario(false)
        } else if (
            (senha === "") &&
            (alterarSenhaProximoLogin !== false) &&
            (listaPerfil.length === 0)
        ) {
            setHasErrorPessoaUsuario(true)
        } else {
            setHasErrorPessoaUsuario(true)
        }
    }, [
        watch("pessoaUsuario.senha"),
        watch("pessoaUsuario.alterarSenhaProximoLogin"),
        novaListaPerfil
    ])

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(14);

    const {
        permiteAlterar,
        permiteInserir,
        permiteExcluir
    } = permissions

    return (
        <Template>
            <PageTitle>
                Dados da Pessoa
            </PageTitle>
            <S.ButtonsContainer>
                <Button onClick={() => navigate("/pessoas/buscar-pessoa")}>Voltar para pesquisa</Button>
                {
                    !storedId && permiteInserir && (
                        <Button type="submit" form="peopleData" disabled={!isValid}>Adicionar</Button>
                    )
                }
                {
                    storedId &&
                    permiteAlterar && (
                        <Button type="submit" form="peopleData">Salvar</Button>
                    )
                }
                {
                    storedId &&
                    permiteExcluir && (
                        <Button onClick={() => setConfirmDelete(true)}>Excluir</Button>
                    )
                }
            </S.ButtonsContainer>
            <PeopleDataHandler view={view} setView={setView} />
            <S.PeopleDataContent id="peopleData" onSubmit={handleSubmit((data) => peopleAction(data))}>
                <S.ControlView active={view === 0}>
                    <span style={{ fontSize: ".8rem" }}>* Campos obrigatórios.</span>
                    {
                        storedId && (
                            <S.GridItem cols={6}>
                                <MaskedInput id="sequencial" name="sequencial" disabled label="Sequencial" mask="999999" control={control} error={errors.sequencial?.message} />
                            </S.GridItem>
                        )
                    }
                    <S.GridItem cols={2}>
                        <Select id={"idTipoNaturezaPessoa"} name={"idTipoNaturezaPessoa"} label="Natureza *" control={control}
                            options={
                                tipoNaturezaList ?
                                    tipoNaturezaList.map((item: any) => ({
                                        value: item.id,
                                        label: item.descricao
                                    })) : []
                            }
                        />
                        <MaskedInput id="cnpjCpf" name="cnpjCpf" label={
                            +watch("idTipoNaturezaPessoa") === 1 ? "CPF" : +watch("idTipoNaturezaPessoa") === 2 ? "CNPJ" : "CPF ou CNPJ"
                        } control={control} error={errors.cnpjCpf?.message} mask={cnpjCpfMask} />
                    </S.GridItem>
                    <S.GridItem cols={2}>
                        <Input id="nome" name="nome" label="Nome" control={control} error={errors.nome?.message} />
                        <Input id="email" name="email" label="E-mail" control={control} error={errors.email?.message} />
                    </S.GridItem>
                    <S.GridItem cols={2}>
                        <Select
                            id="idTipoSexo"
                            name="idTipoSexo"
                            label="Sexo *"
                            options={
                                tipoSexoList ?
                                    tipoSexoList.map((item: any) => ({
                                        value: item.id,
                                        label: item.descricao
                                    })) : []
                            }
                            error={errors.idTipoSexo?.message}
                            control={control}
                        />
                        <MaskedInput id="oab" name="oab" label="OAB" control={control} mask="999999" />
                    </S.GridItem>
                    {
                        +watch("idTipoNaturezaPessoa") !== 2 && (
                            <S.GridItem cols={2}>
                                <Input type="date" id="dataNascimento" name="dataNascimento" label="Data de Nascimento" control={control} />
                            </S.GridItem>
                        )
                    }
                    {/* <S.GridItem cols={2}> */}
                    {/* <Input id="endereco" name="endereco" label="Endereço" control={control} /> */}
                    {/* </S.GridItem> */}
                    {/* <S.GridItem cols={2}>
                        <MaskedInput id="numero" name="numero" label="Número" control={control} mask="999999" />
                        <MaskedInput id="ie" name="ie" label="Instrição Estadual" control={control} mask="999999999" />
                    </S.GridItem> */}
                    {/* <S.GridItem cols={2}>
                        <Input id="bairro" name="bairro" label="Bairro" control={control} />
                        <MaskedInput id="cep" name="cep" label="CEP" control={control} mask="99.999-999" />
                    </S.GridItem> */}
                    {/* <S.GridItem cols={2}>
                        <Select
                            id="uf"
                            name="siglaUf"
                            label="UF"
                            control={control}
                            options={
                                listaUF ?
                                    listaUF.map((item: any) => ({
                                        value: item.sigla,
                                        label: item.descricao
                                    })) : []
                            }
                        />
                        <Input id="complemento" name="complemento" label="Complemento" control={control} />
                    </S.GridItem> */}
                    <S.GridItem cols={2}>
                        {/* <Select
                            disabled={listaUF && listaComarca && listaComarca === defaultBlankOption}
                            id="idComarca"
                            name="idComarca"
                            label="Comarca *"
                            control={control}
                            options={
                                listaComarca &&
                                    listaComarca !== defaultBlankOption ?
                                    listaComarca.map((item: any) => ({
                                        value: item.id,
                                        label: item.nomeComarca
                                    })) : [{ value: "Selecione a UF", label: "Selecione a UF" }]
                            }
                        /> */}
                        <WithLabel text="Situação">
                            <Checkbox id="isAtivo" name="isAtivo" label="Ativo" value={"true"} control={control} />
                        </WithLabel>
                    </S.GridItem>
                    {/* <S.GridItem cols={1}>
                        <TextArea id="historico" name="historico" label="Histórico" rows={5} control={control} />
                    </S.GridItem> */}
                    <S.GridItem cols={1}>
                        <TextArea id="observacao" name="observacao" label="Observação" rows={5} control={control} />
                    </S.GridItem>
                </S.ControlView>
                <S.ControlView active={view === 1}>
                    <S.ButtonsContainer top="-1">
                        <Button disabled>Desbloquear</Button>
                        <Button disabled>Bloquear</Button>
                        {
                            novaListaPerfil &&
                            novaListaPerfil.length > 0 &&
                            (
                                <Button onClick={() => removeProfile()}>Excluir</Button>
                            )
                        }
                    </S.ButtonsContainer>
                    <input type="hidden" id="idUsuario" disabled {...register("pessoaUsuario.idUsuario")} />
                    {
                        hasErrorPessoaUsuario && (
                            <S.GridItem cols={4}>
                                <span className="error">Preencha todos os campos!</span>
                            </S.GridItem>
                        )
                    }
                    <S.GridItem cols={4}>
                        <Input type={passType ? "password" : "text"} id="senha" name="pessoaUsuario.senha" label="Nova senha" control={control} />
                        <WithLabel text="Situação">
                            <Checkbox id="alterarSenhaProximoLogin" name="pessoaUsuario.alterarSenhaProximoLogin" label="Alterar senha no próximo login" value="sim" control={control} />
                        </WithLabel>
                    </S.GridItem>
                    <S.GridItem cols={4}>
                        <Select
                            id="profile"
                            name="profile"
                            label="Perfil"
                            control={control}
                            options={
                                listaPerfil ?
                                    listaPerfil.map((item: any) => ({
                                        value: item.id,
                                        label: item.nomePerfil
                                    })) : []
                            }
                        />
                        <WithLabel text="&nbsp;">
                            <Button onClick={() => insertProfile()} disabled={watch("profile") === "Selecione"}>Inserir</Button>
                        </WithLabel>
                    </S.GridItem>
                    <br />
                    <S.GridItem cols={2}>
                        {
                            novaListaPerfil &&
                                novaListaPerfil.length > 0 ?
                                (
                                    <ItemsList>
                                        <HeaderList
                                            items={["ID", "Nome", "Ações"]}
                                        />
                                        <>
                                            {
                                                novaListaPerfil.map((item: any, index: any) => {
                                                    return (
                                                        <ListItem key={index}>
                                                            <div>{item.id || "N/A"}</div>
                                                            <div>{item.nomePerfil || "N/A"}</div>
                                                            <div>
                                                                {/* <Button onClick={() => removeProfile(item.id)}>Remover</Button> */}
                                                                <ActionButton act="delete" onClick={() => removeProfile(item.id)}>
                                                                    <Trash />
                                                                </ActionButton>
                                                            </div>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </>
                                    </ItemsList>
                                ) : (<span>Nenhum perfil.</span>)
                        }
                    </S.GridItem>
                </S.ControlView>
                <S.ControlView active={view === 2}>
                    <S.GridItem cols={4}>
                        <Input disabled type="date" id="dataInclusao" name="dataInclusao" label="Data de Inserção" control={control} />
                    </S.GridItem>
                    <S.GridItem cols={2}>
                        <Input disabled id="usuarioInclusao" name="usuarioInclusao" label="Inserido por" control={control} />
                    </S.GridItem>
                    <S.GridItem cols={4}>
                        <Input disabled type="date" id="dataUltimaAlteracao" name="dataUltimaAlteracao" label="Data última alteração" control={control} />
                    </S.GridItem>
                    <S.GridItem cols={2}>
                        <Input disabled id="usuarioUltimaAlteracao" name="usuarioUltimaAlteracao" label="Alterado por" control={control} />
                    </S.GridItem>
                </S.ControlView>
            </S.PeopleDataContent>
            {
                storedId && confirmDelete && (
                    <ModalAction
                        type="delete"
                        onConfirm={() => removePeople()}
                        onClose={() => setConfirmDelete(false)}
                        title="Excluir pessoa"
                        item={{
                            id: Number(storedId),
                            descricao: watch("nome")
                        }}
                    />
                )
            }
        </Template>
    )
}

export default PeopleData