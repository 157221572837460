import get from "../../methods/get";
import { privatePost } from "../../methods/post";
import put, { privatePut } from "../../methods/put";
import {
    IUsuarioAdicionar,
    IUsuarioAlterar,
    IUsuarioDesbloquear,
    IUsuarioExcluir
} from "../../models/Usuario";


export const excluirUsuario = (body: IUsuarioExcluir) => {
    return put<string>("/usuario/excluir", body)
}

export const desbloquearUsuario = (body: IUsuarioDesbloquear) => {
    return put<string>("/usuario/desbloquear", body)
}

export const adicionarUsuario = (body: IUsuarioAdicionar) => {
    return privatePost<string>("/usuario/adicionar", body)
}

export const alterarUsuario = (body: IUsuarioAlterar) => {
    return privatePut<string>("/usuario/alterar", body)
}