import * as S from "./styles"
import { useLocation, useNavigate } from 'react-router-dom';
import { FiLogOut, FiUser } from "react-icons/fi";
import { useState, useEffect } from 'react';
import { useAuthStore } from "../../stores/AuthStore";
import { usePermissionsStore } from "../../stores/PermissionsStore";

const Header = () => {
    const [showOptions, setShowOptions] = useState<boolean>(false)
    const location = useLocation()
    const navigate = useNavigate()
    const [currentUser, setCurrentUser] = useState<string | null>(null)
    const [currentClient, setCurrentClient] = useState<string | null>(null)

    const logout = useAuthStore((state) => state.logout);
    const removePermissions = usePermissionsStore((state) => state.removePermissions);

    useEffect(() => {
        let usr = localStorage.getItem("user")
        let usrParsed = usr ? JSON.parse(usr) : ""
        let mountedClientName = `${usrParsed?.cName}`

        setCurrentUser(usrParsed.username)
        setCurrentClient(mountedClientName)
    }, [])

    if (location.pathname === "/") {
        return <></>
    }

    const logoutAction = () => {
        logout();
        removePermissions();
        navigate("/")
    }

    return (
        <S.HeaderWrapper>
            <S.LineContainer>
                <img src="/img/logo-macdata.png" alt="Macdata" width={200} />
                <span style={{ fontSize: "1rem" }}>
                    PROVIMAC – Provisionamento e Gestão de Risco
                </span>
            </S.LineContainer>
            <S.LineContainer>
                <S.ClientName>{currentClient}</S.ClientName>
                <S.LoggedUser onMouseEnter={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
                    <FiUser />
                    {
                        showOptions && (
                            <S.LoggedUserMenuWrapper>
                                <S.LoggedUserMenu>
                                    <S.MenuLabel>{currentUser}</S.MenuLabel>
                                    <S.StyledDivider />
                                    <S.LogoutButton onClick={logoutAction}>
                                        Sair
                                        <FiLogOut />
                                    </S.LogoutButton>
                                </S.LoggedUserMenu>
                            </S.LoggedUserMenuWrapper>
                        )
                    }
                </S.LoggedUser>
            </S.LineContainer>
        </S.HeaderWrapper>
    )
}

export default Header