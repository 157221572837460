import styled from "styled-components";

export const ContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: .5rem;

`

export const ContainerHeader = styled.div`
    width: 100%;
    border: 1px solid var(--medium-gray);
    font-size: .9rem;
    font-weight: 400;
    color: var(--dark-gray);
    padding: .2rem .5rem;
    background: linear-gradient(0deg, var(--light-gray) 0%, var(--white) 100%);
`

export const ContainerBody = styled.div`
    padding: 1rem;
    border: 1px solid var(--medium-gray);
    border-top: none;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`