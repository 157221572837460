import { privateDelete } from '../../methods/delete';
import { privateGet } from '../../methods/get';
import { privatePost } from '../../methods/post';
import { privatePut } from '../../methods/put';
import {
    IPerfilAdicionar,
    IPerfilAlterar,
    IPerfilExcluir
} from '../../models/Perfil';
import { IObterListaPerfil } from '../../models/Perfil/index';

const basePath = "/perfil"

export const obterListaPerfil = () => {
    return privateGet<IObterListaPerfil>(`${basePath}/obterlistaperfil`)
}

export const adicionarPerfil = (body: IPerfilAdicionar) => {
    return privatePost<string>(`${basePath}/adicionar`, body)
}

export const alterarPerfil = (body: IPerfilAlterar) => {
    return privatePut<string>(`${basePath}/alterar`, body)
}

export const excluirPerfil = (body: IPerfilExcluir) => {
    return privateDelete<string>(`${basePath}/excluir`, body)
}