import * as S from "../style"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useState, useEffect } from 'react';
import PageTitle from "../../../components/PageTitle";
import TabSwitcher from "../../../components/TabSwitcher";
import Template from "../../../components/Template";
import ItemsList, { HeaderList, ListItem } from "../../../components/ItemsList";
import { excluirRiscoFase, obterListaRiscoFase, adicionarRiscoFase, alterarRiscoFase } from '../../../services/api/Configuracoes/RiscoFase/index';
import { IAlterarRiscoFase } from '../../../services/models/Configuracoes/RiscoFase/index';
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import ModalAction from "../../../components/ModalAction";
import { PencilSimple, Trash } from "phosphor-react";
import ActionButton from "../../../components/ActionButton";
import { usePermissionsStore } from "../../../stores/PermissionsStore";

const AddRiskPhaseSchema = yup.object({
    descricao: yup.string().required("O nome da fase é obrigatório.")
})

interface item {
    id: number;
    descricao: string;
    isAtivo: boolean;
}

const RiskPhases = () => {
    const [view, setView] = useState<number>(0)
    const [riskPhasesList, setRiskPhasesList] = useState<any>(null)

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [itemToDelete, setItemToDelete] = useState<item | null>(null)
    const [itemToEdit, setItemToEdit] = useState<item | null>(null)

    useEffect(() => {
        view === 0 && ObterListaRiscoFase()
    }, [view])

    const {
        handleSubmit,
        control,
        reset,
        formState: {
            errors,
            isValid
        } } = useForm({
            mode: "onChange",
            reValidateMode: "onChange",
            resolver: yupResolver(AddRiskPhaseSchema)
        })

    const ObterListaRiscoFase = async () => {
        let res = await obterListaRiscoFase()
        setRiskPhasesList(res)
    }

    const AdicionarRiscoFase = async (data: any) => {
        let body = {
            ...data
        }

        await adicionarRiscoFase(body).then(() => {
            reset()
        })
    }

    const ExcluirRiscoFase = async (idRiscoFase: number) => {
        await excluirRiscoFase({ idRiscoFase })
        ObterListaRiscoFase()
    }

    const EditarRiscoFase = async (newRiscoFase: IAlterarRiscoFase) => {
        await alterarRiscoFase(newRiscoFase)
        ObterListaRiscoFase()
    }

    const handleShowDeleteModal = (item: item) => {
        setItemToDelete(item)
        setShowDeleteModal(true)
    }

    const handleShowEditModal = (item: item) => {
        setItemToEdit(item)
        setShowEditModal(true)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(4);

    // PARA TESTE
    // const permissions = {
    //     permiteInserir: false,
    //     permiteAlterar: false,
    //     permiteExcluir: false,
    // }

    const {
        permiteInserir,
        permiteAlterar,
        permiteExcluir
    } = permissions;

    const menuItems = permiteInserir ?
        [
            "Dados",
            "Adicionar"
        ] : [
            "Dados"
        ]

    const headerList = permiteAlterar || permiteExcluir ?
        [
            "Descrição",
            "Status",
            "Ações"
        ] : [
            "Descrição",
            "Status"
        ]

    return (
        <Template>
            <PageTitle>
                Fases de Risco
            </PageTitle>
            <TabSwitcher
                list={menuItems}
                view={view}
                setView={setView}
            />
            <S.Content>
                <S.ControlView active={view === 0}>
                    {
                        riskPhasesList ? (
                            <ItemsList>
                                <HeaderList items={headerList} />
                                {
                                    riskPhasesList.map((item: item, index: any) => {
                                        return (
                                            <ListItem key={index}>
                                                <div>{item.descricao}</div>
                                                <div>{item.isAtivo ? "Ativo" : "Inativo"}</div>
                                                {
                                                    (
                                                        permiteAlterar ||
                                                        permiteExcluir
                                                    ) && (
                                                        <div>
                                                            {
                                                                permiteAlterar && (
                                                                    <ActionButton act="edit" onClick={() => handleShowEditModal(item)}>
                                                                        <PencilSimple />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                            {
                                                                permiteExcluir && (
                                                                    <ActionButton act="delete" onClick={() => handleShowDeleteModal(item)}>
                                                                        <Trash />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </ListItem>
                                        )
                                    })
                                }
                            </ItemsList>
                        ) : (<p>Carregando...</p>)
                    }
                </S.ControlView>
                <S.ControlView active={view === 1} id="addRisk" onSubmit={handleSubmit((data) => AdicionarRiscoFase(data))}>
                    <S.GridItem cols={2}>
                        <Input id="descricao" name="descricao" label="Nome da fase" control={control} error={errors?.descricao?.message} />
                    </S.GridItem>
                    {
                        isValid && (
                            <S.GridItem cols={6}>
                                <Button type="submit" form="addRisk">Adicionar</Button>
                            </S.GridItem>
                        )
                    }
                </S.ControlView>
            </S.Content>
            {
                showDeleteModal && itemToDelete && (
                    <ModalAction
                        type="delete"
                        onConfirm={() => ExcluirRiscoFase(itemToDelete.id)}
                        onClose={() => setShowDeleteModal(false)}
                        title="Excluir fase"
                        item={itemToDelete}
                    />
                )
            }
            {
                showEditModal && itemToEdit && (
                    <ModalAction
                        type="edit"
                        onConfirm={(e) => EditarRiscoFase(e)}
                        onClose={() => setShowEditModal(false)}
                        title="Editar fase"
                        item={itemToEdit}
                    />
                )
            }
        </Template>
    )

}

export default RiskPhases