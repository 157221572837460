import styled from "styled-components";

export const CaseSearchWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 2.3125rem);
    padding: 5rem;
`

export const CaseSearchContent = styled.form`
    width: 100%;
    padding: 1rem 3rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
`

export const FloatNewButton = styled.div`
    position: absolute;
    right: 3rem;
    top: -4rem;
`

export const ActionButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    margin-top: 1rem;
`

export const FlexLine = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: center;
`

export const StyledHr = styled.hr`
    border-top: 1px solid var(--text-blue);
`

export const TableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    background: var(--white);

    td {
        border: 1px solid var(--dark-gray);
        text-align: center;
        padding: .3rem;
    }

    th {
        background-color: var(--blue-primary);
        color: var(--white);
        font-weight: 300;
        font-size: .8rem;
        padding: 0.5rem;
        border: 1px solid var(--blue-primary);
    }

    tbody {
        tr {
            transition: all .2s ease;
            :nth-child(even) {
                background-color: var(--light-gray);
            }
            &:hover {
                cursor: pointer;
                background-color: #00000f30;
            }
        }
    }
`