import styled from "styled-components";

interface IStyledActionButtonProps {
    act: "edit" | "delete" | "confirm";
}

const getColor = (act: "edit" | "delete" | "confirm") => {
    if (act === "edit") {
        return "var(--yellow)"
    }
    if (act === "delete") {
        return "var(--red)"
    }
    if (act === "confirm") {
        return "green"
    }
}

export const StyledActionButton = styled.button<IStyledActionButtonProps>`
    background-color: ${props => getColor(props.act)};
    color: var(--white);
    border-radius: .4rem;
    padding: .3rem .4rem;
    font-size: 1rem;
    line-height: .8rem;
    transition: filter .2s ease;

    :hover {
        filter: brightness(1.1);
    }

    :active {
        filter: brightness(.9);
    }

    :disabled {
        filter: grayscale(1);
        cursor: not-allowed;
    }
`