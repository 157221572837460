import styled from "styled-components";

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;

    input[type="checkbox"] {
        cursor: pointer;
    }
`

export const CheckboxLabel = styled.label`
    font-size: .8rem;
    color: var(--dark-gray);
    font-weight: 400;
    cursor: pointer;
`