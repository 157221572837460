import axios from "axios";
import { toast } from "react-toastify";
import getIdClienteMacdata from "../../utils/getIdClienteMacdata";

const post = async<T> (route: string, body: any): Promise<T> => {
    return await axios
        .post(`${process.env.REACT_APP_BACKEND_API}${route}`, body)
        .then((r) => r.data)
        .catch((e) => {
            toast.dismiss()
            toast.error(e.response.data.detail, { toastId: "postErrorToast" })
        })
}

export const privatePost = async<T> (route: string, body?: any): Promise<T> => {

    const user = localStorage.getItem("user")
    const token = user && JSON.parse(user).token

    const privateAxios = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        headers: {
            'Authorization': `Bearer ${token}`,
            idClienteMacdata: getIdClienteMacdata()
        }
    })

    return await privateAxios
        .post(`${process.env.REACT_APP_BACKEND_API}${route}`, body)
        .then((r) => {
            toast.dismiss()
            toast.success((typeof r.data === "string") ? r.data : r.data.mensagem, { toastId: "postToast" })
            return r.data
        })
        .catch((e) => {
            toast.dismiss()
            toast.error(e.response.data.detail, { toastId: "postErrorToast" })
        })
}

export const authPost = async<T> (route: string, idClienteMacdata: number, body?: any): Promise<T> => {

    const user = localStorage.getItem("user")
    const token = user && JSON.parse(user).token

    const privateAxios = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        headers: {
            'Authorization': `Bearer ${token}`,
            idClienteMacdata: idClienteMacdata
        }
    })

    return await privateAxios
        .post(`${process.env.REACT_APP_BACKEND_API}${route}`, body)
        .then((r) => {
            // toast.dismiss()
            // toast.success("Login efetuado!", { toastId:  "postToast" })
            return r.data
        })
        .catch((e) => {
            toast.dismiss()
            toast.error(e.response.data.detail, { toastId: "postErrorToast" })
        })
}

export default post