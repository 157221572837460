import { privateGet, privateGetFile } from '../../../../methods/get';
import { privatePost } from '../../../../methods/post';
import { IFiltroObterListaPaginadaRelatoriosSolicitados, IObterListaPaginadaRelatoriosSolicitados } from '../../../../models/Relatorio';
const baseApiPath = "/relatorio"

export const obterListaPaginadaRelatoriosSolicitados = (body: IFiltroObterListaPaginadaRelatoriosSolicitados) => {
    return privateGet<IObterListaPaginadaRelatoriosSolicitados>(`${baseApiPath}/obterlistapaginadarelatoriossolicitados`, body)
}

export const adicionarRelatorioProvisionamento = (body: any) => {
    return privatePost<any>(`${baseApiPath}/gerarrelatorio`, body)
}

export const downloadRelatorio = async (fileName: string) => {

    const response = await privateGetFile<any>(`${baseApiPath}/download`, { fileName })

    return response
}