import styled, { keyframes } from "styled-components";

const ldsRoller = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const LoaderContainer = styled.div`
    /* display: flex;
    width: 100%;
    justify-content: center; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFF40;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: wait;
    z-index: 10;
`

export const LoaderWrapper = styled.div`
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 5rem;

    div {
        animation: ${ldsRoller} 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
        transform-origin: 2.5rem 2.5rem;
    
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: .4375rem;
            height: .4375rem;
            border-radius: 50%;
            background: var(--blue-primary);
            margin: -.25rem 0 0 -.25rem;
        }

        &:nth-child(1) {
            animation-delay: -.036s;
            &:after {
                top: 3.9375rem;
                left: 3.9375rem;
            }
        }
        &:nth-child(2) {
            animation-delay: -.072s;
            &:after {
                top: 4.25rem;
                left: 3.5rem;
            }
        }
        &:nth-child(3) {
            animation-delay: -0.108s;
            &:after {
                top: 71px;
                left: 48px;
            }
        }
        &:nth-child(4) {
            animation-delay: -0.144s;
            &:after {
                top: 72px;
                left: 40px;
            }
        }
        &:nth-child(5) {
            animation-delay: -0.18s;
            &:after {
                top: 71px;
                left: 32px;
            }
        }
        &:nth-child(6) {
            animation-delay: -0.216s;
            &:after {
                top: 68px;
                left: 24px;
            }
        }
        &:nth-child(7) {
            animation-delay: -0.252s;
            &:after {
                top: 63px;
                left: 17px;
            }
        }
        &:nth-child(8) {
            animation-delay: -0.288s;
            &:after {
                top: 56px;
                left: 12px;
            }
        }
    }
`