import styled from "styled-components";

interface IStyledPageTitle {
    maxWidth: string;
}

export const StyledPageTitle = styled.span<IStyledPageTitle>`
    font-size: 1.5rem;
    color: var(--text-blue);
    padding: 0 1rem 1rem 1rem;
    position: relative;
    font-weight: 700;

    &:after {
        content: "";
        position: absolute;
        width: ${props => `${props.maxWidth}%`};
        height: .2rem;
        bottom: 0;
        left: 0;
        background: var(--text-blue);
        background: linear-gradient(90deg, var(--text-blue) 40%, transparent 100%);
    }
`