import { useEffect } from "react";
import { useAuthStore } from "../../stores/AuthStore";
import { usePermissionsStore } from "../../stores/PermissionsStore";
import * as S from "./style";
import { useNavigate } from "react-router-dom";

const Home = () => {

    const user = useAuthStore((state) => state.user)
    const getPermissions = usePermissionsStore((state) => state.getPermissions);

    const navigate = useNavigate()

    useEffect(() =>{
        getPermissions()
        user && console.log("user: ", user)
        if (user?.tipoStatusLogin === "SolicitarNovaSenha") {
            console.log("FOI")
            navigate("/redefinir-senha")
        }
    }, [])

    return (
        <S.HomeWrapper>
            <span>{user?.tipoStatusLogin}</span>
            Navegue pelo sistema através do menu à esquerda.
        </S.HomeWrapper>
    )
}

export default Home