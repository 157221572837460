import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import Button from '../../../components/Button/index'
import Checkbox from "../../../components/Checkbox"
import Input from "../../../components/Input"
import MaskInput from "../../../components/MaskInput"
import PageTitle from "../../../components/PageTitle"
import Select from "../../../components/Select"
import Template from "../../../components/Template"
import WithLabel from "../../../components/WithLabel"
import { adicionarClienteMacdata } from '../../../services/api/ClienteMacdata/index'
import * as S from "./style"

const ClienteMacdataSchema = yup.object({
    id: yup.number().required("ID é obrigatório."),
    nome: yup.string().required("Nome é obrigatório."),
    idBanco: yup.number(),
    servidor: yup.string(),
    ipServidor: yup.string(),
    userDb: yup.string(),
    senhaDb: yup.string(),
    dataEntrada: yup.string().required("Data de entrada é obrigatório."),
    administradorNome: yup.string().required("O nome do administrador é obrigatório."),
    administradorCpf: yup.string().required("O CPF do administrador é obrigatório."),
    administradorCargo: yup.string().required("O cargo do administrador é obrigatório."),
    administradorTelefone: yup.string().required("O telefone do administrador é obrigatório."),
    administradorEmail: yup.string().required("O email do administrador é obrigatório.").email("E-mail inválido."),
    isAprovaCalculos: yup.string().oneOf(["Sim", "Não"])
})

const CadastroClienteMacdata = () => {

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isValid
        }
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(ClienteMacdataSchema)
    })

    const AdicionarClienteMacdata = async (data: any) => {
        const body = {
            id: data.id,
            nome: data.nome,
            bancoDados: {
                idBanco: data.idBanco,
                servidor: data.servidor,
                ipServidor: data.ipServidor,
                userDb: data.userDb,
                senhaDb: data.senhaDb
            },
            dataEntrada: data.dataEntrada,
            administradorNome: data.administradorNome,
            administradorCpf: data.administradorCpf,
            administradorCargo: data.administradorCargo,
            administradorTelefone: data.administradorTelefone,
            administradorEmail: data.administradorEmail,
            isAprovaCalculos: data.isAprovaCalculos === "Sim"
        }
        let res = await adicionarClienteMacdata(body)
    }

    return (
        <Template>
            <PageTitle>
                Cadastro de cliente Macdata
            </PageTitle>
            <S.CadastroClienteMacdataContent onSubmit={handleSubmit((data) => AdicionarClienteMacdata(data))} id="cadastroClienteMacdata">
                <S.SectionName>Cliente</S.SectionName>
                <S.GridItem cols={6}>
                    <Input
                        id="id"
                        name="id"
                        label="ID"
                        control={control}
                        error={errors.name?.message}
                    />
                </S.GridItem>
                <S.GridItem cols={2}>
                    <Input
                        id="nome"
                        name="nome"
                        label="Nome"
                        control={control}
                        error={errors.nome?.message}
                    />
                </S.GridItem>
                <S.SectionName mt>Banco de Dados</S.SectionName>
                <S.GridItem cols={4}>
                    <Input
                        id="idBanco"
                        name="idBanco"
                        label="ID"
                        control={control}
                        error={errors.nome?.message}
                    />
                    <Input
                        id="servidor"
                        name="servidor"
                        label="Servidor"
                        control={control}
                        error={errors.nome?.message}
                    />
                </S.GridItem>
                <S.GridItem cols={4}>
                    <MaskInput
                        id="ipServidor"
                        name="ipServidor"
                        label="IP"
                        control={control}
                        error={errors.ipServidor?.message}
                        mask="999.999.9.9"
                    />
                </S.GridItem>
                <S.GridItem cols={4}>
                    <Input
                        id="userDb"
                        name="userDb"
                        label="Usuário"
                        control={control}
                        error={errors.userDb?.message}
                    />
                    <Input
                        type="password"
                        id="senhaDb"
                        name="senhaDb"
                        label="Senha"
                        control={control}
                        error={errors.senhaDb?.message}
                    />
                </S.GridItem>
                <S.SectionName mt />
                <S.GridItem cols={4}>
                    <Input
                        type="date"
                        id="dataEntrada"
                        name="dataEntrada"
                        label="Data de entrada"
                        control={control}
                        error={errors.dataEntrada?.message}
                    />
                </S.GridItem>
                <S.SectionName mt>Administrador</S.SectionName>
                <S.GridItem cols={4}>
                    <Input
                        id="administradorNome"
                        name="administradorNome"
                        label="Nome"
                        control={control}
                        error={errors.administradorNome?.message}
                    />
                    <Input
                        id="administradorCpf"
                        name="administradorCpf"
                        label="CPF"
                        control={control}
                        error={errors.administradorCpf?.message}
                    />
                </S.GridItem>
                <S.GridItem cols={4}>
                    <Input
                        id="administradorCargo"
                        name="administradorCargo"
                        label="Cargo"
                        control={control}
                        error={errors.administradorCargo?.message}
                    />
                    <Input
                        id="administradorTelefone"
                        name="administradorTelefone"
                        label="Telefone"
                        control={control}
                        error={errors.administradorTelefone?.message}
                    />
                </S.GridItem>
                <S.GridItem cols={2}>
                    <Input
                        id="administradorEmail"
                        name="administradorEmail"
                        label="Email"
                        control={control}
                        error={errors.administradorEmail?.message}
                    />
                </S.GridItem>
                <S.GridItem cols={4}>
                    <Select
                        id="isAprovaCalculos"
                        name="isAprovaCalculos"
                        label="Aprova cálculos"
                        control={control}
                        error={errors.isAprovaCalculos?.message}
                        options={[
                            { value: "Sim", label: "Sim" },
                            { value: "Não", label: "Não" }
                        ]}
                    />
                </S.GridItem>
                {
                    isValid && (
                        <S.GridItem cols={2}>
                            <Button type="submit" form="cadastroClienteMacdata">Cadastrar</Button>
                        </S.GridItem>
                    )
                }
            </S.CadastroClienteMacdataContent>
        </Template>
    )
}

export default CadastroClienteMacdata