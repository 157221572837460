import { useEffect, useState } from "react"
import PageTitle from "../../../components/PageTitle"
import TabSwitcher from "../../../components/TabSwitcher"
import Template from "../../../components/Template"
import * as S from "../style"
import ItemsList, { HeaderList, ListItem } from "../../../components/ItemsList"
import { GridItem } from '../style';
import Input from "../../../components/Input"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Button from "../../../components/Button"
import { IAlterarPoloCliente, IObterListaPoloClienteResponse } from "../../../services/models/Configuracoes/PoloCliente"
import { adicionarPoloCliente, alterarPoloCliente, excluirPoloCliente, obterListaPoloCliente } from "../../../services/api/Configuracoes/PoloCliente"
import ActionButton from "../../../components/ActionButton"
import { PencilSimple, Trash } from "phosphor-react"
import ModalAction from "../../../components/ModalAction"
import { usePermissionsStore } from "../../../stores/PermissionsStore"

const AddPoloClienteSchema = yup.object({
    descricao: yup.string().required("O nome é obrigatório.")
})

type item = IObterListaPoloClienteResponse;

const PoloCliente = () => {
    const [view, setView] = useState<number>(0)
    const [PoloClienteList, setPoloClienteList] = useState<any>(null);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [itemToDelete, setItemToDelete] = useState<item | null>(null)
    const [itemToEdit, setItemToEdit] = useState<item | null>(null)

    useEffect(() => {
        view === 0 && ObterListaPoloCliente();
    }, [view])

    const {
        handleSubmit,
        control,
        reset,
        formState: {
            errors,
            isValid
        }
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(AddPoloClienteSchema)
    })

    const ObterListaPoloCliente = async () => {
        let res = await obterListaPoloCliente()
        setPoloClienteList(res);
    }

    const AdicionarPoloCliente = async (data: any) => {
        let body = {
            ...data
        }

        await adicionarPoloCliente(body).then(() => {
            reset({
                descricao: null
            })
        })
    }

    const ExcluirPoloCliente = async (idCasoPoloCliente: number) => {
        await excluirPoloCliente({ idCasoPoloCliente });
        ObterListaPoloCliente()
    }

    const EditarPoloCliente = async (newPoloCliente: IAlterarPoloCliente) => {
        await alterarPoloCliente(newPoloCliente)
        ObterListaPoloCliente()
    }

    const handleShowDeleteModal = (item: item) => {
        setItemToDelete(item)
        setShowDeleteModal(true)
    }

    const handleShowEditModal = (item: item) => {
        setItemToEdit(item)
        setShowEditModal(true)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(24);

    // PARA TESTE
    // const permissions = {
    //     permiteInserir: false,
    //     permiteAlterar: false,
    //     permiteExcluir: false,
    // }

    const {
        permiteInserir,
        permiteAlterar,
        permiteExcluir
    } = permissions;

    const menuItems = permiteInserir ?
        [
            "Dados",
            "Adicionar"
        ] : [
            "Dados"
        ]

    const headerList = permiteAlterar || permiteExcluir ?
        [
            "Descrição",
            "Status",
            "Ações"
        ] : [
            "Descrição",
            "Status"
        ]

    return (
        <Template>
            <PageTitle>
                Polo do Cliente
            </PageTitle>
            <TabSwitcher
                list={menuItems}
                view={view}
                setView={setView}
            />
            <S.Content>
                <S.ControlView active={view === 0}>
                    {
                        PoloClienteList ? (
                            <ItemsList>
                                <HeaderList items={headerList} />
                                {
                                    PoloClienteList.length > 0 ?
                                    PoloClienteList.map((item: item, index: number) => {
                                        return (
                                            <ListItem key={index}>
                                                <div>{item.descricao}</div>
                                                <div>{item.isAtivo ? "Ativo" : "Inativo"}</div>
                                                {
                                                    (
                                                        permiteAlterar ||
                                                        permiteExcluir
                                                    ) && (
                                                        <div>
                                                            {
                                                                permiteAlterar && (
                                                                    <ActionButton act="edit" onClick={() => handleShowEditModal(item)}>
                                                                        <PencilSimple />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                            {
                                                                permiteExcluir && (
                                                                    <ActionButton act="delete" onClick={() => handleShowDeleteModal(item)}>
                                                                        <Trash />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </ListItem>
                                        )
                                    }) : (
                                        <span>Nenhum item</span>
                                    )
                                }
                            </ItemsList>
                        ) : (
                            <p>Carregando...</p>
                        )
                    }
                </S.ControlView>
                <S.ControlView
                    active={view === 1}
                    id="addPoloCliente"
                    onSubmit={handleSubmit((data) => AdicionarPoloCliente(data))}
                >
                    <S.GridItem cols={2}>
                        <Input
                            id="descricao"
                            name="descricao"
                            label="Descrição"
                            control={control}
                            error={errors.descricao?.message}
                        />
                    </S.GridItem>
                    {
                        isValid && (
                            <S.GridItem cols={6}>
                                <Button type="submit" form="addPoloCliente">Adicionar</Button>
                            </S.GridItem>
                        )
                    }
                </S.ControlView>
            </S.Content>
            {
                showDeleteModal && itemToDelete && (
                    <ModalAction
                        type="delete"
                        onConfirm={() => ExcluirPoloCliente(itemToDelete.id)}
                        onClose={() => setShowDeleteModal(false)}
                        title="Excluir Polo do Cliente"
                        item={itemToDelete}
                    />
                )
            }
            {
                showEditModal && itemToEdit && (
                    <ModalAction
                        type="edit"
                        onConfirm={(e) => EditarPoloCliente(e)}
                        onClose={() => setShowEditModal(false)}
                        title="Editar Polo do Cliente"
                        item={itemToEdit}
                    />
                )
            }
        </Template>
    )
}

export default PoloCliente