import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { recuperarSenha } from '../../services/api/Autenticar';
import { useAuthStore } from "../../stores/AuthStore";
import * as S from "./style";

const LoginSchema = yup.object({
    mail: yup.string().email("E-mail inválido.").required("E-mail obrigatório."),
    password: yup.string().required("Senha obrigatória.")
})

const SelectClientSchema = yup.object({
    client: yup.string().notOneOf(["Selecione", null], "Selecione o cliente.").required("Selecione o cliente.")
})

type LoginValues = {
    mail: string
    password: string
}

type ClientValues = {
    idClienteMacdata: number
    nome: string
}

const Login = () => {

    const [ login, clients, auth, isLoading ] = useAuthStore((state) => [ state.login, state.clients, state.auth, state.isLoading ]);

    const {
        handleSubmit: handleSubmitLogin,
        control,
        watch,
        setError,
        formState: {
            errors,
            isValid
        }
    } = useForm<LoginValues>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(LoginSchema)
    })

    const {
        handleSubmit: handleSubmitClient,
        control: control2,
        formState: {
            errors: errors2,
            isValid: isValid2
        }
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(SelectClientSchema)
    })

    const forgotPassword = () => {
        let t = watch("mail")

        if (!t) {
            setError("mail", { type: 'custom', message: 'Informar e-mail para recuperação.' })
        } else {
            RecuperarSenha()
        }
    }

    const RecuperarSenha = async () => {
        let mail = watch("mail")
        let body = {
            loginEmail: mail,
            urlBase: window.location.origin
        }
        await recuperarSenha(body).then(() => {
            toast.success(`E-mail de recuperação enviado!`)
        })
    }

    const handleLogin = (data: LoginValues) => {
        login({
            mail: data.mail,
            password: data.password
        })
    }

    const handleClientAuth = (data: any) => {
        const client: ClientValues = JSON.parse(data.client);
        auth({
            idClienteMacdata: client.idClienteMacdata,
            name: client.nome,
            loginEmail: watch("mail"),
            senha: watch("password")
        })
    }

    return (
        <S.LoginWrapper>
            <S.LoginContainer>
                <S.LoginTitle>
                    PROVIMAC
                </S.LoginTitle>
                {
                    (!clients || clients?.length < 1) && (
                        <>
                            <S.LoginForm id="loginForm" onSubmit={handleSubmitLogin((data) => handleLogin(data))}>
                                <S.LoginItem>
                                    <Input label="E-mail" id="mail" name="mail" control={control} placeholder="Insira seu e-mail de login" error={errors.mail?.message} />
                                </S.LoginItem>
                                <S.LoginItem>
                                    <Input label="Senha" id="password" name="password" control={control} type="password" placeholder="Insira sua senha" error={errors.password?.message} />
                                </S.LoginItem>
                            </S.LoginForm>
                            <S.LoginItem>
                                <S.ForgotBox>
                                    <S.ForgotLink onClick={forgotPassword}>
                                        Esqueci minha senha
                                    </S.ForgotLink>
                                    <Button type="submit" form="loginForm" disabled={isLoading || !isValid} loading={isLoading}>
                                        Entrar
                                    </Button>
                                </S.ForgotBox>
                            </S.LoginItem>
                        </>
                    )
                }
                {
                    clients &&
                    clients.length > 1 && (
                        <S.LoginForm id="clientForm" onSubmit={handleSubmitClient((data) => handleClientAuth(data))} >
                            <S.LoginItem>
                                <Select
                                    id="client"
                                    name="client"
                                    error={errors2.client?.message}
                                    control={control2}
                                    label="Selecione o Cliente"
                                    options={
                                        clients.map((item) => {
                                            return (
                                                { label: `${item.idClienteMacdata.toString()} - ${item.nome}`, value: JSON.stringify(item) }
                                            )
                                        })
                                    }
                                />
                            </S.LoginItem>
                            <Button type="submit" form="clientForm" disabled={isLoading || !isValid2}>
                                Selecionar
                            </Button>
                        </S.LoginForm>
                    )
                }
            </S.LoginContainer>
        </S.LoginWrapper>
    )
}

export default Login