import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import ItemsList, { HeaderList, ListItem } from "../../../../components/ItemsList";
import Paginator from "../../../../components/Paginator";
import Select from "../../../../components/Select";
import { downloadDocumentoCalculo, obterListaPaginadaDocumentoCalculoCasoJuridico } from "../../../../services/api/CasoJuridico";
import { obterListaRiscoFase } from "../../../../services/api/Configuracoes/RiscoFase";
import { IObterListaPaginadaDocumentoCalculoCasoJuridico } from "../../../../services/models/CasoJuridico";
import { convertDateWithTime } from '../../../../utils/index';
import * as S from "../../style";

interface ICalculos {
    storedId?: string | null;
}

const Calculos = ({ storedId }: ICalculos) => {
    const [listaFaseProvisao, setListaFaseProvisao] = useState<any>(null)
    const [listaCalculos, setListaCalculos] = useState<any>(null)
    const [totalPaginasCalculos, setTotalPaginasCalculos] = useState<number>(0)
    const [paginaCalculos, setPaginaCalculos] = useState<number>(1)
    const [totalItensCalculos, setTotalItensCalculos] = useState<number>(0)

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isValid
        },
        getValues,
        setValue,
        register,
        getFieldState,
        reset,
        watch,
        setError
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    })

    useEffect(() => {
        if (storedId) {

        }

        ObterListaFaseProvisao()
    }, [storedId])

    useEffect(() => {
        let fase = watch("calculosFase")
        fase && ObterListaPaginadaDocumentoCalculoCasoJuridico(+fase, false)
    }, [watch("calculosFase")])

    useEffect(() => {
        let fase = watch("calculosFase")
        fase && ObterListaPaginadaDocumentoCalculoCasoJuridico(+fase, true)
    }, [paginaCalculos])

    const ObterListaPaginadaDocumentoCalculoCasoJuridico = async (idRiscoFase: number, page?: boolean) => {
        if (!storedId) return

        let body: IObterListaPaginadaDocumentoCalculoCasoJuridico = {
            idCaso: +storedId,
            idRiscoFase: idRiscoFase || null,
            limite: 3,
            pagina: page ? paginaCalculos : 1
        }

        let res = await obterListaPaginadaDocumentoCalculoCasoJuridico(body)
        setListaCalculos(res.itens)
        setPaginaCalculos(res.paginaAtual)
        setTotalItensCalculos(res.totalItens)
        setTotalPaginasCalculos(res.totalPaginas)
    }

    const ObterListaFaseProvisao = async () => {
        let res = await obterListaRiscoFase(1)
        setListaFaseProvisao(res)
        setValue("calculosFase", "TODOS")
    }

    const BaixarDocumento = async (fileName: string) => {
        try {
            const res = await downloadDocumentoCalculo(+storedId, fileName);
            const fileUrl = window.URL.createObjectURL(new Blob([res]))
            const a = document.createElement("a");
            a.href = fileUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)

        } catch (error) {
            toast.error(error as string)
        }
    }

    return (
        <>
            <S.GridItem cols={2}>
                {
                    listaFaseProvisao && (
                        <Select
                            id="calculosFase"
                            name="calculosFase"
                            label="Fase"
                            control={control}
                            options={[
                                { value: "TODOS", label: "TODOS" },
                                ...listaFaseProvisao.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            ]}
                        />
                    )
                }
            </S.GridItem>
            {
                listaCalculos && listaCalculos.length > 0 ? (
                    <S.GridItem cols={1}>
                        <ItemsList>
                            <HeaderList items={[
                                "Parte Contrária",
                                "Fase",
                                "Data",
                                "Documento"
                            ]} />
                            {
                                listaCalculos.map((item: any, index: number) => {
                                    return (
                                        <ListItem key={index} onClick={() => BaixarDocumento(item.path)} >
                                            <div>{item.parteContraria}</div>
                                            <div>{item.fase}</div>
                                            <div>{convertDateWithTime(item.dataDocumento)}</div>
                                            <div>{item.path}</div>
                                        </ListItem>
                                    )
                                })
                            }
                            <br />
                            <Paginator
                                totalItens={totalItensCalculos}
                                totalPaginas={totalPaginasCalculos}
                                paginaAtual={paginaCalculos}
                                change={setPaginaCalculos}
                                first={() => setPaginaCalculos(1)}
                                last={() => setPaginaCalculos(totalPaginasCalculos)}
                            />
                        </ItemsList>
                    </S.GridItem>
                ) : (
                    <span>Sem resultados</span>
                )
            }
        </>
    )
}

export default Calculos