import styled, { keyframes } from "styled-components";
import CloseIcon from "../../assets/svg/CloseIcon.svg"

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`


const zoomIn = keyframes`
  from {
    transform: scale(.25);
  }
  
  to {
    transform: scale(1);
  }
`

const zoomOut = keyframes`
    from {
        transform: scale(1);
    }
    to {
        transform: scale(.25);
    }
`

export const ModalActionWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${fadeIn} .2s ease-in-out;

    &.fadeIn {
        animation: ${fadeIn} .2s ease;
    }

    &.fadeOut {
        animation: ${fadeOut} .2s ease;
    }

    .zoomIn {
        animation: ${zoomIn} .2s ease;
    }

    .zoomOut {
        animation: ${zoomOut} .2s ease;
    }
`

export const ModalActionWindow = styled.div`
    border-radius: .5rem;
    border: 1px solid var(--blue-primary);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.40);
    -moz-box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.40);
    box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.40);
`

export const ModalActionHeader = styled.div`
    display: flex;
    flex-direction: row;
    color: var(--white);
    font-size: .8rem;
    align-items: center;
    column-gap: 3rem;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    background-color: var(--blue-primary);
`

export const ModalActionCloseButton = styled.button`
    /* padding: .2rem; */
    width: .8rem;
    height: .8rem;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: transparent;
    background-image: url(${CloseIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all .2s ease;

    :active {
        transform: scale(.9);
    }
`

export const ModalActionBody = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    background-color: var(--white);
    font-size: .8rem;
`

export const ModalActionFooter = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    border-top: 1px solid var(--blue-primary);
    background-color: var(--white);
    padding: 1rem 2rem;
`

export const ModalActionCancelButton = styled.button`
    border: 1px solid var(--blue-primary);
    color: var(--blue-primary);
    background-color: var(--white);
    border-radius: .2rem;
    padding: .5rem 1rem;
    outline: none;
    transition: all .2s ease;

    :hover {
        background-color: var(--blue-primary);
        color: var(--white);
    }

    :active {
        filter: brightness(.8);
    }
`

export const ModalActionConfirmButton = styled.button`
    border: 1px solid var(--blue-primary);
    color: var(--white);
    background-color: var(--blue-primary);
    border-radius: .2rem;
    padding: .5rem 1rem;
    outline: none;
    transition: all .2s ease;
    font-size: .8rem;

    :hover {
        background-color: var(--white);
        color: var(--blue-primary);
        border: 1px solid var(--blue-primary);
    }

    :active {
        filter: brightness(.8);
    }
`

export const ModalForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
`