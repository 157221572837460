import styled from "styled-components";

export const ItemsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const HeaderListWrapper = styled.div`
    display: flex;
    align-items: center;

    div {
        flex: 1;
        text-align: center;
        background-color: var(--blue-primary);
        color: var(--white);
        border: 1px solid var(--white);
        border-right: 1px solid var(--blue-primary);
        border-bottom: none;
        border-top: none;
        padding: .2rem .2rem;
        font-size: .8rem;

        :first-child {
            border-left: 1px solid var(--blue-primary);
        }

        :not(:last-child) {
            border-right: none;
        }
    }

`

interface IListItem {
    height?: number;
}

export const ListItem = styled.div<IListItem>`
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--blue-primary);

    :hover {
        background-color: var(--light-gray);
        cursor: pointer;
    }
    
    > div {
        flex: 1;
        text-align: center;
        border: 1px solid var(--blue-primary);
        font-size: .7rem;
        /* padding: .6rem 0; */
        height: ${props => props.height ? `${props.height}rem` : "2.5rem"};
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 1rem;
        border-bottom: none;
        border-top: none;
        line-height: .8rem;
        padding: 0 .5rem;
        overflow: hidden;

        &.big {
            flex: 1.86;
        }

        &.small {
            flex: .687;
        }

        :not(:last-child) {
            border-right: none;
        }
    }
`