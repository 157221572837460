import * as S from "../style"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { IAlterarCasoJuridicoArea, IObterListaCasoJuridicoAreaResponse } from '../../../services/models/Configuracoes/CasoJuridicoArea/index';
import { useEffect, useState } from "react";
import { adicionarCasoJuridicoArea, alterarCasoJuridicoArea, excluirCasoJuridicoArea, obterListaCasoJuridicoArea } from "../../../services/api/Configuracoes/CasoJuridicoArea";
import { PencilSimple, Trash } from "phosphor-react";
import ActionButton from "../../../components/ActionButton";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import ItemsList, { HeaderList, ListItem } from "../../../components/ItemsList";
import ModalAction from "../../../components/ModalAction";
import PageTitle from "../../../components/PageTitle";
import TabSwitcher from "../../../components/TabSwitcher";
import Template from "../../../components/Template";
import { usePermissionsStore } from "../../../stores/PermissionsStore";

const AddLegalCaseAreaSchema = yup.object({
    descricao: yup.string().required("O nome é obrigatório.")
})

interface item extends IObterListaCasoJuridicoAreaResponse { }

const LegalCaseArea = () => {
    const [view, setView] = useState<number>(0)
    const [LegalCaseAreaList, setLegalCaseAreaList] = useState<any>(null)

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [itemToDelete, setItemToDelete] = useState<item | null>(null)
    const [itemToEdit, setItemToEdit] = useState<item | null>(null)

    useEffect(() => {
        view === 0 && ObterListaCasoJuridicoArea()
    }, [view])

    const {
        handleSubmit,
        control,
        reset,
        formState: {
            errors,
            isValid
        }
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(AddLegalCaseAreaSchema)
    })

    const ObterListaCasoJuridicoArea = async () => {
        let res = await obterListaCasoJuridicoArea()
        setLegalCaseAreaList(res)
    }

    const AdicionarCasoJuridicoArea = async (data: any) => {
        let body = {
            ...data
        }

        await adicionarCasoJuridicoArea(body).then(() => {
            reset({
                descricao: null
            })
        })
    }

    const ExcluirCasoJuridicoArea = async (idCasoJuridicoArea: number) => {
        await excluirCasoJuridicoArea({ idCasoJuridicoArea })
        ObterListaCasoJuridicoArea()
    }

    const EditarCasoJuridicoArea = async (newCasoJuridicoArea: IAlterarCasoJuridicoArea) => {
        await alterarCasoJuridicoArea(newCasoJuridicoArea)
        ObterListaCasoJuridicoArea()
    }

    const handleShowDeleteModal = (item: item) => {
        setItemToDelete(item)
        setShowDeleteModal(true)
    }

    const handleShowEditModal = (item: item) => {
        setItemToEdit(item)
        setShowEditModal(true)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(9);

    // PARA TESTE
    // const permissions = {
    //     permiteInserir: false,
    //     permiteAlterar: false,
    //     permiteExcluir: false,
    // }

    const {
        permiteInserir,
        permiteAlterar,
        permiteExcluir
    } = permissions;

    const menuItems = permiteInserir ?
        [
            "Dados",
            "Adicionar"
        ] : [
            "Dados"
        ]

    const headerList = permiteAlterar || permiteExcluir ?
        [
            "Descrição",
            "Status",
            "Ações"
        ] : [
            "Descrição",
            "Status"
        ]


    return (
        <Template>
            <PageTitle>
                Área (Caso Jurídico)
            </PageTitle>
            <TabSwitcher
                list={menuItems}
                view={view}
                setView={setView}
            />
            <S.Content>
                <S.ControlView active={view === 0}>
                    {
                        LegalCaseAreaList ? (
                            <ItemsList>
                                <HeaderList items={headerList} />
                                {
                                    LegalCaseAreaList.map((item: item, index: any) => {
                                        return (
                                            <ListItem key={index}>
                                                <div>{item.descricao}</div>
                                                <div>{item.isAtivo ? "Ativo" : "Inativo"}</div>
                                                {
                                                    (
                                                        permiteAlterar ||
                                                        permiteExcluir
                                                    ) && (
                                                        <div>
                                                            {
                                                                permiteAlterar && (
                                                                    <ActionButton act="edit" onClick={() => handleShowEditModal(item)}>
                                                                        <PencilSimple />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                            {
                                                                permiteExcluir && (
                                                                    <ActionButton act="delete" onClick={() => handleShowDeleteModal(item)}>
                                                                        <Trash />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </ListItem>
                                        )
                                    })
                                }
                            </ItemsList>
                        ) : (
                            <p>Carregando ...</p>
                        )
                    }
                </S.ControlView>
                <S.ControlView active={view === 1} id="addRisk" onSubmit={handleSubmit((data) => AdicionarCasoJuridicoArea(data))}>
                    <S.GridItem cols={2}>
                        <Input
                            id="descricao"
                            name="descricao"
                            label="Descrição"
                            control={control}
                            error={errors.descricao?.message}
                        />
                    </S.GridItem>
                    {
                        isValid && (
                            <S.GridItem cols={6}>
                                <Button type="submit" form="addRisk">Adicionar</Button>
                            </S.GridItem>
                        )
                    }
                </S.ControlView>
            </S.Content>
            {
                showDeleteModal && itemToDelete && (
                    <ModalAction
                        type="delete"
                        onConfirm={() => ExcluirCasoJuridicoArea(itemToDelete.id)}
                        onClose={() => setShowDeleteModal(false)}
                        title="Excluir Área"
                        item={itemToDelete}
                    />
                )
            }
            {
                showEditModal && itemToEdit && (
                    <ModalAction
                        type="edit"
                        onConfirm={(e) => EditarCasoJuridicoArea(e)}
                        onClose={() => setShowEditModal(false)}
                        title="Editar Área"
                        item={itemToEdit}
                    />
                )
            }
        </Template>
    )
}

export default LegalCaseArea