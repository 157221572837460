import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import Container from "../../../../components/Container";
import ItemsList, { HeaderList, ListItem } from "../../../../components/ItemsList";
import Select from "../../../../components/Select";
import WithLabel from "../../../../components/WithLabel";
import { obterListaParteCasoJuridico, obterListaParteContrariaCasoJuridico, obterListaRiscoCasoJuridico, obterListaRiscoCasoJuridicoPorExito, obterListaRiscoCasoJuridicoPorFase } from "../../../../services/api/CasoJuridico";
import { obterListaTipoConsultaRiscoCasoJuridico } from '../../../../services/api/CasoJuridico/index';
import { convertCurrency } from '../../../../utils/index';
import * as S from "../../style";
import { usePermissionsStore } from '../../../../stores/PermissionsStore';

interface IConsulta {
    storedId?: string | null;
    caseFaseProvisao: number | null;
}

const getTableHeaderFromFilter = (id: number) => {
    let t = {
        1: [
            "Parte Contrária",
            "Êxito",
            "Título",
            "Subtítulo",
            "Valor Principal",
            "Valor Corrigido",
            "Juros",
            "Valor Total"
        ],
        2: [
            "Parte Contrária",
            "Êxito",
            "Título",
            "Valor Principal",
            "Valor Corrigido",
            "Juros",
            "Valor Total"
        ],
        3: [
            "Êxito",
            "Título",
            "Valor Principal",
            "Valor Corrigido",
            "Juros",
            "Valor Total"
        ]
    }

    return t[id as keyof typeof t]
}

const Consulta = ({ storedId, caseFaseProvisao }: IConsulta) => {
    const [listaTipoConsultaRiscoCasoJuridico, setListaTipoConsultaRiscoCasoJuridico] = useState<any>([])
    const [listaPartesContrarias, setListaPartesContrarias] = useState<any>([])
    const [listaRiscoCasoJuridicoPorFase, setListaRiscoCasoJuridicoPorFase] = useState<any>([])
    const [listaRiscoCasoJuridicoPorExito, setListaRiscoCasoJuridicoPorExito] = useState<any>([])
    const [listaRiscoCasoJuridico, setListaRiscoCasoJuridico] = useState<any>([])
    const [tipoConsulta, setTipoConsulta] = useState<number>(1)
    const [inibirSemValor, setInibirSemValor] = useState<boolean>(true)

    useEffect(() => {
        if (storedId) {
            ObterListaParteCasoJuridico(+storedId)
            ObterListaRiscoCasoJuridicoPorFase(+storedId)
            ObterListaParteContrariaCasoJuridico(+storedId)
        }
        ObterListaTipoConsultaRiscoCasoJuridico()

    }, [storedId])

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isValid
        },
        getValues,
        setValue,
        register,
        getFieldState,
        reset,
        watch,
        setError
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    })

    useEffect(() => {
        let fase = watch("faseSelecionada")
        let parteContraria = watch("parteContraria")
        if (!storedId) return
        if (fase && parteContraria) {
            ObterListaRiscoCasoJuridicoPorExito(+storedId, +fase, +parteContraria)
        }
    }, [watch("faseSelecionada"), watch("parteContraria")])

    useEffect(() => {
        let idTipoConsultaRiscoCasoJuridico = watch("tipoConsultaRiscoCasoJuridico")
        let parteContraria = watch("lancamentosParteContraria")
        let fase = watch("faseSelecionada")
        if (!storedId) return
        if (
            idTipoConsultaRiscoCasoJuridico &&
            parteContraria &&
            fase
        ) {
            setTipoConsulta(+idTipoConsultaRiscoCasoJuridico)
            ObterListaRiscoCasoJuridico(+storedId, +fase, +parteContraria, +idTipoConsultaRiscoCasoJuridico)
        }
    }, [watch("tipoConsultaRiscoCasoJuridico"), watch("lancamentosParteContraria"), watch("faseSelecionada")])

    const ObterListaParteCasoJuridico = async (idCaso: number) => {
        let res = await obterListaParteCasoJuridico(idCaso)
        let partesContrarias = res.filter((item: any) => {
            return item.idPessoaRelacao === 2
        })
        setListaPartesContrarias(partesContrarias)
    }

    const ObterListaTipoConsultaRiscoCasoJuridico = async () => {
        let res = await obterListaTipoConsultaRiscoCasoJuridico()
        setListaTipoConsultaRiscoCasoJuridico(res)
        setValue("tipoConsultaRiscoCasoJuridico", res[0].id)
    }

    const ObterListaParteContrariaCasoJuridico = async (idCaso: number) => {
        let res = await obterListaParteContrariaCasoJuridico(idCaso)
        let partePrincipal = res.filter((item: any) => {
            return item.isPrincipal
        })
        setValue("lancamentosParteContraria", partePrincipal[0].idPessoa)
    }

    const ObterListaRiscoCasoJuridicoPorFase = async (idCaso: number) => {
        let res = await obterListaRiscoCasoJuridicoPorFase(idCaso)
        setListaRiscoCasoJuridicoPorFase(res)
        setValue("faseSelecionada", caseFaseProvisao)
    }
    const ObterListaRiscoCasoJuridicoPorExito = async (idCaso: number, idRiscoFase: number, idPessoa?: number) => {
        let res = await obterListaRiscoCasoJuridicoPorExito(idCaso, idRiscoFase, +idPessoa)
        setListaRiscoCasoJuridicoPorExito(res)
    }

    const ObterListaRiscoCasoJuridico = async (idCaso: number, idRiscoFase: number, idPessoa: number, idTipoConsultaRiscoCasoJuridico: number) => {
        let res = await obterListaRiscoCasoJuridico(idCaso, idRiscoFase, idPessoa, idTipoConsultaRiscoCasoJuridico)
        setListaRiscoCasoJuridico(res)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(17);

    const {
        permiteAlterar,
        permiteInserir,
        permiteExcluir
    } = permissions

    // const permiteAlterar = true
    // const permiteExcluir = true
    // const permiteInserir = true

    const fieldsDisabled = !(permiteAlterar || permiteInserir);

    return (
        <>
            <S.GridItem cols={2}>
                {
                    listaPartesContrarias &&
                        listaPartesContrarias.length > 0 ? (
                        <Select
                            id="lancamentosParteContraria"
                            name="lancamentosParteContraria"
                            disabled={fieldsDisabled}
                            label="Parte Contrária" control={control}
                            options={
                                listaPartesContrarias.map((item: any) => ({
                                    value: item.idPessoa,
                                    label: item.nomePessoa
                                }))
                            }
                        />
                    ) : (
                        <WithLabel text="&nbsp;">
                            <span className="error">Nenhuma parte contrária incluída.</span>
                        </WithLabel>
                    )
                }
                <WithLabel text="&nbsp;">
                    <input type="checkbox" name="inibirLancamentosSemValor" id="inibirLancamentosSemValor" onChange={(e) => { setInibirSemValor(e.target.checked) }} defaultChecked={inibirSemValor} />
                    <label style={{ fontSize: ".8rem", marginLeft: "-1.4rem", cursor: "pointer" }} htmlFor="inibirLancamentosSemValor">Inibir lançamentos sem valor</label>
                </WithLabel>
            </S.GridItem>
            <S.GridItem cols={2}>
                {
                    listaTipoConsultaRiscoCasoJuridico && (
                        <Select
                            id="tipoConsultaRiscoCasoJuridico"
                            name="tipoConsultaRiscoCasoJuridico"
                            label="Tipo da consulta"
                            disabled={fieldsDisabled}
                            control={control}
                            options={
                                listaTipoConsultaRiscoCasoJuridico.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    )
                }
            </S.GridItem>
            <S.GridItem cols={1}>
                <Container title="Consolidado por Fase">
                    {
                        listaRiscoCasoJuridicoPorFase &&
                            listaRiscoCasoJuridicoPorFase.length > 0 ? (
                            <ItemsList>
                                <HeaderList items={[
                                    "Fase",
                                    "Data",
                                    "Valor Principal",
                                    "Valor Corrigido",
                                    "Juros",
                                    "Valor Total"
                                ]} />
                                {
                                    listaRiscoCasoJuridicoPorFase
                                        .filter((item: any) => {
                                            return inibirSemValor ? item.valorTotal !== 0 : item
                                        })
                                        .map((item: any, index: number) => {
                                            return (
                                                <ListItem
                                                    key={index}
                                                    onClick={() => setValue("faseSelecionada", item.idRiscoFase)}
                                                    style={{ backgroundColor: watch("faseSelecionada") === item.idRiscoFase && "#00000030" }}
                                                >
                                                    {/* <div>
                                                        <input type="radio" id="faseSelecionada" value={item.idRiscoFase} {...register("faseSelecionada")} checked={watch("faseSelecionada") === item.idRiscoFase} />
                                                    </div> */}
                                                    <div>{item.descricaoRiscoFase}</div>
                                                    <div>{item.data}</div>
                                                    <div>{convertCurrency(item.valorPrincipal)}</div>
                                                    <div>{convertCurrency(item.valorCorrigido)}</div>
                                                    <div>{convertCurrency(item.juros)}</div>
                                                    <div>{convertCurrency(item.valorTotal)}</div>
                                                </ListItem>
                                            )
                                        })
                                }
                            </ItemsList>
                        ) : (
                            <WithLabel text="&nnbsp;">
                                Nenhum dado.
                            </WithLabel>
                        )
                    }
                </Container>
            </S.GridItem>
            <S.GridItem cols={1}>
                <Container title="Consolidado por Êxito (da fase selecionada)">
                    {
                        listaRiscoCasoJuridicoPorExito &&
                            listaRiscoCasoJuridicoPorExito.length > 0 ? (
                            <ItemsList>
                                <HeaderList
                                    items={[
                                        "Êxito",
                                        "Valor Principal",
                                        "Valor Corrigido",
                                        "Juros",
                                        "Valor Total"
                                    ]}
                                />
                                {
                                    listaRiscoCasoJuridicoPorExito
                                        .filter((item: any) => {
                                            return inibirSemValor ? item.valorTotal !== 0 : item
                                        })
                                        .map((item: any, index: number) => {
                                            return (
                                                <ListItem key={index}>
                                                    <div>{convertCurrency(item.exito)}</div>
                                                    <div>{convertCurrency(item.valorPrincipal)}</div>
                                                    <div>{convertCurrency(item.valorCorrigido)}</div>
                                                    <div>{convertCurrency(item.juros)}</div>
                                                    <div>{convertCurrency(item.valorTotal)}</div>
                                                </ListItem>
                                            )
                                        })
                                }
                            </ItemsList>
                        ) : (
                            <WithLabel text="&nbsp;">
                                Nenhum dado.
                            </WithLabel>
                        )
                    }
                </Container>
            </S.GridItem>
            <S.GridItem cols={1}>
                <Container title="Todos os Lançamentos (da fase selecionada)">
                    {
                        listaRiscoCasoJuridico &&
                            listaRiscoCasoJuridico.length > 0 ? (
                            <ItemsList>
                                <HeaderList
                                    items={getTableHeaderFromFilter(tipoConsulta)}
                                    big={[tipoConsulta === 3 ? 1 : 2]}
                                />
                                {
                                    listaRiscoCasoJuridico
                                        .filter((item: any) => {
                                            return inibirSemValor ? item.valorTotal !== 0 : item
                                        })
                                        .map((item: any, index: number) => {
                                            return (
                                                <ListItem key={index} height="3.5">
                                                    {
                                                        (
                                                            tipoConsulta === 1 ||
                                                            tipoConsulta === 2
                                                        ) ? (
                                                            <div>{item.parteContraria}</div>
                                                        ) : <></>
                                                    }
                                                    <div>{item.exito}</div>
                                                    <div className="big">{item.descricaoRiscoTitulo}</div>
                                                    {
                                                        tipoConsulta === 1 ? (
                                                            <div>{item.descricaoRiscoSubtitulo}</div>
                                                        ) : <></>
                                                    }
                                                    <div>{convertCurrency(item.valorPrincipal)}</div>
                                                    <div>{convertCurrency(item.valorCorrigido)}</div>
                                                    <div>{convertCurrency(item.juros)}</div>
                                                    <div>{convertCurrency(item.valorTotal)}</div>
                                                </ListItem>
                                            )
                                        })
                                }
                            </ItemsList>
                        ) : (
                            <WithLabel text="&nbsp;">
                                Nenhum dado.
                            </WithLabel>
                        )
                    }
                </Container>
            </S.GridItem>
        </>
    )
}

export default Consulta