import { yupResolver } from "@hookform/resolvers/yup"
import { PencilSimple, Trash } from "phosphor-react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import ActionButton from "../../../components/ActionButton"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import ItemsList, { HeaderList, ListItem } from "../../../components/ItemsList"
import ModalAction from "../../../components/ModalAction"
import PageTitle from "../../../components/PageTitle"
import Select from "../../../components/Select"
import TabSwitcher from "../../../components/TabSwitcher"
import Template from "../../../components/Template"
import { adicionarPerfil, alterarPerfil, excluirPerfil, obterListaPerfil } from "../../../services/api/Perfil"
import { IObterListaPerfil, IPerfilAlterar } from '../../../services/models/Perfil/index'
import * as S from "../style"
import { usePermissionsStore } from "../../../stores/PermissionsStore"

const AddProfileSchema = yup.object({
    nomePerfil: yup.string().required("O nome é obrigatório."),
    isAdmin: yup.string().required("Obrigatório").oneOf(["Sim", "Não"]),
    isConfidencial: yup.string().required("Obrigatório").oneOf(["Sim", "Não"]),
})

interface item extends IObterListaPerfil { }

const Profiles = () => {
    const [view, setView] = useState<number>(0)
    const [ProfilesList, setProfilesList] = useState<any>(null)

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [itemToDelete, setItemToDelete] = useState<item | null>(null)
    const [itemToEdit, setItemToEdit] = useState<item | null>(null)

    useEffect(() => {
        view === 0 && ObterListaPerfil()
    }, [view])

    const {
        handleSubmit,
        control,
        reset,
        formState: {
            errors,
            isValid
        }
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(AddProfileSchema)
    })

    const ObterListaPerfil = async () => {
        let res = await obterListaPerfil()
        setProfilesList(res)
    }

    const AdicionarPerfil = async (data: any) => {
        let body = {
            ...data,
            isConfidencial: data.isConfidencial === "Sim",
            isAdmin: data.isAdmin === "Sim"
        }

        await adicionarPerfil(body).then(() => {
            reset({
                isConfidencial: "Não",
                isAdmin: "Não",
                nomePerfil: null
            })
        })
    }

    const ExcluirPerfil = async (idPerfil: number) => {
        await excluirPerfil({ idPerfil })
        ObterListaPerfil()
    }

    const EditarPerfil = async (newPerfil: IPerfilAlterar) => {
        await alterarPerfil(newPerfil)
        ObterListaPerfil()
    }

    const handleShowDeleteModal = (item: item) => {
        setItemToDelete(item)
        setShowDeleteModal(true)
    }

    const handleShowEditModal = (item: item) => {
        setItemToEdit(item)
        setShowEditModal(true)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(11);

    // PARA TESTE
    // const permissions = {
    //     permiteInserir: false,
    //     permiteAlterar: false,
    //     permiteExcluir: false,
    // }

    const {
        permiteInserir,
        permiteAlterar,
        permiteExcluir
    } = permissions;

    const menuItems = permiteInserir ?
        [
            "Dados",
            "Adicionar"
        ] : [
            "Dados"
        ]

    const headerList = permiteAlterar || permiteExcluir ?
    ["Descrição", "Confidencial (S/N)", "Admin (S/N)", "Ações"] : ["Descrição", "Confidencial (S/N)", "Admin (S/N)"]

    return (
        <Template>
            <PageTitle>
                Perfis
            </PageTitle>
            <TabSwitcher
                list={menuItems}
                view={view}
                setView={setView}
            />
            <S.Content>
                <S.ControlView active={view === 0}>
                    {
                        ProfilesList ? (
                            <ItemsList>
                                <HeaderList items={headerList} />
                                {
                                    ProfilesList.map((item: item, index: any) => {
                                        return (
                                            <ListItem key={index}>
                                                <div>{item.nomePerfil}</div>
                                                <div>{item.isConfidencial ? "S" : "N"}</div>
                                                <div>{item.isAdmin ? "S" : "N"}</div>
                                                {
                                                    (
                                                        permiteAlterar ||
                                                        permiteExcluir
                                                    ) && (
                                                        <div>
                                                            {
                                                                permiteAlterar && (
                                                                    <ActionButton act="edit" onClick={() => handleShowEditModal(item)}>
                                                                        <PencilSimple />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                            {
                                                                permiteExcluir && (
                                                                    <ActionButton act="delete" onClick={() => handleShowDeleteModal(item)}>
                                                                        <Trash />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </ListItem>
                                        )
                                    })
                                }
                            </ItemsList>
                        ) : (
                            <p>Carregando ...</p>
                        )
                    }
                </S.ControlView>
                <S.ControlView active={view === 1} id="addRisk" onSubmit={handleSubmit((data) => AdicionarPerfil(data))}>
                    <S.GridItem cols={2}>
                        <Input
                            id="nomePerfil"
                            name="nomePerfil"
                            label="Descrição"
                            control={control}
                            error={errors.nomePerfil?.message}
                        />
                    </S.GridItem>
                    <S.GridItem cols={2}>
                        <Select
                            id="isConfidencial"
                            name="isConfidencial"
                            control={control}
                            label="Confidencial"
                            options={[
                                { label: "Sim", value: "Sim" },
                                { label: "Não", value: "Não" }
                            ]}
                        />
                    </S.GridItem>
                    <S.GridItem cols={2}>
                        <Select
                            id="isAdmin"
                            name="isAdmin"
                            control={control}
                            label="Administrador"
                            options={[
                                { label: "Sim", value: "Sim" },
                                { label: "Não", value: "Não" }
                            ]}
                        />
                    </S.GridItem>
                    {
                        isValid && (
                            <S.GridItem cols={6}>
                                <Button type="submit" form="addRisk">Adicionar</Button>
                            </S.GridItem>
                        )
                    }
                </S.ControlView>
            </S.Content>
            {
                showDeleteModal && itemToDelete && (
                    <ModalAction
                        type="delete"
                        onConfirm={() => ExcluirPerfil(itemToDelete.id)}
                        onClose={() => setShowDeleteModal(false)}
                        title="Excluir perfil"
                        item={itemToDelete}
                    />
                )
            }
            {
                showEditModal && itemToEdit && (
                    <ModalAction
                        type="edit"
                        onConfirm={(e) => EditarPerfil(e)}
                        onClose={() => setShowEditModal(false)}
                        title="Editar perfil"
                        item={itemToEdit}
                    />
                )
            }
        </Template>
    )
}

export default Profiles