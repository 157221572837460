import { yupResolver } from "@hookform/resolvers/yup"
import { PencilSimple, Trash } from "phosphor-react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import ActionButton from "../../../components/ActionButton"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import ItemsList, { HeaderList, ListItem } from "../../../components/ItemsList"
import ModalAction from "../../../components/ModalAction"
import PageTitle from "../../../components/PageTitle"
import TabSwitcher from "../../../components/TabSwitcher"
import Template from "../../../components/Template"
import { adicionarQualificacaoPessoa, alterarQualificacaoPessoa, excluirQualificacaoPessoa, obterListaPessoaQualificacao } from '../../../services/api/Pessoa/Qualificacao/index'
import { IQualificacaoAlterar, IQualificacaoObterListaPessoaQualificacaoResponse } from '../../../services/models/Pessoa/Qualificacao/index'
import * as S from "../../Settings/style"
import { usePermissionsStore } from "../../../stores/PermissionsStore"

const AddPeopleQualificationSchema = yup.object({
    descricao: yup.string().max(100, "Limite de 100 caracteres.").matches(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/, { excludeEmptyString: true, message: "Nome deve conter apenas letras." }).required("O nome é obrigatório.")
})

interface item extends IQualificacaoObterListaPessoaQualificacaoResponse { }

const Qualification = () => {
    const [view, setView] = useState<number>(0)
    const [PeopleQualificationList, setPeopleQualificationList] = useState<any>(null)

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [itemToDelete, setItemToDelete] = useState<item | null>(null)
    const [itemToEdit, setItemToEdit] = useState<item | null>(null)

    useEffect(() => {
        view === 0 && ObterListaQualificacaoPessoa()
    }, [view])

    const {
        handleSubmit,
        control,
        reset,
        formState: {
            errors,
            isValid
        }
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(AddPeopleQualificationSchema)
    })

    const ObterListaQualificacaoPessoa = async () => {
        let res = await obterListaPessoaQualificacao()
        setPeopleQualificationList(res)
    }

    const AdicionarQualificacaoPessoa = async (data: any) => {
        let body = {
            ...data
        }

        await adicionarQualificacaoPessoa(body).then(() => {
            reset({
                descricao: null
            })
        })
    }

    const ExcluirQualificacaoPessoa = async (idPessoaQualificacao: number) => {
        await excluirQualificacaoPessoa({ idPessoaQualificacao })
        ObterListaQualificacaoPessoa()
    }

    const EditarQualificacaoPessoa = async (o: IQualificacaoAlterar) => {
        await alterarQualificacaoPessoa(o)
        ObterListaQualificacaoPessoa()
    }

    const handleShowDeleteModal = (item: item) => {
        setItemToDelete(item)
        setShowDeleteModal(true)
    }

    const handleShowEditModal = (item: item) => {
        setItemToEdit(item)
        setShowEditModal(true)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(2);
    const {
        permiteInserir,
        permiteAlterar,
        permiteExcluir
    } = permissions;

    const menuItems = permiteInserir ?
        [
            "Dados",
            "Adicionar"
        ] : [
            "Dados"
        ]

    const headerList = permiteAlterar || permiteExcluir ?
        [
            "Descrição",
            "Status",
            "Ações"
        ] : [
            "Descrição",
            "Status"
        ]


    return (
        <Template>
            <PageTitle>Qualificação de Pessoas</PageTitle>
            <TabSwitcher
                list={menuItems}
                view={view}
                setView={setView}
            />
            <S.Content>
                <S.ControlView active={view === 0}>
                    {
                        PeopleQualificationList ? (
                            <ItemsList>
                                <HeaderList items={headerList} />
                                {
                                    PeopleQualificationList.map((item: item, index: any) => {
                                        return (
                                            <ListItem key={index}>
                                                <div>{item.descricao}</div>
                                                <div>{item.isAtivo ? "Ativo" : "Inativo"}</div>
                                                {
                                                    (
                                                        permiteAlterar ||
                                                        permiteExcluir
                                                    ) && (
                                                        <div>
                                                            {
                                                                permiteAlterar && (
                                                                    <ActionButton act="edit" onClick={() => handleShowEditModal(item)}>
                                                                        <PencilSimple />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                            {
                                                                permiteExcluir && (
                                                                    <ActionButton act="delete" onClick={() => handleShowDeleteModal(item)}>
                                                                        <Trash />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </ListItem>
                                        )
                                    })
                                }
                            </ItemsList>
                        ) : (
                            <p>Carregando ...</p>
                        )
                    }
                </S.ControlView>
                <S.ControlView active={view === 1} id="addRisk" onSubmit={handleSubmit((data) => AdicionarQualificacaoPessoa(data))}>
                    <S.GridItem cols={2}>
                        <Input
                            id="descricao"
                            name="descricao"
                            label="Descrição"
                            control={control}
                            error={errors.descricao?.message}
                        />
                    </S.GridItem>
                    {
                        isValid && (
                            <S.GridItem cols={6}>
                                <Button type="submit" form="addRisk">Adicionar</Button>
                            </S.GridItem>
                        )
                    }
                </S.ControlView>
            </S.Content>
            {
                showDeleteModal && itemToDelete && (
                    <ModalAction
                        type="delete"
                        onConfirm={() => ExcluirQualificacaoPessoa(itemToDelete.id)}
                        onClose={() => setShowDeleteModal(false)}
                        title="Excluir qualificação"
                        item={itemToDelete}
                    />
                )
            }
            {
                showEditModal && itemToEdit && (
                    <ModalAction
                        type="edit"
                        onConfirm={(e) => EditarQualificacaoPessoa(e)}
                        onClose={() => setShowEditModal(false)}
                        title="Editar qualificação"
                        item={itemToEdit}
                    />
                )
            }
        </Template>
    )
}

export default Qualification