import * as S from "../../style"
import { useState, useEffect } from 'react';
import ItemsList, { HeaderList, ListItem } from "../../../../../components/ItemsList";
import Paginator from "../../../../../components/Paginator";
import { convertDate } from "../../../../../utils";
import { downloadRelatorio, obterListaPaginadaRelatoriosSolicitados } from "../../../../../services/api/Relatorios/Provisionamento/Solicitacao";
import { toast } from "react-toastify";

const RelatoriosGerados = () => {
    const [totalItens, setTotalItens] = useState<number>(0)
    const [paginaAtual, setPaginaAtual] = useState<number>(1)
    const [totalPaginas, setTotalPaginas] = useState<number>(0)
    const [listaRelatoriosGerados, setRelatoriosGerados] = useState<any | null>(null)
    const [seconds, setSeconds] = useState<number>(10)

    const triggerSearch = async (pagina: number) => {
        let res = await obterListaPaginadaRelatoriosSolicitados({ Limite: 10, Pagina: pagina })

        setRelatoriosGerados(res.itens)
        setTotalItens(res.totalItens)
        setTotalPaginas(res.totalPaginas)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setPaginaAtual((current: number) => {
                triggerSearch(current)
                return current
            })
        }, 10000)

        const secondsInterval = setInterval(() => {
            setSeconds((s: number) => {
                if (s <= 1) {
                    return 10
                } else {
                    return s - 1
                }
            })
        }, 1000)

        return () => {
            clearInterval(interval)
            clearInterval(secondsInterval)
        };
    }, [])

    useEffect(() => {
        triggerSearch(paginaAtual)
    }, [paginaAtual])

    const downloadFile = async (fileName: string) => {
        try {
            const res = await downloadRelatorio(fileName)
            const fileUrl = window.URL.createObjectURL(new Blob([res]))
            const a = document.createElement("a")
            a.href = fileUrl
            a.download = fileName
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } catch (error) {
            toast.error(error as string)
        }
    }

    return (
        <S.ContentWrapper>
            {/* <S.GridItem cols={1}> */}
                <span style={{
                    textAlign: "center",
                    fontSize: ".7rem"
                }}>Atualização automática em {seconds} segundos.</span>
            {/* </S.GridItem> */}
            {
                listaRelatoriosGerados &&
                    listaRelatoriosGerados.length > 0 ? (
                    <>
                        <ItemsList>
                            <HeaderList items={[
                                "Status",
                                "Formato",
                                "Data da Solicitação",
                                "Data de Emissão",
                                "Nome do Arquivo"
                            ]} big={[4]} small={[0, 1]} />
                            {
                                listaRelatoriosGerados.map((item: any, index: any) => {
                                    return (
                                        <ListItem
                                            style={{
                                                cursor: item.status === "Emitido" ? "pointer" : "not-allowed",
                                                fontWeight: item.status === "Emitido" ? "bold" : "normal"
                                            }}
                                            key={index}
                                            onClick={
                                                () => item.status === "Emitido" && downloadFile(item.fileName)
                                            }
                                        >
                                            <div className="small">{item.status}</div>
                                            <div className="small">{item.formato.toUpperCase()}</div>
                                            <div>{convertDate(item.dataSolicitacao)}</div>
                                            <div>{convertDate(item.dataEmissao) === '01/01/0001' ? '' : convertDate(item.dataEmissao)}</div>
                                            <div className="big">{item.fileName}</div>
                                        </ListItem>
                                    )
                                })
                            }
                        </ItemsList>
                        <Paginator
                            totalItens={totalItens}
                            totalPaginas={totalPaginas}
                            paginaAtual={paginaAtual}
                            change={setPaginaAtual}
                            first={() => setPaginaAtual(1)}
                            last={() => setPaginaAtual(totalPaginas)}
                        />
                    </>
                ) : (
                    <span>Nenhum resultado.</span>
                )
            }
        </S.ContentWrapper>
    )
}

export default RelatoriosGerados