import * as S from "../../style"
import { useForm } from "react-hook-form"
import Select from "../../../../../components/Select";
import Input from "../../../../../components/Input";
import Button from "../../../../../components/Button"
import RadioButton from "../../../../../components/RadioButton";
import WithLabel from "../../../../../components/WithLabel";
import { useState, useEffect } from 'react';
import { obterListaUnidade } from "../../../../../services/api/Configuracoes/Unidade";
import { obterListaCasoJuridicoArea } from "../../../../../services/api/Configuracoes/CasoJuridicoArea";
import { obterListaTipoRisco } from "../../../../../services/api/CasoJuridico";
import CurrencyInput from "../../../../../components/CurrencyInput";
import Checkbox from "../../../../../components/Checkbox";
import { IListaParametrosOpcionais, IObterListaOpcoesLayout } from "../../../../../services/models/Relatorio";
import { obterListaOpcoesLayoutRelatoriosProvisionamento } from "../../../../../services/api/Relatorios/Provisionamento/Layout";
import getIdClienteMacdata from "../../../../../utils/getIdClienteMacdata";
import { convertToNumber } from "../../../../../utils";
import { adicionarRelatorioProvisionamento } from "../../../../../services/api/Relatorios/Provisionamento/Solicitacao";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import Template from "../../../../../components/Template";
import PageTitle from "../../../../../components/PageTitle";
import { obterListaRiscoGrupoTitulo } from "../../../../../services/api/Configuracoes/RiscoGrupoTitulo";

const GerarRelatorios = (storedId: any) => {

    const [listaUnidade, setListaUnidade] = useState<any>(null);
    const [listaAreaJuridica, setListaAreaJuridica] = useState<any>(null);
    const [listaTipoRisco, setListaTipoRisco] = useState<any>(null);
    const [listaRelatorioResponse, setListaRelatoriosDisponiveis] = useState<IObterListaOpcoesLayout[] | null>(null);
    const [listaSaida, setListaSaida] = useState<{ id: number, descricao: string }[] | null>(null)

    const [selectedLayout, setSelectedLayout] = useState<any>(null)

    const [listaRiscoGrupoTitulo, setListaRiscoGrupoTitulo] = useState<any | null>(null)

    const GerarRelatorioSchema = yup.object({
        idRelatorio: yup.string().not(["Selecione"], "Campo obrigatório.").required("Campo obrigatório."),
        formatoDeSaida: yup.string().oneOf(["Pdf", "Xls", "Doc"], "Formato obrigatório.").required("Campo obrigatório."),
        Status: yup.string().oneOf(["Ativos", "Baixados", "Todos"], "Status é obrigatório.").required("Campo obrigatório."),
        hasDataBase: yup.boolean(),
        dataBase: yup.string().when("hasDataBase", {
            is: true,
            then: yup.string().notOneOf([""]).required("Obrigatório"),
            otherwise: yup.string()
        }),
        hasDataAtualizacao: yup.boolean(),
        dataAtualizacao: yup.string().when("hasDataAtualizacao", {
            is: true,
            then: yup.string().notOneOf([""]).required("Obrigatório"),
            otherwise: yup.string()
        }),
        hasExibeObjetos: yup.boolean(),
        exibeObjetos: yup.string().when("hasExibeObjetos", {
            is: true,
            then: yup.boolean().required("Obrigatório"),
            otherwise: yup.string()
        })
    })

    const {
        handleSubmit,
        control,
        formState: {
            isValid,
            errors
        },
        watch,
        setValue
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(GerarRelatorioSchema)
    })

    useEffect(() => {
        ObterListaUnidade()
        ObterListaAreaJuridica()
        ObterListaTipoRisco()
        ObterListaRelatoriosDisponiveis()
        ObterListaRiscoGrupoTitulo()
    }, [storedId])

    const ObterListaRiscoGrupoTitulo = async () => {
        let res = await obterListaRiscoGrupoTitulo(1)
        setListaRiscoGrupoTitulo(res)
    }

    const ObterListaUnidade = async () => {
        let res = await obterListaUnidade(1)
        setListaUnidade(res)
    }

    const ObterListaAreaJuridica = async () => {
        let res = await obterListaCasoJuridicoArea(1)
        setListaAreaJuridica(res)
    }

    const ObterListaTipoRisco = async () => {
        let res = await obterListaTipoRisco()
        setListaTipoRisco(res)
    }

    const ObterListaRelatoriosDisponiveis = async () => {
        let res = await obterListaOpcoesLayoutRelatoriosProvisionamento()
        setListaRelatoriosDisponiveis(res)
    }

    function convertToCamelCase(inputString: string) {
        return inputString.replace(/_./g, (match) => match.charAt(1).toUpperCase());
    }

    const SolicitarRelatorioProvisionamento = async (data: any) => {
        const payload = {
            idRelatorio: data.idRelatorio === "Selecione" ? null : data.idRelatorio,
            statusWhere: data.Status === "Selecione" ? null : data.Status,
            formatoDeSaida: data.formatoDeSaida === "Selecione" ? null : data.formatoDeSaida,
            idUnidade: data.idUnidade === "Selecione" ? null : +data.idUnidade,
            idAreaJuridica: data.idCasoJuridicoArea === "Selecione" ? null : +data.idCasoJuridicoArea,
            idTipoRisco: data.idTipoRisco === "Selecione" ? null : +data.idTipoRisco,
            dataEntradaInicio: data.DataEntradaInicio,
            dataEntradaFim: data.DataEntradaFim,
            dataBaixaInicio: data.DataBaixaInicio,
            dataBaixaFim: data.DataBaixaFim,
            dataDistribuicaoInicio: data.DataDistribuicaoInicio,
            dataDistribuicaoFim: data.DataDistribuicaoFim,
            idRiscoGrupoTitulo: data.idRiscoGrupoTitulo === "Selecione" ? null : data.idRiscoGrupoTitulo,
            valorMinimo: data.valorMinimo === '' || data.valorMinimo === undefined || data.valorMinimo === null ? null : convertToNumber(data.valorMinimo),
            valorMaximo: data.valorMaximo === '' || data.valorMaximo === undefined || data.valorMaximo === null ? null : convertToNumber(data.valorMaximo),
            listaFiltroParametrosOpcionais: selectedLayout.map((item: IListaParametrosOpcionais) => ({
                idImpreParametro: item.id,
                valor: watch(convertToCamelCase(item.nomeParametro))
            }))
        }

        await adicionarRelatorioProvisionamento(payload);
    }

    useEffect(() => {
        let idRelatorio = watch("idRelatorio")
        if (idRelatorio === "Selecione") return;
        if (!listaRelatorioResponse) return;

        let relatorioSelecionado = listaRelatorioResponse?.filter((item) => (
            item.id === +idRelatorio
        ))[0]

        const listOfFormats = relatorioSelecionado.listaTipoFormatoSaidaRelatorioProvisionamento
        const layoutSelecionado = relatorioSelecionado.listaParametrosOpcionais

        const hasDataBase = layoutSelecionado.some((itm: any) => itm.nomeParametro.includes("data_base"));
        const hasDataAtualizacao = layoutSelecionado.some((itm: any) => itm.nomeParametro.includes("data_de_atualizacao"));
        const hasExibeObjetos = layoutSelecionado.some((itm: any) => itm.nomeParametro.includes("exibe_objetos"));


        setValue("hasDataBase", !!hasDataBase)
        setValue("hasDataAtualizacao", !!hasDataAtualizacao)
        setValue("hasExibeObjetos", !!hasExibeObjetos)

        setListaSaida(listOfFormats)
        setSelectedLayout(layoutSelecionado)
    }, [watch("idRelatorio")])


    return (
        <S.Form id="formGerar" onSubmit={handleSubmit(SolicitarRelatorioProvisionamento)}>
            <S.GridItem cols={4}>
                {
                    listaRelatorioResponse && (
                        <div style={{ gridColumn: "span 2" }}>
                            <Select
                                label="Opções de Relatórios*"
                                id="idRelatorio"
                                name="idRelatorio"
                                control={control}
                                isDefaultEnabled
                                error={errors.idRelatorio?.message}
                                options={
                                    listaRelatorioResponse.map((item: any) => ({
                                        value: item.id,
                                        label: item.nomeLayout
                                    }))
                                }
                            />
                        </div>
                    )
                }
                {
                    listaSaida &&
                    watch("idRelatorio") !== "Selecione" && (
                        <Select
                            label="Formato de Saída*"
                            id="formatoDeSaida"
                            name="formatoDeSaida"
                            isDefaultEnabled
                            control={control}
                            defaultValue="Pdf"
                            error={errors.formatoDeSaida?.message}
                            options={
                                listaSaida.map((item) => ({
                                    value: item.descricao, label: item.descricao
                                }))
                            }
                        />
                    )
                }
            </S.GridItem>
            <S.GridItem cols={4}>
                <Input label="Data de Entrada (Início)" type="date" id="DataEntradaInicio" name="DataEntradaInicio" error={errors.DataEntradaInicio?.message} control={control} />
                <Input label="Data de Entrada (Fim)" type="date" id="DataEntradaFim" name="DataEntradaFim" error={errors.DataEntradaFim?.message} control={control} />
                {
                    listaUnidade && (
                        <Select
                            label="Unidade"
                            id="idUnidade"
                            name="idUnidade"
                            control={control}
                            isDefaultEnabled
                            error={errors.idUnidade?.message}
                            options={
                                listaUnidade.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    )
                }
            </S.GridItem>
            <S.GridItem cols={4}><Select
                label="Status*"
                id="Status"
                name="Status"
                defaultValue="Ativos"
                isDefaultEnabled
                control={control}
                error={errors.Status?.message}
                options={[
                    { value: "Ativos", label: "Ativos" },
                    { value: "Baixados", label: "Baixados" },
                    { value: "Todos", label: "Todos" },
                ]}
            />
                {
                    listaAreaJuridica && (
                        <Select
                            label="Área Jurídica"
                            id="idCasoJuridicoArea"
                            name="idCasoJuridicoArea"
                            isDefaultEnabled
                            control={control}
                            error={errors.idCasoJuridicoArea?.message}
                            options={
                                listaAreaJuridica.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    )
                }
            </S.GridItem>
            {
                watch("Status") === "Baixados" && (
                    <S.GridItem cols={4}>
                        <Input label="Data de Baixa (Início)" type="date" id="DataBaixaInicio" name="DataBaixaInicio" error={errors.DataBaixaInicio?.message} control={control} />
                        <Input label="Data de Baixa (Fim)" type="date" id="DataBaixaFim" name="DataBaixaFim" error={errors.DataEntradaFim?.message} control={control} />
                    </S.GridItem>
                )
            }
            <S.GridItem cols={4}>
                <CurrencyInput label="Valor Perda Provável (Mínimo)" id="valorMinimo" name="valorMinimo" control={control} error={errors.valorMinimo?.message} />
                <CurrencyInput label="Valor Perda Provável (Máximo)" id="valorMaximo" name="valorMaximo" control={control} error={errors.valorMaximo?.message} />
            </S.GridItem>
            <S.GridItem cols={4}>
                <Input label="Data de Distribuição (Início)" type="date" id="DataDistribuicaoInicio" name="DataDistribuicaoInicio" error={errors.DataDistribuicaoInicio?.message} control={control} />
                <Input label="Data de Distribuição (Fim)" type="date" id="DataDistribuicaoFim" name="DataDistribuicaoFim" error={errors.DataDistribuicaoFim?.message} control={control} />
                {
                    listaTipoRisco && (
                        <Select
                            id="idTipoRisco"
                            name="idTipoRisco"
                            label="Tipo do Risco"
                            isDefaultEnabled
                            control={control}
                            error={errors.idTipoRisco?.message}
                            options={
                                listaTipoRisco.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    )
                }
            </S.GridItem>
            {
                selectedLayout &&
                selectedLayout.length > 0 && (
                    <S.GridItem cols={4}>
                        {
                            !!watch("hasDataBase") && (
                                <Input
                                    label="Data Base*"
                                    type="date"
                                    id="dataBase"
                                    name="dataBase"
                                    control={control}
                                    error={errors.dataBase?.message}
                                />
                            )

                        }
                        {
                            !!watch("hasDataAtualizacao") && (
                                <Input
                                    label="Data da Atualização"
                                    type="date"
                                    id="dataAtualizacao"
                                    name="dataAtualizacao"
                                    control={control}
                                    error={errors.dataAtualizacao?.message}
                                />
                            )
                        }
                        {
                            !!watch("hasExibeObjetos") && (
                                <Select
                                    id="exibeObjeto"
                                    name="exibeObjeto"
                                    label="Exibe Objetos"
                                    control={control}
                                    options={[
                                        { value: "true", label: "Exibe" },
                                        { value: "false", label: "Não exibe" }
                                    ]}
                                />
                            )
                        }
                    </S.GridItem>
                )
            }
            {
                listaRiscoGrupoTitulo && (
                    <S.GridItem cols={4}>
                        <Select
                            id="idRiscoGrupoTitulo"
                            name="idRiscoGrupoTitulo"
                            control={control}
                            label="Grupo"
                            error={errors.idRiscoGrupoTitulo?.message}
                            isDefaultEnabled
                            options={
                                listaRiscoGrupoTitulo.map((item: any) => {
                                    return (
                                        { label: item.descricao, value: item.id }
                                    )
                                })
                            }
                        />
                    </S.GridItem>
                )
            }
            <br />
            <S.GridItem cols={4}>
                <div style={{ gridColumn: "span 3" }} />
                <Button type="submit" form="formGerar" disabled={!isValid}>Solicitar</Button>
            </S.GridItem>
        </S.Form>
    )
}

export default GerarRelatorios