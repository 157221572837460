import styled, { css } from "styled-components";
import Loader from "../../assets/loader.gif"

interface IButtonProps {
    loading?: boolean;
}

export const StyledButton = styled.button<IButtonProps>`
    height: 24.39px;
    padding: 0 2rem;
    min-width: 6rem;
    color: var(--pure-white);
    color: #FFFFFF;
    border-radius: .25rem;
    background-color: var(--blue-primary);
    font-family: "Sora", sans-serif;
    transition: all .2s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    justify-content: center;

    &:hover {
        filter: brightness(1.1);
    }

    &:active {
        filter: brightness(.9);
    }

    &:focus {
        outline: 2px solid var(--light-blue);
    }

    &:disabled {
        background-color: var(--disabled-blue);
        cursor: not-allowed;
    }

    ${props => props.loading && css`
        background-color: var(--dark-gray) !important;
        color: transparent;
        background-image: url(${Loader});
        background-repeat: no-repeat;
        background-position: center;
        background-size: .8rem;
        transition: none;
        cursor: progress;
    `}
`