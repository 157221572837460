import styled from "styled-components";

export const WithLabelContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .2rem;
`

export const StyledLabel = styled.span`
    font-size: .8rem;
    font-weight: 700;
    color: var(--dark-gray);
`

export const WithLabelContent = styled.div`
    height: 24.39px;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
`