import { privateDelete } from '../../../methods/delete';
import { privateGet } from '../../../methods/get';
import { privatePost } from '../../../methods/post';
import { privatePut } from '../../../methods/put';
import { IAdicionarPessoaRelacao, IAlterarPessoaRelacao, IExcluirPessoaRelacao, IObterListaPessoaRelacaoResponse } from '../../../models/Configuracoes/PessoaRelacao/index';

const baseApiPath = "/configuracao/pessoarelacao"

export const adicionarPessoaRelacao = (body: IAdicionarPessoaRelacao) => {
    return privatePost<string>(`${baseApiPath}/adicionar`, body)
}

export const obterListaPessoaRelacao = (idTipoSituacao?: number) => {
    return privateGet<IObterListaPessoaRelacaoResponse>(`${baseApiPath}/obterlistapessoarelacao?idTipoSituacao=${idTipoSituacao || 3}`)
}

export const alterarPessoaRelacao = (body: IAlterarPessoaRelacao) => {
    return privatePut<string>(`${baseApiPath}/alterar`, body)
}

export const excluirPessoaRelacao = (idPessoaRelacao: IExcluirPessoaRelacao) => {
    return privateDelete<string>(`${baseApiPath}/excluir`, idPessoaRelacao)
}

export const obterListaPessoaRelacaoPesquisaCasoJuridico = (idTipoSituacao?: number) => {
    return privateGet<IObterListaPessoaRelacaoResponse[]>(`${baseApiPath}/obterlistapessoarelacaopesquisacasojuridico?idTipoSituacao=${idTipoSituacao || 1}`)
}