import * as S from "../../style"
import { useForm, Control } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Select from "../../../../components/Select";
import { alterarRiscoFaseProvisaoCasoJuridico, obterListaIndicesPropriedades, obterListaLancamentoRiscoCasoJuridicoPorRiscoFase, obterListaObjetoCasoJuridico, obterListaParteCasoJuridico, obterListaParteContrariaCasoJuridico, obterListaRiscoFasePorCasoJuridico } from "../../../../services/api/CasoJuridico";
import WithLabel from "../../../../components/WithLabel";
import { IObterListaIndicesPropriedadesResponse, IObterListaLancamentoRiscoCasoJuridicoPorRiscoFaseResponse } from "../../../../services/models/CasoJuridico";
import Button from "../../../../components/Button";
import ItemsList, { HeaderList, ListItem } from "../../../../components/ItemsList";
import ActionButton from "../../../../components/ActionButton";
import { FiCheck, FiCrosshair, FiEdit, FiX } from "react-icons/fi";
import { convertCurrency, convertToNumber } from '../../../../utils/index';
import Input from "../../../../components/Input";
import { IObterListaParteCasoJuridicoResponse, ISalvarLancamentoRiscoCasoJuridicoPorRiscoFase } from '../../../../services/models/CasoJuridico/index';
import { salvarLancamentoRiscoCasoJuridicoPorRiscoFase, obterListaNovaFasePorCasoJuridico } from '../../../../services/api/CasoJuridico/index';
import Popup from "../../../../components/Popup";
import Checkbox from "../../../../components/Checkbox";
import CurrencyInput from "../../../../components/CurrencyInput";
import PercentageInput from "../../../../components/PercentageInput";
import { toast } from "react-toastify";
import { usePermissionsStore } from "../../../../stores/PermissionsStore";
import { useAuthStore } from "../../../../stores/AuthStore";

interface ILancamentos {
    storedId: string;
    caseIsSctr: boolean;
    caseFaseProvisao: number | null;
}

const getNow = () => {
    let dt = new Date
    let day = dt.getDate() > 10 ? dt.getDate() : `0${dt.getDate()}`
    let month = dt.getMonth() + 1 > 10 ? dt.getMonth() + 1 : `0${dt.getMonth() + 1}`
    let year = dt.getFullYear()
    return `${year}-${month}-${day}`
}

const Lancamentos = ({
    storedId,
    caseIsSctr,
    caseFaseProvisao
}: ILancamentos) => {
    const [listaFasesPorCaso, setListaFasesPorCaso] = useState<any>(null)
    const [listaPartesContrarias, setListaPartesContrarias] = useState<any>(null)
    const [listaIndices, setListaIndices] = useState<IObterListaIndicesPropriedadesResponse[] | null>(null)
    const [listaLancamentos, setListaLancamentos] = useState<IObterListaLancamentoRiscoCasoJuridicoPorRiscoFaseResponse[] | null>(null)
    const [canEditDataBase, setCanEditDataBase] = useState<boolean>(false)
    const [parteContraria, setParteContraria] = useState<any>(null)
    const [popupNovaFase, setPopupNovaFase] = useState<boolean>(false)
    const [listaNovaFase, setListaNovaFase] = useState<any>(null)
    const [dataBase, setDataBase] = useState<string>("")
    const [itemParaEditar, setItemParaEditar] = useState<any>(null)
    const [canNovaFase, setCanNovaFase] = useState<boolean>(true)
    const [canSave, setCanSave] = useState<boolean>(false)

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isValid
        },
        getValues,
        setValue,
        register,
        getFieldState,
        reset,
        watch,
        setError
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    })

    useEffect(() => {
        if (storedId) {
            ObterListaRiscoFasePorCasoJuridico(+storedId)
            ObterListaParteCasoJuridico(+storedId)
            ObterListaNovaFasePorCasoJuridico(+storedId)
            ObterListaIndicesPropriedades()
        }
    }, [storedId])

    // ALTEROU FASE
    useEffect(() => {
        let fase = watch("fasesDoCaso")
        let parteContraria = watch("parteContraria")
        if (!fase || !parteContraria) return
        if ((fase === "Selecione") || fase === undefined) return

        ObterListaLancamentoRiscoCasoJuridicoPorRiscoFase(+storedId, fase)
        setCanSave(false)
    }, [watch("fasesDoCaso"), watch("parteContraria")])

    const ObterListaRiscoFasePorCasoJuridico = async (idCaso: number) => {
        let res = await obterListaRiscoFasePorCasoJuridico(idCaso)
        setListaFasesPorCaso(res)
        setValue("fasesDoCaso", caseFaseProvisao)
    }

    const ObterListaParteContrariaCasoJuridico = async (idCaso: number) => {
        let res = await obterListaParteContrariaCasoJuridico(idCaso)
        let partePrincipal = res.filter((item: any) => {
            return item.isPrincipal
        })
        setValue("parteContraria", partePrincipal[0].idPessoa)
    }

    const ObterRiscoFasePorId = (idFase: number) => {
        return listaFasesPorCaso.filter((item: any) => {
            return item.id === idFase
        })[0].descricao
    }

    const ObterListaParteCasoJuridico = async (idCaso: number) => {
        let res = await obterListaParteCasoJuridico(idCaso)
        let partesContrarias = res.filter((item: any) => {
            return item.idPessoaRelacao === 2
        })
        setListaPartesContrarias(partesContrarias)
        ObterListaParteContrariaCasoJuridico(idCaso)
    }

    const ObterPartePorId = (idParte: number) => {
        let parte = listaPartesContrarias?.filter((item: IObterListaParteCasoJuridicoResponse) => {
            return item.idPessoa === idParte
        })[0]
        return parte === undefined ? "N/A" : parte.nomePessoa
    }

    const ObterListaLancamentoRiscoCasoJuridicoPorRiscoFase = async (idCaso: number, idRiscoFase: number) => {
        let res = await obterListaLancamentoRiscoCasoJuridicoPorRiscoFase(idCaso, idRiscoFase, +watch("parteContraria"))

        if (res.length <= 0) return

        // SETAR DATA BASE
        if (res[0].dataPrincipal) {
            setDataBase(res[0].dataPrincipal.substring(0, 7))
            setValue("mesAno", res[0].dataPrincipal.substring(0, 7))
        }

        // SETAR PARTE CONTRÁRIA
        if (res[0].idParteContraria) {
            setParteContraria(res[0].idParteContraria)
            setValue("parteContraria", res[0].idParteContraria)
        }

        // SETAR LISTA DE LANCAMENTOS
        setListaLancamentos(res)
    }

    useEffect(() => {
        if (watch("parteContraria") === "Selecione") return
        if (!listaLancamentos) return
        let pContraria = +watch("parteContraria")

        if (pContraria !== parteContraria) {
            let listaLancAux = [...listaLancamentos]
            listaLancAux.forEach((item: any) => {
                item.idParteContraria = pContraria
                item.nomeParteContraria = ObterNomeParteContrariaPorId(pContraria)
            })
            setListaLancamentos([...listaLancAux])
            setCanSave(true)
        }
    }, [watch("parteContraria")])

    const ObterNomeParteContrariaPorId = (idParteContraria: number) => {
        return listaPartesContrarias.filter((item: any) => {
            return item.idPessoa === idParteContraria
        })[0].nomePessoa
    }

    const ObterListaIndicesPropriedades = async () => {
        let res = await obterListaIndicesPropriedades()
        setListaIndices(res)
        ObterIndicePorId(143)
    }

    const ObterIndicePorId = (idIndice: number) => {
        let indice = listaIndices?.filter((item: IObterListaIndicesPropriedadesResponse) => {
            return item.id === idIndice
        })[0]
        return indice === undefined ? "N/A" : indice.nome
    }

    const AlterarDataBase = (mesAno: string) => {
        if (!listaLancamentos) return

        let listaLancamentosAux = [...listaLancamentos]

        listaLancamentosAux.forEach((item) => {
            item.dataPrincipal = mesAno
        })

        setListaLancamentos([...listaLancamentosAux])
        setCanSave(true)
    }

    const currencyToNumber = (value: string) => {
        return +value.replaceAll("R$ ", "").replaceAll(".", "").replaceAll(",", ".")
    }

    const SalvarLancamentoRiscoCasoJuridicoPorRiscoFase = async (lista: any) => {
        let body: ISalvarLancamentoRiscoCasoJuridicoPorRiscoFase[] = lista.map((item: any) => {
            return {
                id: item.id,
                dataPrincipal: item.dataPrincipal,
                taxaJurosMora: item.taxaJurosMora,
                isUtilizaSelic: item.isUtilizaSelic,
                idIndice: item.idIndice,
                idCaso: item.idCaso,
                idPessoa: item.idParteContraria,
                idRiscoFase: item.idRiscoFase,
                idRiscoTitulo: item.idRiscoTitulo,
                idRiscoSubtitulo: item.idRiscoSubtitulo,
                valorPrincipal: convertToNumber(item.valorPrincipal),
                valorJurosAcumulado: convertToNumber(item.valorJurosAcumulado)
            }
        })

        await salvarLancamentoRiscoCasoJuridicoPorRiscoFase(body)
        setCanNovaFase(true)
        setCanSave(false)
    }

    const ObterListaNovaFasePorCasoJuridico = async (idCaso: number) => {
        let res = await obterListaNovaFasePorCasoJuridico(idCaso)
        setListaNovaFase(res)
    }

    const ObterNovaFasePorId = (idFase: number) => {
        let fase = listaNovaFase?.filter((item: any) => {
            return item.id === idFase
        })[0]
        return fase === undefined ? "N/A" : fase.descricao
    }

    const AdicionarFase = async () => {

        let fase = watch("fasesNovaFase")
        let faseBase = watch("faseBase")
        let novaFaseProvisiona = watch("novaFaseProvisiona") === "sim"

        if (novaFaseProvisiona) {
            alterarRiscoFaseProvisaoCasoJuridico({
                idCaso: +storedId,
                idRiscoFaseProvisao: +fase
            })
        }

        let listaNovaFase: any

        if (faseBase) {

            listaNovaFase = await obterListaLancamentoRiscoCasoJuridicoPorRiscoFase(+storedId, +faseBase)

            listaNovaFase.forEach((item: any) => {
                item.id = 0
                item.idRiscoFase = +fase
            })

            await SalvarLancamentoRiscoCasoJuridicoPorRiscoFase(listaNovaFase) // DUPLICAR PARA BAIXO
            setPopupNovaFase(false)
        } else {
            const objetos = localStorage.getItem("objetos")
            if (objetos) {
                const parsedObjetos = JSON.parse(objetos)

                listaNovaFase = parsedObjetos.map((item: any) => {
                    return (
                        {
                            id: 0,
                            idCaso: +storedId,
                            idParteContraria: +watch("parteContraria"),
                            nomePartecontraria: ObterNomeParteContrariaPorId(+watch("parteContraria")),
                            idRiscoFase: +fase,
                            descricaoRiscoFase: "",
                            idRiscoTitulo: item.idRiscoTitulo,
                            descricaoRiscoTitulo: item.descricaoRiscoTitulo,
                            idRiscoSubtitulo: item.idRiscoSubtitulo,
                            descricaoRiscoSubtitulo: item.descricaoRiscoSubtitulo,
                            dataPrincipal: getNow(),
                            valorPrincipal: 0,
                            valorJurosAcumulado: 0,
                            taxaJurosMora: 0,
                            isUtilizaSelic: false,
                            idIndice: 0
                        }
                    )
                })

                await SalvarLancamentoRiscoCasoJuridicoPorRiscoFase(listaNovaFase)
                localStorage.removeItem("objetos")
                setPopupNovaFase(false)


            } else {
                setPopupNovaFase(false)
                toast.dismiss("objetoNaoExiste")
                toast.error("Não existem objetos no caso.", { toastId: "objetoNaoExiste" })
            }

        }

        await ObterListaRiscoFasePorCasoJuridico(+storedId)
        await ObterListaNovaFasePorCasoJuridico(+storedId)
        setValue("fasesNovaFase", "Selecione")
        setValue("faseBase", "Selecione")
        setValue("novaFaseProvisiona", null)
        setPopupNovaFase(false)

    }

    const ObterListaObjetoCasoJuridico = async (idCaso: number) => {
        let res = await obterListaObjetoCasoJuridico(idCaso)
    }

    const EditarItemLancamento = (lancamento: any) => {
        if (!listaLancamentos) return

        let idItem = lancamento.id

        let valorPrincipal = watch(`valorPrincipal-${lancamento.id}`)
        let idIndice = +watch(`idIndice-${lancamento.id}`)
        let valorJurosAcumulado = watch(`valorJurosAcumulado-${lancamento.id}`)
        let taxaJurosMora = watch(`taxaJurosMora-${lancamento.id}`) ? +watch(`taxaJurosMora-${lancamento.id}`).replaceAll("%", "") : 0
        let isUtilizaSelic = watch(`isUtilizaSelic-${lancamento.id}`) === "true"

        let listaLancamentosAux = [...listaLancamentos]

        listaLancamentosAux.forEach((item) => {
            if (item.id === idItem) {
                item.valorPrincipal = valorPrincipal || item.valorPrincipal
                item.idIndice = idIndice || item.idIndice
                item.valorJurosAcumulado = valorJurosAcumulado || item.valorJurosAcumulado
                item.taxaJurosMora = taxaJurosMora
                item.isUtilizaSelic = isUtilizaSelic
            }
        })

        setListaLancamentos([...listaLancamentosAux])
        setCanSave(true)
        setItemParaEditar(null)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(17);

    const {
        permiteAlterar,
        permiteInserir,
        permiteExcluir
    } = permissions

    // const permiteAlterar = true
    // const permiteExcluir = true
    // const permiteInserir = true

    return (
        <>{
            (permiteAlterar || permiteInserir) &&
            canSave && (
                <div style={{
                    position: "absolute",
                    right: "0rem",
                    top: "-10.5rem"
                }}>
                    <Button onClick={() => SalvarLancamentoRiscoCasoJuridicoPorRiscoFase(listaLancamentos)}>
                        Salvar Lançamentos
                    </Button>
                </div>
            )
        }
            {
                (permiteAlterar || permiteInserir) &&
                canSave && (
                    <S.GridItem cols={1}>
                        <span style={{ fontSize: ".8rem" }}>*As alterações nos lançamentos de casa fase devem ser salvas.</span>
                    </S.GridItem>
                )
            }
            <S.GridItem cols={2}>
                {
                    listaFasesPorCaso &&
                        listaFasesPorCaso.length > 0 ? (
                        <Select
                            id="fasesDoCaso"
                            name="fasesDoCaso"
                            label="Fase"
                            control={control}
                            disabled={!(permiteAlterar || permiteInserir)}
                            options={
                                listaFasesPorCaso.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    ) : (
                        <WithLabel text="&nbsp;">
                            <span>Nenhuma fase inserida no caso.</span>
                        </WithLabel>
                    )
                }
                {
                    !caseIsSctr && (
                        <WithLabel text="&nbsp;">
                            <Button disabled={!canNovaFase || !(permiteAlterar || permiteInserir)} onClick={() => setPopupNovaFase(true)}>Inserir Nova Fase</Button>
                        </WithLabel>
                    )
                }
            </S.GridItem>
            <S.GridItem cols={2}>
                {
                    listaPartesContrarias &&
                        listaPartesContrarias.length > 0 ? (
                        <Select
                            id="parteContraria" name="parteContraria" label="Parte Contrária" control={control}
                            disabled={
                                (watch("fasesDoCaso") === "Selecione") ||
                                (watch("fasesDoCaso") === undefined) ||
                                !(permiteAlterar || permiteInserir)
                            }
                            options={
                                listaPartesContrarias.map((item: any) => ({
                                    value: item.idPessoa,
                                    label: item.nomePessoa
                                }))
                            }
                        />
                    ) : (
                        <WithLabel text="">
                            <span>Nenhuma parte contrária no caso.</span>
                        </WithLabel>
                    )
                }
                <S.GridItem cols={2}>
                    <Input
                        type="month"
                        id="mesAno"
                        name="mesAno"
                        label="Mês/Ano"
                        control={control}
                        disabled={!canEditDataBase  || !(permiteAlterar || permiteInserir)}
                    />
                    {
                        !canEditDataBase && !caseIsSctr && (
                            <WithLabel text="&nbsp;">
                                <Button
                                    onClick={() => setCanEditDataBase(true)}
                                    disabled={
                                        (watch("fasesDoCaso") === "Selecione") ||
                                        (watch("fasesDoCaso") === undefined) ||
                                        !(permiteAlterar || permiteInserir)
                                    }
                                >Alterar Data Base</Button>
                            </WithLabel>
                        )
                    }
                    {
                        canEditDataBase && (
                            <WithLabel text="&nbsp;">
                                <ActionButton act="delete" onClick={() => {
                                    setCanEditDataBase(false)
                                    setValue("mesAno", dataBase)
                                }}>
                                    <FiX />
                                </ActionButton>
                                <ActionButton act="confirm" onClick={() => {
                                    setCanEditDataBase(false)
                                    setDataBase(watch("mesAno"))
                                    AlterarDataBase(watch("mesAno"))
                                }}>
                                    <FiCheck />
                                </ActionButton>
                            </WithLabel>
                        )
                    }
                </S.GridItem>
            </S.GridItem>
            <S.GridItem cols={1}>
                {
                    listaLancamentos &&
                    listaLancamentos.length > 0 && (
                        <ItemsList>
                            <HeaderList items={
                                caseIsSctr
                                    ? [
                                        "Parte Contrária",
                                        "Objeto",
                                        "Reflexo",
                                        "Valor",
                                        "Índice",
                                        "Vlr. Juros Acum.",
                                        "Tx. Juros Mora"
                                    ]
                                    : [
                                        "Parte Contrária",
                                        "Objeto",
                                        "Reflexo",
                                        "Valor",
                                        "Índice",
                                        "Vlr. Juros Acum.",
                                        "Tx. Juros Mora",
                                        "Ações"
                                    ]} />
                            <>
                                {
                                    listaLancamentos.map((item: IObterListaLancamentoRiscoCasoJuridicoPorRiscoFaseResponse, index: number) => {
                                        return (
                                            <ListItem height="3.5" key={index}>
                                                <div>{item.nomeParteContraria}</div>
                                                <div>{item.descricaoRiscoTitulo}</div>
                                                <div>{item.descricaoRiscoSubtitulo}</div>
                                                <div>
                                                    {
                                                        itemParaEditar === item.id ? (
                                                            <CurrencyInput
                                                                id={`valorPrincipal-${item.id}`}
                                                                name={`valorPrincipal-${item.id}`}
                                                                control={control}
                                                                value={item.valorPrincipal}
                                                            />
                                                        ) : (
                                                            convertCurrency(item.valorPrincipal)
                                                        )
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        itemParaEditar === item.id &&
                                                            listaIndices ? (
                                                            <Select
                                                                id={`idIndice-${item.id}`}
                                                                name={`idIndice-${item.id}`}
                                                                control={control}
                                                                defaultValue={item.idIndice}
                                                                options={
                                                                    listaIndices.map((item: any) => ({
                                                                        value: item.id, label: item.nome
                                                                    }))
                                                                }
                                                            />
                                                        ) : (
                                                            ObterIndicePorId(item.idIndice)
                                                        )
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        itemParaEditar === item.id ? (
                                                            <CurrencyInput
                                                                id={`valorJurosAcumulado-${item.id}`}
                                                                name={`valorJurosAcumulado-${item.id}`}
                                                                value={item.valorJurosAcumulado}
                                                                control={control}
                                                            />
                                                        ) : (
                                                            convertCurrency(item.valorJurosAcumulado)
                                                        )
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        itemParaEditar === item.id ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    gap: "1.4rem",
                                                                    alignItems: "center"
                                                                }}
                                                            >
                                                                <PercentageInput
                                                                    id={`taxaJurosMora-${item.id}`}
                                                                    name={`taxaJurosMora-${item.id}`}
                                                                    control={control}
                                                                    value={item.taxaJurosMora}
                                                                    disabled={watch(`isUtilizaSelic-${item.id}`)}
                                                                />
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignItems: "center",
                                                                        gap: ".3rem"
                                                                    }}
                                                                >
                                                                    <span>Selic</span>
                                                                    <input
                                                                        type="checkbox"
                                                                        defaultChecked={item.isUtilizaSelic}
                                                                        value={"true"}
                                                                        {...register(`isUtilizaSelic-${item.id}`)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            item.isUtilizaSelic ? "SELIC" : `${item.taxaJurosMora}%`
                                                        )
                                                    }
                                                </div>
                                                {
                                                    !caseIsSctr ? (

                                                        <div>
                                                            {
                                                                itemParaEditar === item.id ? (
                                                                    <>
                                                                        <ActionButton
                                                                            act="delete"
                                                                            onClick={() => {
                                                                                setItemParaEditar(null)
                                                                                setValue(`valorPrincipal-${item.id}`, item.valorPrincipal)
                                                                                setValue(`idIndice-${item.id}`, item.idIndice)
                                                                                setValue(`valorJurosAcumulado-${item.valorJurosAcumulado}`, item.valorJurosAcumulado)
                                                                                setValue(`taxaJurosMora-${item.id}`, item.taxaJurosMora)
                                                                                setValue(`isUtilizaSelic-${item.id}`, item.isUtilizaSelic)
                                                                            }}
                                                                        >
                                                                            <FiX />
                                                                        </ActionButton>
                                                                        <ActionButton
                                                                            act="confirm"
                                                                            onClick={() => EditarItemLancamento(item)}
                                                                        >
                                                                            <FiCheck />
                                                                        </ActionButton>
                                                                    </>
                                                                ) : (
                                                                    <ActionButton
                                                                        act="edit"
                                                                        onClick={() => setItemParaEditar(item.id)}
                                                                    >
                                                                        <FiEdit />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                        </div>
                                                    ) : <></>
                                                }
                                            </ListItem>
                                        )
                                    })
                                }
                            </>
                        </ItemsList>
                    )}
            </S.GridItem>
            <Popup
                show={popupNovaFase}
                onClose={() => setPopupNovaFase(false)}
                title={"Inserir Nova Fase"}
                confirmAction={() => AdicionarFase()}
                confirmDisabled={
                    watch("fasesNovaFase") === "Selecione" ||
                    watch("fasesNovaFase") === undefined
                }
            // confirmAction={() => adicionarFaseNoCaso()}
            >
                {
                    listaNovaFase && (
                        <Select
                            id="fasesNovaFase"
                            name="fasesNovaFase"
                            control={control}
                            label="Nova Fase"
                            options={
                                listaNovaFase.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    )
                }
                {
                    listaFasesPorCaso &&
                        listaFasesPorCaso.length > 0 ? (
                        <Select
                            id="faseBase"
                            name="faseBase"
                            control={control}
                            label="Fase Base para Correção"
                            options={
                                listaFasesPorCaso.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    ) : (
                        <span>Nenhuma fase.</span>
                    )
                }
                <Checkbox
                    id={"novaFaseProvisiona"}
                    name={"novaFaseProvisiona"}
                    label="Usar nova fase para provisionamento"
                    control={control} value="sim"
                />
            </Popup>
        </>
    )
}

export default Lancamentos