import { useState, useEffect } from 'react';
import Container from "../../../../components/Container";
import ItemsList, { HeaderList, ListItem } from "../../../../components/ItemsList";
import { obterListaEventoCasoJuridico } from "../../../../services/api/CasoJuridico";
import { IObterListaEventoCasoJuridicoResponse } from "../../../../services/models/CasoJuridico";

interface IInfosAdicionais {
    storedId?: string | null;
}

const InfosAdicionais = ({ storedId }: IInfosAdicionais) => {
    const [listaEventoCasoJuridico, setListaEventoCasoJuridico] = useState<IObterListaEventoCasoJuridicoResponse[] | null>(null)

    useEffect(() => {
        if (storedId) {
            ObterListaEventoCasoJuridico(+storedId)
        }
    }, [storedId])

    const ObterListaEventoCasoJuridico = async (idCaso: number) => {
        let res = await obterListaEventoCasoJuridico(idCaso)
        setListaEventoCasoJuridico(res)
    }

    return (
        <>
            <Container title="Informações do Registro">
                {
                    listaEventoCasoJuridico &&
                        listaEventoCasoJuridico.length > 0 ?
                        (
                            <ItemsList>
                                <HeaderList
                                    items={["Data e Hora", "Descrição do Evento", "Pessoa Responsável"]}
                                />
                                <>
                                    {
                                        listaEventoCasoJuridico.map((item: any, index: any) => {
                                            return (
                                                <ListItem key={index}>
                                                    <div>{item.dataHora}</div>
                                                    <div>{item.evento}</div>
                                                    <div>{item.pessoaResponsavel}</div>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </>
                            </ItemsList>
                        ) :
                        (
                            <p>Nenhum resultado.</p>
                        )
                }
            </Container>
        </>
    )
}

export default InfosAdicionais