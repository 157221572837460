import styled from "styled-components";

export const ReportsContent = styled.div`
    width: 100%;
    padding: 1rem 3rem 2rem 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`

export const PageHandlerContainer = styled.div`
    border: 1px solid var(--blue-primary);
    border-radius: .25rem;
    padding: 1rem 0;
    width: 100%;
    /* height: 100%; */
`

export const FloatButtons = styled.div`
    position: absolute;
    top: 1rem;
    right: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
`

export const ItemDataWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 2.3125rem);
    padding: 5rem;
`

export const Form = styled.form`
    width: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: relative;
`

export const ContentWrapper = styled.div`
    width: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
`

interface IGridItem {
    cols: number;
}

export const GridItem = styled.div<IGridItem>`
    display: grid;
    grid-template-columns: repeat(${props => props.cols},1fr);
    width: ${props => `${props.cols}fr`};
    column-gap: 3rem;

    .error {
        font-size: .875rem;
        color: var(--red);
    }
`

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    margin: 1rem 0;
`

interface IControlView {
    active: boolean;
}

export const ControlView = styled.div<IControlView>`
    display: ${props => props.active ? "flex" : "none"};
    flex-direction: column;
    gap: .5rem;
`

export const ButtonsContainer = styled.div`
    position: absolute;
    top: 1.5rem;
    right: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
`

export const Line = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
`

export const FlexLine = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: center;
`

export const StyledHr = styled.hr`
    border-top: 1px solid var(--text-blue);
`

export const TableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    background: var(--white);

    td {
        border: 1px solid var(--dark-gray);
        text-align: center;
        padding: .3rem;
    }

    th {
        background-color: var(--blue-primary);
        color: var(--white);
        font-weight: 300;
        font-size: .8rem;
        padding: 0.5rem;
        border: 1px solid var(--blue-primary);
    }

    tbody {
        tr {
            transition: all .2s ease;
            :nth-child(even) {
                background-color: var(--light-gray);
            }
            &:hover {
                cursor: pointer;
                background-color: #00000f30;
            }
        }
    }
`