import { yupResolver } from "@hookform/resolvers/yup";
import { PencilSimple, Trash } from "phosphor-react";
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ActionButton from "../../../components/ActionButton";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import ItemsList, { HeaderList, ListItem } from "../../../components/ItemsList";
import ModalAction from "../../../components/ModalAction";
import PageTitle from "../../../components/PageTitle";
import Select from "../../../components/Select";
import TabSwitcher from "../../../components/TabSwitcher";
import Template from "../../../components/Template";
import { obterListaCasoJuridicoArea } from '../../../services/api/Configuracoes/CasoJuridicoArea/index';
import { obterListaRiscoGrupoTitulo } from '../../../services/api/Configuracoes/RiscoGrupoTitulo/index';
import { adicionarRiscoTitulo, alterarRiscoTitulo, excluirRiscoTitulo, obterListaRiscoTitulo } from "../../../services/api/Configuracoes/RiscoTitulo";
import { IAlterarRiscoTitulo, IObterListaRiscoTituloResponse } from '../../../services/models/Configuracoes/RiscoTitulo/index';
import getIdClienteMacdata from "../../../utils/getIdClienteMacdata";
import * as S from "../style";
import { usePermissionsStore } from "../../../stores/PermissionsStore";

const AddRiskTitleSchema = yup.object({
    descricao: yup.string().required("O nome da fase é obrigatório."),
    isConsolidado: yup.string().required("O status é obrigatório.").oneOf(["Sim", "Não"]),
    idCasoJuridicoArea: yup.number().required("Obrigatório"),
    idRiscoGrupoTitulo: yup.number().required("Obrigatório")
})

interface item extends IObterListaRiscoTituloResponse { }

const RiskTitle = () => {
    const [view, setView] = useState<number>(0)
    const [RiskTitleList, setRiskTitleList] = useState<any>(null)

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [itemToDelete, setItemToDelete] = useState<item | null>(null)
    const [itemToEdit, setItemToEdit] = useState<item | null>(null)

    const [listaRiscoGrupoTitulo, setListaRiscoGrupoTitulo] = useState<any | null>(null)
    const [listaCasoJuridicoArea, setListaCasoJuridicoArea] = useState<any | null>(null)

    useEffect(() => {
        view === 0 && ObterListaRiscoTitulo()
        if (view === 1) {
            ObterListaRiscoGrupoTitulo()
            ObterListaCasoJuridicoArea()
        }
    }, [view])

    const {
        handleSubmit,
        control,
        reset,
        formState: {
            errors,
            isValid
        } } = useForm({
            mode: "onChange",
            reValidateMode: "onChange",
            resolver: yupResolver(AddRiskTitleSchema)
        })

    const ObterListaRiscoTitulo = async () => {
        let res = await obterListaRiscoTitulo()
        setRiskTitleList(res)
    }

    const AdicionarRiscoTitulo = async (data: any) => {
        let body = {
            ...data,
            idClienteMacdata: getIdClienteMacdata(),
            isConsolidado: data.isConsolidado === "Sim" ? true : false,
        }

        await adicionarRiscoTitulo(body).then(() => {
            reset({
                descricao: null,
                isConsolidado: null,
                idRiscoGrupoTitulo: null,
                idCasoJuridicoArea: null
            })
        })
    }

    const ExcluirRiscoTitulo = async (idRiscoTitulo: number) => {
        await excluirRiscoTitulo({ idRiscoTitulo })
        ObterListaRiscoTitulo()
    }

    const EditarRiscoTitulo = async (newRiscoTitulo: IAlterarRiscoTitulo) => {
        await alterarRiscoTitulo(newRiscoTitulo)
        ObterListaRiscoTitulo()
    }

    const handleShowDeleteModal = (item: item) => {
        setItemToDelete(item)
        setShowDeleteModal(true)
    }

    const handleShowEditModal = (item: item) => {
        setItemToEdit(item)
        setShowEditModal(true)
    }

    const ObterListaRiscoGrupoTitulo = async () => {
        let res = await obterListaRiscoGrupoTitulo(1)
        setListaRiscoGrupoTitulo(res)
    }

    const ObterListaCasoJuridicoArea = async () => {
        let res = await obterListaCasoJuridicoArea(1)
        setListaCasoJuridicoArea(res)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(6);

    // PARA TESTE
    // const permissions = {
    //     permiteInserir: false,
    //     permiteAlterar: false,
    //     permiteExcluir: false,
    // }

    const {
        permiteInserir,
        permiteAlterar,
        permiteExcluir
    } = permissions;

    const menuItems = permiteInserir ?
        [
            "Dados",
            "Adicionar"
        ] : [
            "Dados"
        ]

    const headerList = permiteAlterar || permiteExcluir ?
        [
            "Descrição",
            "Status",
            "Consolidado",
            "Desc. Área",
            "Desc. Grupo",
            "Ações"
        ] :
        [
            "Descrição",
            "Status",
            "Consolidado",
            "Desc. Área",
            "Desc. Grupo"
        ]

    return (
        <Template>
            <PageTitle>
                Risco Título
            </PageTitle>
            <TabSwitcher
                list={menuItems}
                view={view}
                setView={setView}
            />
            <S.Content>
                <S.ControlView active={view === 0}>
                    {
                        RiskTitleList ? (
                            <ItemsList>
                                <HeaderList
                                    items={headerList}
                                    big={[0]}
                                />
                                {
                                    RiskTitleList.map((item: item, index: any) => {
                                        return (
                                            <ListItem key={index}>
                                                <div className="big">{item.descricao}</div>
                                                <div>{item.isAtivo ? "Ativo" : "Inativo"}</div>
                                                <div>{item.isConsolidado ? "Sim" : "Não"}</div>
                                                <div>{item.descricaoCasoJuridicoArea || "N/A"}</div>
                                                <div>{item.descricaoRiscoGrupoTitulo || "N/A"}</div>
                                                {
                                                    (
                                                        permiteAlterar ||
                                                        permiteExcluir
                                                    ) && (
                                                        <div>
                                                            {
                                                                permiteAlterar && (
                                                                    <ActionButton act="edit" onClick={() => handleShowEditModal(item)}>
                                                                        <PencilSimple />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                            {
                                                                permiteExcluir && (
                                                                    <ActionButton act="delete" onClick={() => handleShowDeleteModal(item)}>
                                                                        <Trash />
                                                                    </ActionButton>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </ListItem>
                                        )
                                    })
                                }
                            </ItemsList>
                        ) : (<p>Carregando...</p>)
                    }
                </S.ControlView>
                <S.ControlView active={view === 1} id="addRisk" onSubmit={handleSubmit((data) => AdicionarRiscoTitulo(data))}>
                    <S.GridItem cols={2}>
                        <Input id="descricao" name="descricao" label="Descrição" control={control} error={errors?.descricao?.message} />
                    </S.GridItem>
                    <S.GridItem cols={2}>
                        <Select
                            id="isConsolidado"
                            name="isConsolidado"
                            control={control}
                            label="Consolidado"
                            error={errors.isConsolidado?.message}
                            options={[
                                { value: "Sim", label: "Sim" },
                                { value: "Não", label: "Não" }
                            ]}
                        />
                    </S.GridItem>
                    {
                        listaRiscoGrupoTitulo && (
                            <S.GridItem cols={2}>
                                <Select
                                    id="idRiscoGrupoTitulo"
                                    name="idRiscoGrupoTitulo"
                                    control={control}
                                    label="Grupo"
                                    error={errors.idRiscoGrupoTitulo?.message}
                                    options={
                                        listaRiscoGrupoTitulo.map((item: any) => {
                                            return (
                                                { label: item.descricao, value: item.id }
                                            )
                                        })
                                    }
                                />
                            </S.GridItem>
                        )
                    }
                    {
                        listaCasoJuridicoArea && (
                            <S.GridItem cols={2}>
                                <Select
                                    id="idCasoJuridicoArea"
                                    name="idCasoJuridicoArea"
                                    control={control}
                                    label="Área"
                                    error={errors.idCasoJuridicoArea?.message}
                                    options={
                                        listaCasoJuridicoArea.map((item: any) => {
                                            return (
                                                { label: item.descricao, value: item.id }
                                            )
                                        })
                                    }
                                />
                            </S.GridItem>
                        )
                    }
                    {
                        isValid && (
                            <S.GridItem cols={6}>
                                <Button type="submit" form="addRisk">Adicionar</Button>
                            </S.GridItem>
                        )
                    }
                </S.ControlView>
            </S.Content>
            {
                showDeleteModal && itemToDelete && (
                    <ModalAction
                        type="delete"
                        onConfirm={() => ExcluirRiscoTitulo(itemToDelete.id)}
                        onClose={() => setShowDeleteModal(false)}
                        title="Excluir Risco Título"
                        item={itemToDelete}
                    />
                )
            }
            {
                showEditModal && itemToEdit && (
                    <ModalAction
                        type="edit"
                        onConfirm={(e) => EditarRiscoTitulo(e)}
                        onClose={() => setShowEditModal(false)}
                        title="Editar Risco Título"
                        item={itemToEdit}
                    />
                )
            }
        </Template>
    )

}

export default RiskTitle