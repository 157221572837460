import Button from "../Button"
import PageTitle from "../PageTitle"
import * as S from "./style"
import { useEffect } from 'react';

interface IPopup {
    show: boolean;
    onClose: () => void;
    children: React.ReactNode;
    title: string;
    confirmText?: string;
    minWidth?: string;
    confirmAction: () => void;
    confirmDisabled?: boolean;
}

const Popup = ({
    show,
    onClose,
    children,
    title,
    confirmText = "Confirmar",
    minWidth,
    confirmAction,
    confirmDisabled = false
}: IPopup) => {

    const hideBodyScroll = (value: "hidden" | "auto") => {
        let bd = document.querySelector("body")
        if (bd) {
            bd.style.overflow = value
        }
    }

    const closeAction = () => {
        onClose()
    }
    
    if (!show) {
        return null
    }

    return (
        <S.PopupWrapper onClick={() => closeAction()}>
            <S.PopupWindow onClick={(e) => e.stopPropagation()} minWidth={minWidth}>
                <PageTitle maxWidth="100">
                    {title}
                </PageTitle>
                <S.PopupContent>
                    {children}
                </S.PopupContent>
                <S.PopupButtonsContainer>
                    <Button onClick={() => closeAction()}>Cancelar</Button>
                    <Button onClick={() => confirmAction()} disabled={confirmDisabled}>{confirmText}</Button>
                </S.PopupButtonsContainer>
            </S.PopupWindow>
        </S.PopupWrapper>
    )
}

export default Popup