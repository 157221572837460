import styled from "styled-components";

export const PeopleDataWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 2.3125rem);
    padding: 5rem;
`

export const PeopleDataContent = styled.form`
    width: 100%;
    padding: 0 3rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: relative;
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
`

interface IGridItem {
    cols: number;
}

export const GridItem = styled.div<IGridItem>`
    display: grid;
    grid-template-columns: repeat(${props => props.cols},1fr);
    width: ${props => `${props.cols}fr`};
    column-gap: 3rem;

    .error {
        font-size: .875rem;
        color: var(--red);
    }
`

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    margin: 1rem 0;
`

interface IControlView {
    active: boolean;
}

export const ControlView = styled.div<IControlView>`
    display: ${props => props.active ? "flex" : "none"};
    flex-direction: column;
    gap: .5rem;
`

interface IButtonsContainer {
    top?: string;
    right?: string;
}

export const ButtonsContainer = styled.div<IButtonsContainer>`
    position: absolute;
    top: ${props => props.top ? `${props.top}rem` : "1.5rem"};
    right: ${props => props.right ? `${props.right}rem` : "3rem"};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
`