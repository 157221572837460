import * as S from "./style"

interface IWithLabel {
    text: string;
    children: React.ReactNode
}

const WithLabel = ({
    text,
    children
}: IWithLabel) => {
    return (
        <S.WithLabelContainer>
            <S.StyledLabel>{text}</S.StyledLabel>
            <S.WithLabelContent>
                {children}
            </S.WithLabelContent>
        </S.WithLabelContainer>
    )
}

export default WithLabel