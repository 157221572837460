import { IObterListaCasoJuridico, IObterListaCasoJuridicoResponse, IObterDetalheCasoJuridicoResponse, IObterCasoJuridicoResponse, IObterListaEventoCasoJuridicoResponse, IObterListaRiscoPorFaseCasoJuridicoResponse, IObterListaRiscoPorExitoCasoJuridicoResponse, IObterListaTipoConsultaRiscoCasoJuridicoResponse, IObterListaPaginadaPesquisaPessoaCasoJuridicoResponse, IObterListaRiscoCasoJuridicoResponse, IAdicionarCasoJuridico, IAlterarCasoJuridico, IBaixarCasoJuridico, IObterListaPaginadaDocumentoCalculoCasoJuridico, IObterListaPaginadaDocumentoCalculoCasoJuridicoResponse, IObterListaJustificativaCasoJuridicoPorRiscoFaseResponse, IObterListaRiscoFasePorCasoJuridicoResponse, IObterListaIndicesPropriedadesResponse, IObterListaLancamentoRiscoCasoJuridicoPorRiscoFaseResponse, IObterListaParteCasoJuridicoResponse, IObterListaObjetoCasoJuridicoResponse, ISalvarParteCasoJuridico, ISalvarCasoJuridico, IObterListaDistribuicaoRiscoCasoJuridicoPorRiscoFaseResponse, ISalvarDistribuicaoCasoJuridicoPorRiscoFase, IObterListaRiscoCasoJuridicoPorFaseResponse, IObterListaRiscoCasoJuridicoPorExitoResponse, ISalvarLancamentoRiscoCasoJuridicoPorRiscoFase, IObterListaNovaFasePorCasoJuridicoResponse, IObterProjecaoCasoJuridico, ISalvarJustificativaCasoJuridicoPorRiscoFase, IAlterarRiscoFasePRovisaoCasoJuridico, IObterListaParteContrariaCasoJuridicoResponse } from '../../models/CasoJuridico/index';
import { privateGet, privateGetFile } from '../../methods/get';
import { privateDelete } from '../../methods/delete';
import { privatePost } from '../../methods/post';
import { privatePut } from '../../methods/put';
const baseApiPath = "/casojuridico"

function convertDateAndTime(datetime: string) {
    let parsedDate = datetime.substring(0, 10).split("-")
    let parsedTime = datetime.substring(11, 19)

    let mountedDateAndTime = `${parsedDate[2]}/${parsedDate[1]}/${parsedDate[0]} - ${parsedTime}`

    return mountedDateAndTime
}

function convertDate(datetime: string) {
    let parsedDate = datetime.substring(0, 10).split("-")

    let mountedDate = `${parsedDate[2]}/${parsedDate[1]}/${parsedDate[0]}`

    return mountedDate
}

function convertToMonth(date: number) {
    if (!date) return
    let ano = date.toString().substring(0, 4)
    let mes = date.toString().substring(4, 6)
    return `${ano}-${mes}`
}

function convertCurrency(value: number) {
    let convertedCurrency = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
    })
    return convertedCurrency
}

export const obterListaPaginadaPesquisaCasoJuridico = (body: IObterListaCasoJuridico) => {
    let res = privateGet<IObterListaCasoJuridicoResponse>(`${baseApiPath}/obterlistapaginadapesquisacasojuridico`, body)

    return res
}

export const obterListaTipoRisco = async () => {
    let res = await privateGet<any>(`${baseApiPath}/obterlistatiporisco`)
    return res
}

export const obterListaEventoCasoJuridico = async (idCaso: number) => {
    let res = await privateGet<IObterListaEventoCasoJuridicoResponse[]>(`${baseApiPath}/obterlistaeventocasojuridico`, { idCaso })
    
    let handler = res.map((item: any) => {
        return {
            ...item,
            dataHora: convertDateAndTime(item.dataHora)
        }
    })

    return handler
}

export const obterListaRiscoPorFaseCasoJuridico = async (idCaso: number) => {
    let res = await privateGet<IObterListaRiscoPorFaseCasoJuridicoResponse[]>(`${baseApiPath}/obterlistariscoporfasecasojuridico`, { idCaso })

    let handler = res.map((item: any) => {
        return {
            ...item,
            data: convertDate(item.data),
            valorPrincipal: convertCurrency(item.valorPrincipal),
            valorCorrigido: convertCurrency(item.valorCorrigido),
            valorTotal: convertCurrency(item.valorTotal),
            juros: convertCurrency(item.juros)
        }
    })

    return handler
}

export const obterListaRiscoPorExitoCasoJuridico = async (idCaso: number, idRiscoFase: number) => {
    let res = await privateGet<IObterListaRiscoPorExitoCasoJuridicoResponse[]>(`${baseApiPath}/obterlistariscoporexitocasojuridico`, { idCaso, idRiscoFase })

    let handler = res.map((item: IObterListaRiscoPorExitoCasoJuridicoResponse) => {
        return {
            ...item,
            valorPrincipal: convertCurrency(item.valorPrincipal),
            valorCorrigido: convertCurrency(item.valorCorrigido),
            valorTotal: convertCurrency(item.valorTotal),
            juros: convertCurrency(item.juros)
        }
    })

    return handler
}

export const obterListaTipoConsultaRiscoCasoJuridico = async () => {
    let res = await privateGet<IObterListaTipoConsultaRiscoCasoJuridicoResponse[]>(`${baseApiPath}/obterlistatipoconsultariscocasojuridico`)

    return res
}

export const obterListaPaginadaPesquisaPessoaCasoJuridico = (body: any) => {
    let res = privateGet<IObterListaPaginadaPesquisaPessoaCasoJuridicoResponse>(`${baseApiPath}/obterlistapaginadapesquisapessoacasojuridico`, body)
    return res
}

export const excluirCasoJuridico = (idCaso: number) => {
    let res = privateDelete(`${baseApiPath}/excluir`, { idCaso })
    return res
}

export const adicionarCasoJuridico = (body: IAdicionarCasoJuridico) => {
    return privatePost<any>(`${baseApiPath}/adicionar`, body)
}

export const alterarCasoJuridico = (body: IAlterarCasoJuridico) => {
    return privatePut<string>(`${baseApiPath}/alterar`, body)
}

export const reativarCasoJuridico = (idCaso: number) => {
    return privatePut<string>(`${baseApiPath}/reativarcasojuridico?idCaso=${idCaso}`)
}

export const baixarCasoJuridico = (body: IBaixarCasoJuridico) => {
    return privatePut<string>(`${baseApiPath}/baixarcasojuridico`, body)
}

export const obterListaPaginadaDocumentoCalculoCasoJuridico = (body: IObterListaPaginadaDocumentoCalculoCasoJuridico) => {
    return privateGet<IObterListaPaginadaDocumentoCalculoCasoJuridicoResponse>(`${baseApiPath}/obterlistapaginadadocumentocalculocasojuridico`, body)
}

export const obterCasoJuridico = async (idCaso: number) => {
    let res = await privateGet<IObterCasoJuridicoResponse>(`${baseApiPath}/obtercasojuridico`, { idCaso }).then((response) => {
        let ret = response.id ? {
            ...response,
            dataCitacao: response.dataCitacao.substring(0, 10),
            dataDistribuicao: response.dataDistribuicao.substring(0, 10),
            anoMesProvisao: convertToMonth(response.anoMesProvisao)
        } : response
        return ret
    })
    return res
}

export const obterDetalheCasoJuridico = async (idCaso: number) => {
    let res = await privateGet<IObterDetalheCasoJuridicoResponse>(`${baseApiPath}/obterdetalhecasojuridico`, { idCaso })

    let handler = {
        ...res,
        dataBaixa: res.dataBaixa.substring(0, 10)
    }

    return handler
}

export const obterListaParteCasoJuridico = (idCaso: number) => {
    return privateGet<IObterListaParteCasoJuridicoResponse[]>(`${baseApiPath}/obterlistapartecasojuridico`, { idCaso })
}

export const excluirParteCasoJuridico = (idCaso: number, idPessoa: number, idPessoaRelacao: number) => {
    return privateDelete<string>(`${baseApiPath}/excluirpartecasojuridico`, { idCaso, idPessoa, idPessoaRelacao}, true)
}

export const obterListaRiscoFasePorCasoJuridico = (idCaso: number) => {
    return privateGet<IObterListaRiscoFasePorCasoJuridicoResponse[]>(`${baseApiPath}/obterlistariscofaseporcasojuridico`, { idCaso })
}

export const obterListaIndicesPropriedades = () => {
    return privateGet<IObterListaIndicesPropriedadesResponse[]>(`/indicespropriedades/obterlistaindicespropriedades`)
}

export const obterListaLancamentoRiscoCasoJuridicoPorRiscoFase = (idCaso: number, idRiscoFase: number, idPessoa?: number) => {
    return privateGet<IObterListaLancamentoRiscoCasoJuridicoPorRiscoFaseResponse[]>(`${baseApiPath}/obterlistalancamentoriscocasojuridicoporriscofase`, { idCaso, idRiscoFase, idPessoa })
}

export const obterListaObjetoCasoJuridico = (idCaso: number) => {
    return privateGet<IObterListaObjetoCasoJuridicoResponse[]>(`${baseApiPath}/obterlistaobjetocasojuridico`, { idCaso })
}

export const excluirObjetoCasoJuridico = (idCaso: number, idRiscoTitulo: number, idRiscoSubtitulo: number) => {
    return privateDelete<string>(`${baseApiPath}/excluirobjetocasojuridico`, { idCaso, idRiscoTitulo, idRiscoSubtitulo })
}

export const salvarParteCasoJuridico = (body: ISalvarParteCasoJuridico[]) => {
    return privatePost<string>(`${baseApiPath}/salvarpartecasojuridico`, body)
}

export const salvarCasoJuridico = (body: ISalvarCasoJuridico) => {
    return privatePost<string>(`${baseApiPath}/salvarcasojuridico`, body)
}

export const obterListaDistribuicaoRiscoCasoJuridicoPorRiscoFase = (idCaso: number, idRiscoFase: number) => {
    return privateGet<IObterListaDistribuicaoRiscoCasoJuridicoPorRiscoFaseResponse[]>(`${baseApiPath}/obterlistadistribuicaoriscocasojuridicoporriscofase`, { idCaso, idRiscoFase })
}

export const salvarDistribuicaoCasoJuridicoPorRiscoFase = (body: ISalvarDistribuicaoCasoJuridicoPorRiscoFase) => {
    return privatePost<string>(`${baseApiPath}/salvardistribuicaocasojuridicoporriscofase`, body)
}

export const obterListaRiscoCasoJuridicoPorFase = async (idCaso: number) => {
    let res = await privateGet<IObterListaRiscoCasoJuridicoPorFaseResponse[]>(`${baseApiPath}/obterlistariscocasojuridicoporfase`, { idCaso })

    let handler = res.map((item: IObterListaRiscoCasoJuridicoPorFaseResponse) => {
        return {
            ...item,
            data: convertDate(item.data)
        }
    })

    return handler
}

export const obterListaRiscoCasoJuridicoPorExito = (idCaso: number, idRiscoFase: number, idPessoa: number) => {
    return privateGet<IObterListaRiscoCasoJuridicoPorExitoResponse[]>(`${baseApiPath}/obterlistariscocasojuridicoporexito`, { idCaso, idRiscoFase, idPessoa })
}


export const obterListaRiscoCasoJuridico = (idCaso: number, idRiscoFase: number, idPessoa: number, idTipoConsultaRiscoCasoJuridico: number) => {
    return privateGet<IObterListaRiscoCasoJuridicoResponse[]>(`${baseApiPath}/obterlistariscocasojuridico`, {
        idCaso,
        idRiscoFase,
        idPessoa,
        idTipoConsultaRiscoCasoJuridico
    })
}

export const salvarLancamentoRiscoCasoJuridicoPorRiscoFase = (body: ISalvarLancamentoRiscoCasoJuridicoPorRiscoFase[]) => {
    return privatePost<string>(`${baseApiPath}/salvarlancamentoriscocasojuridicoporriscofase`, body)
}

export const obterListaNovaFasePorCasoJuridico = (idCaso: number) => {
    return privateGet<IObterListaNovaFasePorCasoJuridicoResponse[]>(`${baseApiPath}/obterlistanovafaseporcasojuridico`, { idCaso })
}

export const obterProjecaoCasoJuridico = async (idCaso: number) => {
    let res = await privateGet<any>(`${baseApiPath}/obterprojecaocasojuridico`, { idCaso })
    return res
}

export const obterListaJustificativaCasoJuridicoPorRiscoFase = async (idCaso: number, idRiscoFase: number) => {
    let res = await privateGet<IObterListaJustificativaCasoJuridicoPorRiscoFaseResponse[]>(`${baseApiPath}/obterlistajustificativacasojuridicoporriscofase`, {idCaso, idRiscoFase})

    let handler = res.map((item: IObterListaJustificativaCasoJuridicoPorRiscoFaseResponse) => {
        return {
            ...item,
            dataJustificativa: convertDate(item.dataJustificativa)
        }
    })

    return handler
}

export const salvarJustificativaCasoJuridicoPorRiscoFase = (body: ISalvarJustificativaCasoJuridicoPorRiscoFase) => {
    return privatePost<string>(`${baseApiPath}/salvarjustificativacasojuridicoporriscofase`, body)
}

export const alterarRiscoFaseProvisaoCasoJuridico = (body: IAlterarRiscoFasePRovisaoCasoJuridico) => {
    return privatePut<string>(`${baseApiPath}/alterarriscofaseprovisaocasojuridico`, body)
}

export const obterListaLancamentoRiscoCasoJuridico = (idCaso: number) => {
    return privateGet<IObterListaLancamentoRiscoCasoJuridicoPorRiscoFaseResponse[]>(`${baseApiPath}/obterlistalancamentoriscocasojuridico`, { idCaso })
}

export const obterListaParteContrariaCasoJuridico = (idCaso: number) => {
    return privateGet<IObterListaParteContrariaCasoJuridicoResponse[]>(`${baseApiPath}/obterlistapartecontrariacasojuridico`, { idCaso })
}

export const downloadDocumentoCalculo = (idCaso: number, fileName: string) => {
    return privateGetFile<any>(`${baseApiPath}/downloaddocumentocalculo`, { idCaso, fileName })
}