import { privateDelete } from '../../../methods/delete';
import { privateGet } from '../../../methods/get';
import { privatePost } from '../../../methods/post';
import { privatePut } from '../../../methods/put';
import { IAdicionarRiscoTitulo, IAlterarRiscoTitulo, IExcluirRiscoTitulo, IObterListaRiscoTituloResponse } from '../../../models/Configuracoes/RiscoTitulo';
const baseApiPath = "/configuracao/riscotitulo"

export const adicionarRiscoTitulo = (body: IAdicionarRiscoTitulo) => {
    return privatePost<string>(`${baseApiPath}/adicionar`, body)
}

export const obterListaRiscoTitulo = () => {
    return privateGet<IObterListaRiscoTituloResponse[]>(`${baseApiPath}/obterlistariscotitulo`)
}

export const alterarRiscoTitulo = (body: IAlterarRiscoTitulo) => {
    return privatePut<string>(`${baseApiPath}/alterar`, body)
}

export const excluirRiscoTitulo = (body: IExcluirRiscoTitulo) => {
    return privateDelete<string>(`${baseApiPath}/excluir`, body)
}