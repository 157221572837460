import styled from "styled-components";

export const HeaderWrapper = styled.div`
    height: 5rem;
    width: 100%;
    background: #ffffff30;
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    font-size: 2rem;
`

export const LoggedUser = styled.div`
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: #033152;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .2s ease;
    position: relative;

    &:hover {
        background-color: var(--white);
        color: #033152;
    }
`

export const LoggedUserMenuWrapper = styled.div`
    position: absolute;
    bottom: -4rem;
    right: 0;
    min-width: 10rem;
    height: 4rem;
    box-sizing: border-box;
    padding-top: .5rem;
    z-index: 4;

    &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right:8px solid #033152;
        transform: rotate(90deg);
        right: 1.25rem;
        top: -3px;
    }
`

export const LoggedUserMenu = styled.div`
    bottom: 0;
    left: 0;
    width: fit-content;
    background: #033152;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    padding: 1rem;
    cursor: default;
    border-radius: .25rem;
    -webkit-box-shadow: 0px 0px 9px 0px #00000060; 
    box-shadow: 0px 0px 9px 0px #00000060;
`

export const LogoutButton = styled.button`
    height: 2rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--red);
    border-radius: .25rem;
    color: var(--white);
    column-gap: .5rem;
    font-size: 1rem;
    line-height: 1rem;
    border: none;
    outline: none;
    transition: filter .2s ease;


    &:hover {
        filter: brightness(1.1);
    }

    &:active {
        filter: brightness(.9);
    }

`

export const StyledDivider = styled.div`
    height: 2rem;
    width: 1px;
    background-color: var(--white);
    opacity: .2;
`

export const MenuLabel = styled.span`
    font-size: 1rem;
    font-family: "Sora", sans-serif;
    font-weight: 100;
    color: var(--white);
    cursor: text;
`

export const ClientName = styled.span`
    font-size: .8rem;
    padding: .2rem .5rem;
    border-radius: .3rem;
    color: var(--dark-gray);
    background-color: #ffffff90;
`

export const LineContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`