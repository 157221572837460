import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Input from "../Input";
import Select from "../Select";
import * as S from "./style";

interface IModalAction {
    onConfirm: (e?: any) => void;
    onClose: () => void;
    title: string;
    item: item;
    type: "edit" | "delete";
}

interface item {
    id: number;
    descricao?: string;
    isAtivo?: boolean;
    isConsolidado?: boolean;
    nomePerfil?: string;
    isAdmin?: boolean;
    isConfidencial?: boolean;
}

const EditSchema = yup.object({
    // descricao: yup.string().required("Campo obrigatório.")
})

const ModalAction = ({
    onConfirm,
    onClose,
    title,
    item,
    type
}: IModalAction) => {
    const [zoom, setZoom] = useState<boolean>(true)
    const [fade, setFade] = useState<boolean>(true)

    const [newDesc, setNewDesc] = useState<string>(item.descricao ? item.descricao : "")
    const [newStatus, setNewStatus] = useState<string>(item.isAtivo ? "ativo" : "inativo")

    const close = () => {
        setZoom(false)
        setFade(false)
        setTimeout(() => onClose(), 200)
    }

    const confirm = (data: FieldValues) => {
        setZoom(false)
        setFade(false)
        setTimeout(() => {
            if (type === "edit") {
                if (typeof item.isConsolidado !== "undefined") {
                    onConfirm({
                        id: item.id,
                        descricao: data.descricao,
                        isAtivo: data.isAtivo === "Ativo",
                        isConsolidado: data.isConsolidado === "Consolidado"
                    })
                } else if (item.nomePerfil) {
                    onConfirm({
                        id: item.id,
                        nomePerfil: data.nomePerfil,
                        isConfidencial: data.isConfidencial === "Sim",
                        isAdmin: data.isAdmin === "Sim"
                    })
                } else if (typeof item.isAtivo === "undefined") {
                    onConfirm({
                        id: item.id,
                        descricao: data.descricao
                    })
                } else {
                    onConfirm({
                        id: item.id,
                        descricao: data.descricao,
                        isAtivo: data.isAtivo === "Ativo"
                    })
                }
            } else {
                onConfirm()
            }
            onClose()
        }, 200)
    }

    const {
        handleSubmit,
        control,
        reset,
        formState: {
            errors
        }
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(EditSchema)
    })

    useEffect(() => {
        if (type === "edit") {
            if (typeof item.isConsolidado !== "undefined") {
                reset({
                    id: item.id,
                    descricao: item.descricao,
                    isAtivo: item.isAtivo ? "Ativo" : "Inativo",
                    isConsolidado: item.isConsolidado ? "Consolidado" : "Não consolidado"
                })
            } else if (item.nomePerfil) {
                reset({
                    id: item.id,
                    nomePerfil: item.nomePerfil,
                    isConfidencial: item.isConfidencial ? "Sim" : "Não",
                    isAdmin: item.isAdmin ? "Sim" : "Não"
                })
            } else if (typeof item.isAtivo === "undefined") {
                reset({
                    id: item.id,
                    descricao: item.descricao,
                })
            } else {
                reset({
                    id: item.id,
                    descricao: item.descricao,
                    isAtivo: item.isAtivo ? "Ativo" : "Inativo"
                })
            }
        }
    }, [])

    return (
        <S.ModalActionWrapper onClick={close} className={fade ? "fadeIn" : "fadeOut"}>
            <S.ModalActionWindow onClick={(e) => e.stopPropagation()} className={zoom ? "zoomIn" : "zoomOut"}>
                <S.ModalActionHeader>
                    <span>{title}</span>
                    <S.ModalActionCloseButton onClick={close} />
                </S.ModalActionHeader>
                <S.ModalActionBody>
                    {
                        type === "delete" && (
                            <>
                                <span>Tem certeza que deseja excluir o item abaixo?</span>
                                <span>{item.descricao || item.nomePerfil}</span>
                            </>
                        )
                    }
                    {
                        type === "edit" && (
                            <S.ModalForm onSubmit={handleSubmit((data) => confirm(data))} id="modalForm">
                                <Input id={"id"} name={"id"} label="ID" control={control} disabled />
                                {
                                    item.descricao && (
                                        <Input id="descricao" name="descricao" label="Descrição" control={control} error={errors.descricao?.message} />
                                    )
                                }
                                {
                                    item.nomePerfil && (
                                        <Input id="nomePerfil" name="nomePerfil" label="Nome do perfil" control={control} error={errors.nomePerfil?.message} />
                                    )
                                }
                                {
                                    typeof item.isAtivo !== "undefined" && (
                                        <Select
                                            id="isAtivo"
                                            name="isAtivo"
                                            error={errors.isAtivo?.message}
                                            control={control}
                                            label="Status"
                                            options={[
                                                { label: "Ativo", value: "Ativo" },
                                                { label: "Inativo", value: "Inativo" }
                                            ]}
                                        />
                                    )
                                }
                                {
                                    typeof item.isConsolidado !== "undefined" && (
                                        <Select
                                            id="isConsolidado"
                                            name="isConsolidado"
                                            error={errors.isConsolidado?.message}
                                            control={control}
                                            label="Consolidado"
                                            options={[
                                                { label: "Consolidado", value: "Consolidado" },
                                                { label: "Não consolidado", value: "Não consolidado" }
                                            ]}
                                        />
                                    )
                                }
                                {
                                    typeof item.isAdmin !== "undefined" && (
                                        <Select
                                            id="isAdmin"
                                            name="isAdmin"
                                            error={errors.isAdmin?.message}
                                            control={control}
                                            label="Administrador"
                                            options={[
                                                { label: "Sim", value: "Sim" },
                                                { label: "Não", value: "Não" }
                                            ]}
                                        />
                                    )
                                }
                                {
                                    typeof item.isConfidencial !== "undefined" && (
                                        <Select
                                            id="isConfidencial"
                                            name="isConfidencial"
                                            label="Confidencial"
                                            control={control}
                                            error={errors.isConfidencial?.message}
                                            options={[
                                                { label: "Sim", value: "Sim" },
                                                { label: "Não", value: "Não" }
                                            ]}
                                        />
                                    )
                                }
                            </S.ModalForm>
                        )
                    }
                </S.ModalActionBody>
                <S.ModalActionFooter>
                    <S.ModalActionCancelButton onClick={close}>Cancelar</S.ModalActionCancelButton>
                    {
                        type === "delete" ? (
                            <S.ModalActionConfirmButton onClick={confirm}>Confirmar</S.ModalActionConfirmButton>
                        )
                            :
                            (
                                <S.ModalActionConfirmButton type="submit" form="modalForm">Confirmar</S.ModalActionConfirmButton>
                            )
                    }
                </S.ModalActionFooter>
            </S.ModalActionWindow>
        </S.ModalActionWrapper>
    )
}

export default ModalAction