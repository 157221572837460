import BackButton from "../BackButton";
import * as S from "./style";
import { useNavigate } from 'react-router-dom';

interface ITemplate {
    children: JSX.Element | JSX.Element[] | string | any;
    backTo?: string;
}

const Template = ({
    children,
    backTo = "/inicio"
}: ITemplate) => {
    return (
        <S.TemplateWrapper>
            {/* <BackButton backTo={backTo} /> */}
            <S.TemplateContent>
                { children }
            </S.TemplateContent>
        </S.TemplateWrapper>
    )
}

export default Template