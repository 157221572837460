import { privateDelete } from '../../../methods/delete';
import { privateGet } from '../../../methods/get';
import { privatePost } from '../../../methods/post';
import { privatePut } from '../../../methods/put';
import { IQualificacaoAdicionar, IQualificacaoAlterar, IQualificacaoExcluir, IQualificacaoObterListaPessoaQualificacaoResponse } from '../../../models/Pessoa/Qualificacao';
const basePath = "/pessoa/qualificacao"

export const adicionarQualificacaoPessoa = (body: IQualificacaoAdicionar) => {
    return privatePost<string>(`${basePath}/adicionar`, body)
}

export const alterarQualificacaoPessoa = (body: IQualificacaoAlterar) => {
    return privatePut<string>(`${basePath}/alterar`, body)
}

export const excluirQualificacaoPessoa = (body: IQualificacaoExcluir) => {
    return privateDelete<string>(`${basePath}/excluir`, body)
}

export const obterListaPessoaQualificacao = (idTipoSituacao?: number) => {
    return privateGet<IQualificacaoObterListaPessoaQualificacaoResponse[]>(`${basePath}/obterlistapessoaqualificacao?idTipoSituacao=${idTipoSituacao || 3}`)
}