import axios from "axios";
import { toast } from "react-toastify";
import getIdClienteMacdata from "../../utils/getIdClienteMacdata";

const get = async<T>(route: string, body?: any): Promise<T> => {
    return await axios
        .get(`${process.env.REACT_APP_BACKEND_API}${route}`, body)
        .then(r => r.data)
        .catch((err) => {
            toast.dismiss()
            toast.error(err.response.data.detail, { toastId: "getErrorToast" })
        })
}

export const getForPass = async<T>(route: string, body?: any): Promise<T> => {
    return await axios
        .get(`${process.env.REACT_APP_BACKEND_API}${route}`, body)
        .then(r => r.data)
        .catch((err) => {
            toast.dismiss()
            toast.error(err.response.data.detail, { toastId: "getErrorToast" })
        })
}


export const privateGet = async<T>(route: string, body?: any): Promise<T> => {

    const user = localStorage.getItem("user")

    const token = user && JSON.parse(user).token

    const idClienteMacdata = getIdClienteMacdata();

    const privateAxios = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        headers: {
            'Authorization': `Bearer ${token}`,
            idClienteMacdata: idClienteMacdata

        }
    })

    return await privateAxios
        .get(`${process.env.REACT_APP_BACKEND_API}${route}`, { params: body })
        .then(r => {
            if (r.status === 204) {
                return null
            }
            return r.data
        })
        .catch((err) => {
            if (
                (err.response.data.status === 401) &&
                (err.response.data.title === "Unauthorized")
            ) {
                localStorage.removeItem("user")
                localStorage.removeItem("client")
                localStorage.removeItem("clientInfo")
                window.location.pathname = ""
            }
            toast.dismiss()
            toast.error(err.response.data.detail, { toastId: "getErrorToast" })
        })
}

export const privateGetFile = async<T>(route: string, body?: any): Promise<T> => {

    const user = localStorage.getItem("user")
    const token = user && JSON.parse(user).token

    const privateAxios = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        headers: {
            'Accept': 'application/pdf, application/vnd.ms-excel, application/msword',
            'Authorization': `Bearer ${token}`,
            idClienteMacdata: getIdClienteMacdata()

        }
    })

    return await privateAxios
        .get(`${process.env.REACT_APP_BACKEND_API}${route}`, {
            params: body,
            responseType: 'blob'
        })
        .then(r => {
            if (r.status === 204) {
                return null
            }
            return r.data
        })
        .catch((err) => {
            if (
                (err.response.data.status === 401) &&
                (err.response.data.title === "Unauthorized")
            ) {
                localStorage.removeItem("user")
                localStorage.removeItem("client")
                localStorage.removeItem("clientInfo")
                window.location.pathname = ""
            }
            toast.dismiss()
            toast.error(err.response.data.detail, { toastId: "getErrorToast" })
        })
}

export default get