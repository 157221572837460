import styled from "styled-components";

export const PeopleSearchWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 2.3125rem);
    padding: 5rem;
`

export const PeopleSearchContent = styled.form`
    width: 100%;
    padding: 0 3rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: relative;
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
`

interface IGridItem {
    cols: number;
}

export const GridItem = styled.div<IGridItem>`
    display: grid;
    grid-template-columns: repeat(${props => props.cols},1fr);
    width: ${props => `${props.cols}fr`};
    column-gap: 3rem;
`

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    margin: 1rem 0;
`