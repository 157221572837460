import { create } from 'zustand';
import { obterListaMenuPermissao } from '../services/api/Menu';

export interface MenuOpcao {
  idMenu: number
  descricaoMenu: string
  rota: string
  icone: any
  ordemMenu: number
  submenus: any[]
  permiteInserir: boolean
  permiteAlterar: boolean
  permiteExcluir: boolean
  permiteVisualizar: boolean
  permiteImprimir: boolean
}

export interface MenuPermissao {
  idAgrupamento: number
  nomeAgrupamento: string
  ordemAgrupamento: number
  icone: string
  listaMenu: MenuOpcao[]
}

const defaultPermission: MenuPermissao[] = [
  {
    idAgrupamento: 1,
    nomeAgrupamento: "",
    ordemAgrupamento: 1,
    icone: "",
    listaMenu: [
      {
        idMenu: 1,
        descricaoMenu: "",
        rota: "",
        icone: "",
        ordemMenu: 1,
        submenus: [],
        permiteInserir: false,
        permiteAlterar: false,
        permiteExcluir: false,
        permiteVisualizar: false,
        permiteImprimir: false
      }
    ]
  }
]

type PermissionStore = {
  permissions: MenuPermissao[]
  getPermissions: () => Promise<void>
  findPermission: (idMenu: MenuOpcao['idMenu']) => MenuOpcao
  removePermissions: () => void;
}

export const usePermissionsStore = create<PermissionStore>((set, get) => {
  return {
    permissions: defaultPermission,
    getPermissions: async () => {
      const res = await obterListaMenuPermissao()
      set(() => ({ permissions: res }));
    },
    findPermission: (idMenu) => {

      const permissions = get().permissions;

      if (!permissions) {
        get().getPermissions();
      }

      let foundMenuOpcao: MenuOpcao | undefined;
      permissions.find(permissao => {
        const menuOpcao = permissao.listaMenu.find(menu => menu.idMenu === idMenu);
        if (menuOpcao) {
          foundMenuOpcao = menuOpcao;
          return true;
        }
        return false;
      });
      return foundMenuOpcao;
    },
    removePermissions: () => {
      set(() => ({ permissions: undefined }));
    }
  }
})