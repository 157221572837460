import { Control, Controller, FieldValues } from "react-hook-form";
import * as S from "./style"
import { InputHTMLAttributes } from 'react';

import { NumericFormat } from "react-number-format"

const CurrencyInpt = NumericFormat as any

interface ICurrencyInput extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    id: string;
    control: Control<FieldValues, object>;
    name: string;
    type?: string;
    error?: any;
}

const CurrencyInput = ({
    label,
    id,
    control,
    name,
    type = "text",
    error,
    ...props
}: ICurrencyInput) => {
    return (
        <S.CurrencyInputContainer>
            {
                label && (
                    <S.StyledCurrencyLabel htmlFor={id}>{label}</S.StyledCurrencyLabel>
                )
            }
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <CurrencyInpt
                        customInput={S.StyledCurrencyInput}
                        prefix="R$ "
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        allowNegative={false}
                        id={id}
                        error={error}
                        name={name}
                        placeholder={props.placeholder}
                        value={field.value}
                        onChange={field.onChange}
                        {...props}
                    />
                )}
            />
            {
                error && (
                    <S.StyledCurrencyError>
                        {error}
                    </S.StyledCurrencyError>
                )
            }
        </S.CurrencyInputContainer>
    )
}

export default CurrencyInput