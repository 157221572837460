import { Control, FieldValues } from "react-hook-form";
import * as S from "./style"
import { useState } from 'react';

interface IInput {
    type?: string;
    id: string;
    name: string;
    error?: string | any;
    control: any;
    label?: string;
    required?: boolean;
    [key: string]: any;
}

export const Input = ({
    type = "text",
    id,
    name,
    error,
    control,
    label,
    required = false,
    ...rest
}: IInput) => {
    const [passType, setPassType] = useState<boolean>(true)
    return (
        <S.InputContainer>
            {
                label && (
                    <S.StyledLabel htmlFor={id}>{label}</S.StyledLabel>
                )
            }
            {
                type === "password" ?
                    (
                        <S.WithEye>
                            <S.StyledInput id={id} type={passType ? "password" : "text"} {...control?.register(name)} error={error} {...rest} />
                            <S.EyeButton active={passType} type="button" onClick={() => setPassType(!passType)} />
                        </S.WithEye>
                    ) :
                    (
                        <S.StyledInput id={id} type={type} {...control?.register(name)} error={error} {...rest} />
                    )
            }
            {/* {
                type === "password" && (
                    <S.EyeButton wLabel={label !== undefined} type="button">eye</S.EyeButton>
                )
            } */}
            {
                error && (
                    <S.StyledError>
                        {error}
                    </S.StyledError>
                )
            }
        </S.InputContainer>
    )
}

export default Input