import styled from "styled-components";

export const SideBarWrapper = styled.div`
    min-width: 14rem;
    /* background-color: #033152; */
    background-color: #011a2b99;
    border-right: 2px solid var(--black-70);
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    user-select: none;
    max-width: 13.875rem;
`

export const SideBarItem = styled.button`
    border-top: 1px solid var(--black-70);
    border-bottom: 1px solid var(--black-70);
    cursor: pointer;
    padding: 1rem;
    transition: background-color .2s ease;
    color: var(--white);
    font-size: .8rem;
    background-color: transparent;
    text-align: left;

    :hover {
        background-color: #ffffff10;
    }

    :not(:last-child) {
        border-bottom: none;
    }

    &:focus-visible {
        outline: 2px solid var(--light-blue);
    }
`
interface ISideBarSubItem {
    active?: boolean;
}

export const SideBarSubItem = styled.button<ISideBarSubItem>`
    background-color: #00000040;
    /* background-color: ${props => props.active ? "#ffffff10" : "#00000040"}; */
    color: var(--white);
    font-weight: ${props => props.active ? "700" : "400"};
    font-size: .8rem;
    padding: .5rem 2rem .5rem 2rem;
    cursor: pointer;
    transition: background-color .2s ease;
    width: 100%;
    text-align: left;
    position: relative;

    :after {
        content: "►";
        display: ${props => props.active ? "flex" : "none"};
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: -.5rem;
        width: fit-content;
        height: 100%;
        align-items: center;
        color: var(--white);
        font-size: .5rem;
    }

    :hover {
        background-color: #00000070;
    }

    &:focus-visible {
        outline: 2px solid var(--light-blue);
    }

    :not(:last-child) {
        border-bottom: 1px solid #00000050;
    }
`