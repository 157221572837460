import * as S from "./style"
import { getAppVersion } from "../../utils"

const Footer = () => {
    return (
        <S.FooterWrapper>
            © 2023 Macdata - Sistema de Gestão de Provisionamento e Riscos - <b>{getAppVersion()}</b>
        </S.FooterWrapper>
    )
}

export default Footer