import { Trash } from "phosphor-react";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import ActionButton from "../../../../components/ActionButton";
import Button from "../../../../components/Button";
import ItemsList, { HeaderList, ListItem } from "../../../../components/ItemsList";
import Select from "../../../../components/Select";
import { excluirObjetoCasoJuridico, obterListaLancamentoRiscoCasoJuridico, obterListaObjetoCasoJuridico, obterListaRiscoFasePorCasoJuridico, salvarLancamentoRiscoCasoJuridicoPorRiscoFase } from "../../../../services/api/CasoJuridico";
import { obterListaRiscoSubtitulo } from "../../../../services/api/Configuracoes/RiscoSubtitulo";
import { obterListaRiscoTitulo } from "../../../../services/api/Configuracoes/RiscoTitulo";
import { IObterListaLancamentoRiscoCasoJuridicoPorRiscoFaseResponse } from "../../../../services/models/CasoJuridico";
import { IObterListaRiscoSubtituloResponse } from "../../../../services/models/Configuracoes/RiscoSubtitulo";
import { IObterListaRiscoTituloResponse } from "../../../../services/models/Configuracoes/RiscoTitulo";

import * as S from "../../style";
import { obterListaLancamentoRiscoCasoJuridicoPorRiscoFase } from '../../../../services/api/CasoJuridico/index';
import { usePermissionsStore } from "../../../../stores/PermissionsStore";

interface IObjetos {
    storedId: string;
    listaObjetosAdicionados: any;
    setListaObjetosAdicionados: React.Dispatch<any>;
    caseIsSctr: boolean;
}

const Objetos = ({
    storedId,
    listaObjetosAdicionados,
    setListaObjetosAdicionados,
    caseIsSctr
}: IObjetos) => {
    const [listaRiscoTitulo, setListaRiscoTitulo] = useState<IObterListaRiscoTituloResponse[] | null>(null)
    const [listaRiscoSubtitulo, setListaRiscoSubtitulo] = useState<IObterListaRiscoSubtituloResponse[] | null>(null)
    const [listaObjetos, setListaObjetos] = useState<any>(null)

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isValid
        },
        getValues,
        setValue,
        register,
        getFieldState,
        reset,
        watch,
        setError
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    })

    useEffect(() => {
        if (storedId) {
            ObterListaObjetoCasoJuridico(+storedId)
        }

        ObterListaRiscoTitulo()
        ObterListaRiscoSubtitulo()
    }, [storedId])

    useEffect(() => {
        if ((listaObjetos) && (listaObjetos.length > 0)) {
            localStorage.setItem("objetos", JSON.stringify(listaObjetos))
        }
    }, [listaObjetos])

    const ObterListaRiscoTitulo = async () => {
        let res = await obterListaRiscoTitulo()
        setListaRiscoTitulo(res)
    }

    const ObterTituloPorId = (idTitulo: number) => {
        if (!listaRiscoTitulo) return "N/A"
        let listaTitulo = [...listaRiscoTitulo]
        return listaTitulo.filter((item: IObterListaRiscoTituloResponse) => {
            return item.id === idTitulo
        })[0].descricao
    }

    const ObterListaRiscoSubtitulo = async () => {
        let res = await obterListaRiscoSubtitulo()
        setListaRiscoSubtitulo(res)
    }

    const ObterSubtituloPorId = (idSubtitulo: number) => {
        if (!listaRiscoSubtitulo) return "N/A"
        let listaSubtitulo = listaRiscoSubtitulo
        return listaSubtitulo.filter((item: IObterListaRiscoSubtituloResponse) => {
            return item.id === idSubtitulo
        })[0].descricao
    }

    const LimparSelecaoObjetos = () => {
        setValue("tituloObjetos", "Selecione")
        setValue("subtituloObjetos", "Selecione")
    }

    const AdicionarObjetoLista = async () => {
        let idRiscoTituloObj = watch("tituloObjetos")
        let idRiscoSubtituloObj = watch("subtituloObjetos")

        let newObj = {
            idRiscoTitulo: +idRiscoTituloObj,
            descricaoRiscoTitulo: ObterTituloPorId(+idRiscoTituloObj),
            idRiscoSubtitulo: +idRiscoSubtituloObj,
            descricaoRiscoSubtitulo: ObterSubtituloPorId(+idRiscoSubtituloObj)
        }

        if (ObjetoJaExiste(newObj)) {
            toast.info("Objeto já existe.")
        } else {
            let newLista = listaObjetos ? [
                ...listaObjetos,
                newObj
            ] : [newObj]

            let listaObjetosAdicionadosAux = [...listaObjetosAdicionados, newObj]

            AdicionaObjetosNosLancamentos(newObj)

            setListaObjetos(newLista)
            localStorage.setItem("objetos", JSON.stringify(newLista))
            setListaObjetosAdicionados([...listaObjetosAdicionadosAux])
            LimparSelecaoObjetos()
        }
    }

    const getNow = () => {
        let dt = new Date
        let day = dt.getDate() > 10 ? dt.getDate() : `0${dt.getDate()}`
        let month = dt.getMonth() + 1 > 10 ? dt.getMonth() + 1 : `0${dt.getMonth() + 1}`
        let year = dt.getFullYear()
        return `${day}/${month}/${year}`
    }

    const AdicionaObjetosNosLancamentos = async (newObj: any) => {

        let novoLancamentoComObjeto = {
            id: 0,
            idCaso: +storedId,
            idPessoa: 0,
            idRiscoFase: 0,
            idRiscoTitulo: newObj.idRiscoTitulo,
            idRiscoSubtitulo: newObj.idRiscoSubtitulo,
            dataPrincipal: getNow(),
            valorPrincipal: 0,
            valorJurosAcumulado: 0,
            taxaJurosMora: 0,
            isUtilizaSelic: false,
            idIndice: 0
        }

        let listaFasesDoCaso: any = await obterListaRiscoFasePorCasoJuridico(+storedId)

        let lancamentos: any = await obterListaLancamentoRiscoCasoJuridico(+storedId)

        let listaFasesComIdPessoa = listaFasesDoCaso.map((item: any) => {
            return {
                id: item.id,
                idPessoa: lancamentos.filter((itm: any) => {
                    return itm.idRiscoFase = item.id
                })[0].idParteContraria
            }
        })

        let listaEditada: any = []

        listaFasesComIdPessoa.forEach((itemFase: any) => {
            listaEditada.push({
                ...novoLancamentoComObjeto,
                idRiscoFase: itemFase.id,
                idPessoa: itemFase.idPessoa
            })
        })

        await salvarLancamentoRiscoCasoJuridicoPorRiscoFase(listaEditada)
    }

    const RemoverObjetolista = (index: number) => {
        if (!storedId) return

        let listaObjetosAux = [...listaObjetos]
        let listaObjetosAdicionadosAux = [...listaObjetosAdicionados]

        if (listaObjetosAdicionadosAux.includes(listaObjetosAux[index])) {
            listaObjetosAdicionadosAux.splice(
                listaObjetosAdicionados.indexOf(listaObjetosAux[index])
                , 1)
            listaObjetosAux.splice(
                index
                , 1)
        } else {
            ExcluirObjetoCasoJuridico(+storedId, listaObjetos[index].idRiscoTitulo, listaObjetos[index].idRiscoSubtitulo)
            listaObjetosAdicionadosAux.splice(
                listaObjetosAdicionados.indexOf(listaObjetosAux[index])
                , 1)
            setTimeout(() => {
                ObterListaObjetoCasoJuridico(+storedId)
            }, 2000)
        }
        setListaObjetosAdicionados([...listaObjetosAdicionadosAux])
        setListaObjetos([...listaObjetosAux])
        localStorage.setItem("objetos", JSON.stringify([...listaObjetosAux]))
    }

    const ObjetoJaExiste = (obj: any) => {
        if (listaObjetos) {
            let t = listaObjetos.filter((item: any) => {
                return ((item.idRiscoTitulo === obj.idRiscoTitulo) && (item.idRiscoSubtitulo === obj.idRiscoSubtitulo))
            })
            return t.length > 0
        } else {
            return false
        }
    }

    const isDisabledAdicionarObjeto = () => {
        let tituloObjeto = watch("tituloObjetos") !== "Selecione"
        let subtituloObjeto = watch("subtituloObjetos") !== "Selecione"
        return !(tituloObjeto && subtituloObjeto)
    }

    const ObterListaObjetoCasoJuridico = async (idCaso: number) => {
        let res = await obterListaObjetoCasoJuridico(idCaso)
        setListaObjetos([...res, ...listaObjetosAdicionados])
    }

    const ExcluirObjetoCasoJuridico = async (idCaso: number, idRiscoTitulo: number, idRiscoSubtitulo: number) => {
        await excluirObjetoCasoJuridico(idCaso, idRiscoTitulo, idRiscoSubtitulo)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(17);

    const {
        permiteAlterar,
        permiteInserir,
        permiteExcluir
    } = permissions

    // const permiteAlterar = true
    // const permiteExcluir = true
    // const permiteInserir = true

    return (
        <>
            <S.GridItem cols={2}>
                {
                    listaRiscoTitulo && !caseIsSctr && (
                        <Select
                            id="tituloObjetos"
                            name="tituloObjetos"
                            label="Títulos"
                            control={control}
                            disabled={!(permiteAlterar || permiteInserir)}
                            options={
                                listaRiscoTitulo.filter((item: any) => {
                                    return item.isAtivo
                                }).map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    )
                }
                {
                    listaRiscoSubtitulo && !caseIsSctr && (
                        <Select
                            id="subtituloObjetos"
                            name="subtituloObjetos"
                            label="Subtítulo"
                            control={control}
                            disabled={!(permiteAlterar || permiteInserir)}
                            options={
                                listaRiscoSubtitulo.filter((item: any) => {
                                    return item.isAtivo
                                }).map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    )
                }
            </S.GridItem>
            {
                (permiteAlterar || permiteInserir) &&
                !caseIsSctr && (
                    <S.GridItem cols={4}>
                        <Button onClick={LimparSelecaoObjetos}>Limpar</Button>
                        <Button onClick={AdicionarObjetoLista} disabled={isDisabledAdicionarObjeto()}>Adicionar</Button>
                    </S.GridItem>
                )
            }
            {
                listaObjetos && listaObjetos.length > 0 && (
                    <ItemsList>
                        <HeaderList items={
                            caseIsSctr ? [
                                "Título",
                                "Subtítulo"
                            ] : [
                                "Título",
                                "Subtítulo",
                                ...(permiteExcluir ? ["Remover"] : [])
                            ]} />
                        <>
                            {
                                listaObjetos.map((item: any, index: any) => (
                                    <ListItem index={index}>
                                        <div>{item.descricaoRiscoTitulo}</div>
                                        <div>{item.descricaoRiscoSubtitulo}</div>
                                        {
                                            permiteExcluir &&
                                            !caseIsSctr ? (
                                                <div>
                                                    <ActionButton act={"delete"} onClick={() => RemoverObjetolista(index)}>
                                                        <Trash />
                                                    </ActionButton>
                                                </div>
                                            ) : <></>
                                        }
                                    </ListItem>
                                ))
                            }
                        </>
                    </ItemsList>
                )
            }
        </>
    )
}

export default Objetos