import { privateDelete } from '../../../methods/delete';
import { privateGet } from '../../../methods/get';
import { privatePost } from '../../../methods/post';
import { privatePut } from '../../../methods/put';
import { IAdicionarRiscoGrupoTitulo, IAlterarRiscoGrupoTitulo, IExcluirRiscoGrupoTitulo, IObterListaRiscoGrupoTituloResponse } from '../../../models/Configuracoes/RiscoGrupoTitulo/index';
const baseApiPath = "/configuracao/riscogrupotitulo"

export const adicionarRiscoGrupoTitulo = (body: IAdicionarRiscoGrupoTitulo) => {
    return privatePost<string>(`${baseApiPath}/adicionar`, body)
}

export const obterListaRiscoGrupoTitulo = (idTipoSituacao?: number) => {
    return privateGet<IObterListaRiscoGrupoTituloResponse>(`${baseApiPath}/obterlistariscogrupotitulo?idTipoSituacao=${idTipoSituacao || 3}`)
}

export const alterarRiscoGrupoTitulo = (body: IAlterarRiscoGrupoTitulo) => {
    return privatePut<string>(`${baseApiPath}/alterar`, body)
}

export const excluirRiscoGrupoTitulo = (body: IExcluirRiscoGrupoTitulo) => {
    return privateDelete<string>(`${baseApiPath}/excluir`, body)
}