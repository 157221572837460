import styled from "styled-components";

export const HomeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 7.3125rem);
    flex-direction: column;
    row-gap: 1rem;
    color: var(--white);

    > button {
        width: 20rem;
    }
`