import { privateDelete } from '../../../methods/delete';
import { privateGet } from '../../../methods/get';
import { privatePost } from '../../../methods/post';
import { privatePut } from '../../../methods/put';
import { IAdicionarRiscoMotivo, IAlterarRiscoMotivo, IExcluirRiscoMotivo, IObterListaRiscoMotivoResponse } from '../../../models/Configuracoes/RiscoMotivo/index';
const basePath = "/configuracao/riscomotivointerrupcaoprojecao"

export const adicionarRiscoMotivo = (body: IAdicionarRiscoMotivo) => {
    return privatePost<string>(`${basePath}/adicionar`, body)
}

export const obterListaRiscoMotivo = () => {
    return privateGet<IObterListaRiscoMotivoResponse>(`${basePath}/obterlistariscomotivointerrupcaoprojecao`)
}

export const alterarRiscoMotivo = (body: IAlterarRiscoMotivo) => {
    return privatePut<string>(`${basePath}/alterar`, body)
}

export const excluirRiscoMotivo = (body: IExcluirRiscoMotivo) => {
    return privateDelete<string>(`${basePath}/excluir`, body)
}