import styled from "styled-components";

export const PopupWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1;
    background-color: #00000040;
    top: 0;
    left: 0;
`

interface IPopupWindow {
    minWidth?: string;
}

export const PopupWindow = styled.div<IPopupWindow>`
    /* height: 20rem; */
    /* min-width: 20rem; */
    min-width: ${props => props.minWidth};
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: var(--white);
    border: 1px solid var(--dark-gray);
    padding: 1rem 0;
    border-radius: .25rem;
    -webkit-box-shadow: 0px 0px 9px 0px #00000060; 
    box-shadow: 0px 0px 9px 0px #00000060;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const PopupContent = styled.div`
    width: 100%;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const PopupButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0 1rem;
`