import { privateDelete } from '../../../methods/delete';
import { privateGet } from '../../../methods/get';
import { privatePost } from '../../../methods/post';
import { privatePut } from '../../../methods/put';
import { IAdicionarMotivoBaixa, IAlterarMotivoBaixa, IExcluirMotivoBaixa, IObterListaMotivoBaixaResponse } from '../../../models/Configuracoes/MotivoBaixa';
const basePath = "/configuracao/motivobaixa"

export const adicionarMotivoBaixa = (body: IAdicionarMotivoBaixa) => {
    return privatePost<string>(`${basePath}/adicionar`, body)
}

export const obterListaMotivoBaixa = (idTipoSituação?: number) => {
    return privateGet<IObterListaMotivoBaixaResponse>(`${basePath}/obterlistacasomotivobaixa?idTipoSituacao=${idTipoSituação || 3}`)
}

export const alterarMotivoBaixa = (body: IAlterarMotivoBaixa) => {
    return privatePut<string>(`${basePath}/alterar`, body)
}

export const excluirMotivoBaixa = (body: IExcluirMotivoBaixa) => {
    return privateDelete<string>(`${basePath}/excluir`, body)
}