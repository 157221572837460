import * as S from "./style"

interface IButton {
    children: JSX.Element | JSX.Element[] | string | React.ReactNode;
    type?: "button" | "submit" | "reset";
    onClick?: () => void;
    id?: string;
    disabled?: boolean;
    [key: string]: any; 
}

const Button = ({
    children,
    type = "button",
    onClick,
    id,
    disabled = false,
    ...rest
}: IButton) => {
    return (
        <S.StyledButton type={type} onClick={onClick} id={id} disabled={disabled} {...rest}>
            {children}
        </S.StyledButton>
    )
}

export default Button