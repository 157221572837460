import packageJson from "../../package.json"

export function convertDateAndTime(datetime: string) {
    let parsedDate = datetime.substring(0, 10).split("-")
    let parsedTime = datetime.substring(11, 19)

    let mountedDateAndTime = `${parsedDate[2]}/${parsedDate[1]}/${parsedDate[0]} - ${parsedTime}`

    return mountedDateAndTime
}

export function convertDate(datetime: string) {
    let parsedDate = datetime.substring(0, 10).split("-")

    let mountedDate = `${parsedDate[2]}/${parsedDate[1]}/${parsedDate[0]}`

    return mountedDate
}

export function convertDateWithTime(datetime: string) {
    let splitted = datetime.split("T")
    let parsedDate = convertDate(splitted[0])
    let parsedTime = splitted[1].substring(0, 5)

    return `${parsedDate} ${parsedTime}`
}

export function convertCurrency(value: number) {
    let convertedCurrency = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
    })
    return convertedCurrency
}

export function convertToNumber(value: string) {
    if (!value) return 0;
    if (typeof value === "number") return value
    let convertedNumber = value.replaceAll("R$ ", "").replaceAll(".", "").replaceAll(",", ".")
    return parseFloat(convertedNumber)
}

export function getAppVersion() {
    return `v${packageJson.version}`
}