import styled from "styled-components";
import Eye from "../../assets/svg/eye.svg"
import EyeOff from "../../assets/svg/eye-off.svg"

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .2rem;
    position: relative;
`

export const StyledLabel = styled.label`
    font-size: .8rem;
    font-weight: 700;
    color: var(--dark-gray);
`

export const StyledError = styled.span`
    color: var(--red);
    font-size: .7rem;
    font-weight: 600;
`

interface IStyledInput {
    error?: boolean
}

export const StyledInput = styled.input<IStyledInput>`
    width: 100%;
    border: ${props => props.error ? "1px solid var(--red)" : "1px solid var(--dark-gray)"};
    padding: .2rem;
    border-radius: .25rem;
    font-size: .8rem;
    /* min-width: 14rem; */

    &[type="date"] {
        padding: .175rem;
    }

    &:focus {
        border-color: var(${props => props.error ? "--red" : "--light-blue"});
        outline: 2px solid var(${props => props.error ? "--red" : "--light-blue"});
    }
`

interface EyeButton {
    wLabel: boolean;
}


export const WithEye = styled.div`
    width: 100%;
    position: relative;
`
interface IEyeButton {
    active: boolean;
}

export const EyeButton = styled.button<IEyeButton>`
    position: absolute;
    right: .2rem;
    transform: translateY(-50%);
    top: 50%;
    width: 1.2rem;
    height: 1rem;
    border-radius: .2rem;
    background-color: transparent;
    background-image: url(${props => props.active ? Eye : EyeOff});
    background-size: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: filter .2s ease, background-image .3s linear;

    :hover {
        filter: brightness(2);
    }

    :focus-visible {
        outline: 2px solid var(--light-blue);
    }
`