import { yupResolver } from "@hookform/resolvers/yup"
import { useContext, useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from "yup"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import ItemsList, { HeaderList, ListItem } from "../../../components/ItemsList"
import Loader from "../../../components/Loader"
import MaskedInput from "../../../components/MaskInput"
import PageTitle from "../../../components/PageTitle"
import Paginator from "../../../components/Paginator"
import Template from "../../../components/Template"
import { obterListaPaginadaPesquisaPessoa } from '../../../services/api/Pessoa/index'
import { IPessoaObterListaPaginadaPesquisaPessoa, IPessoaObterListaPaginadaPesquisaPessoaResponse } from '../../../services/models/Pessoa/index'
import * as S from "./style"
import { usePermissionsStore } from "../../../stores/PermissionsStore"

const SearchSchema = yup.object({
    cnpjCpf: yup.string(),
    nome: yup.string().max(100, "Limite de 100 caracteres.")
})

const PeopleSearch = () => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [peopleResponse, setPeopleResponse] = useState<IPessoaObterListaPaginadaPesquisaPessoaResponse | null>(null)

    const navigate = useNavigate()

    useEffect(() => {
        triggerSearch({
            tipoSituacao: "Ativos"
        })
    }, [])

    const {
        handleSubmit,
        control,
        watch,
        getValues,
        formState: {
            errors,
            isValid
        },
        reset
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(SearchSchema)
    })

    const triggerSearch = async (data: any, page?: any) => {
        !page && setCurrentPage(1)
        setLoading(true)
        const userInfo = localStorage.getItem("user")
        const token = userInfo && JSON.parse(userInfo).token

        let body: IPessoaObterListaPaginadaPesquisaPessoa = {
            limite: 5,
            pagina: page ? page : 1,
            tipoSituacao: "Ambos",
            ...data
        }
        let res = await obterListaPaginadaPesquisaPessoa(body)
        setPeopleResponse(res)
        setLoading(false)
    }

    const goDetail = (idPessoa: string) => {
        searchParams.set("idPessoa", idPessoa)
        navigate({
            pathname: "/pessoas/dados-pessoa",
            search: `?idPessoa=${idPessoa}`
        })
    }

    useEffect(() => {
        triggerSearch(getValues(), currentPage)
    }, [currentPage])

    const limpar = () => {
        reset({
            sequencial: "",
            nome: "",
            cnpjCpf: "",
            rg: "",
            tipoSituacao: "Ativos"
        })
        triggerSearch(getValues(), currentPage)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(14);

    const {
        permiteInserir
    } = permissions

    return (
        <Template>
            <PageTitle>
                Pesquisa de Pessoas
            </PageTitle>
            <S.PeopleSearchContent id="peopleSearch" onSubmit={handleSubmit((data) => triggerSearch(data))}>
                {
                    permiteInserir && (
                        <S.ButtonsWrapper>
                            <Button onClick={() => navigate("/pessoas/dados-pessoa")}>Novo</Button>
                        </S.ButtonsWrapper>
                    )
                }
                <S.GridItem cols={3}>
                    <Input id="nome" name="nome" label="Nome" control={control} error={errors.nome?.message} />
                    <MaskedInput id="cnpjCpf" name="cnpjCpf" label="CPF" control={control} mask={"999.999.999-99"} />
                </S.GridItem>
                <S.ButtonsWrapper>
                    <Button onClick={() => limpar()}>Limpar</Button>
                    <Button type="submit" form="peopleSearch" disabled={!isValid}>Pesquisar</Button>
                </S.ButtonsWrapper>
                {
                    peopleResponse &&
                    peopleResponse.itens.length > 0 &&
                    (
                        <ItemsList>
                            <HeaderList
                                items={["Sequencial", "CNPJ/CPF", "NOME"]}
                                big={[2]}
                            />
                            <>
                                {
                                    peopleResponse.itens.map((item: any, index: any) => {
                                        return (
                                            <ListItem key={index} onClick={() => goDetail(item.id)}>
                                                <div>{item.sequencial || "N/A"}</div>
                                                <div>{item.cnpjCpf || "N/A"}</div>
                                                <div className="big">{item.nome || "N/A"}</div>
                                            </ListItem>
                                        )
                                    })
                                }
                                <br />
                                <Paginator
                                    paginaAtual={currentPage}
                                    totalItens={peopleResponse.totalItens}
                                    totalPaginas={peopleResponse.totalPaginas}
                                    first={() => setCurrentPage(1)}
                                    last={() => setCurrentPage(peopleResponse.totalPaginas)}
                                    change={setCurrentPage}
                                />
                            </>
                        </ItemsList>
                    )
                }
                {
                    peopleResponse &&
                    peopleResponse.itens.length < 1 &&
                    (
                        <p>Nenhum resultado.</p>
                    )
                }
            </S.PeopleSearchContent>
            {
                loading &&
                <Loader />
            }
        </Template>
    )
}

export default PeopleSearch