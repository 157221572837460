import { Dispatch, SetStateAction } from "react"
import * as S from "./style"

interface IPeopleDataHandler {
    setView: Dispatch<SetStateAction<0 | 2 | 1>>;
    view: 0 | 1 | 2;
}

const PeopleDataHandler = ({
    setView,
    view
}: IPeopleDataHandler) => {
    return (
        <S.PeopleDataHandlerWrapper>
            <S.PeopleDataHandlerButton active={view === 0} onClick={() => setView(0)}>
                Dados da Pessoa
            </S.PeopleDataHandlerButton>
            {/* <S.PeopleDataHandlerButton active={view === 1} onClick={() => setView(1)}>
                Usuário
            </S.PeopleDataHandlerButton> */}
            <S.PeopleDataHandlerButton active={view === 2} onClick={() => setView(2)}>
                Infos. Adicionais
            </S.PeopleDataHandlerButton>
        </S.PeopleDataHandlerWrapper>
    )
}

export default PeopleDataHandler