import styled from "styled-components";

export const FooterWrapper = styled.div`
    width: 100%;
    padding: .5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    opacity: .8;
    color: var(--white);
    font-weight: 100;
    background-color: #ffffff20;
`