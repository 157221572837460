import styled from "styled-components";

export const RedefinirSenhaWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 2.3125rem);
    display: flex; 
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
    justify-content: center;
    color: var(--white);

`

export const LoginForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`

export const LoginItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.2rem;
    width: 100%;
`

export const LoginTitle = styled.span`
    font-size: 1.5rem;
    color: var(--text-blue);
    text-align: center;
    font-weight: 900;
    min-width: 17.5769rem;
`

export const LoginContainer = styled.div`
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
    row-gap: 2rem;
    background-color: var(--white);
    border-radius: .25rem;
    border: 1px solid var(--dark-gray);    
    -webkit-box-shadow: 0px 0px 9px 0px #00000060; 
    box-shadow: 0px 0px 9px 0px #00000060;

    > span.passNotMatch {
        color: var(--red);
    }
`

export const ForgotBox = styled.div`
    display: flex;
    gap: 2rem;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > button {
        width: 100%;
    }
`