import { privateDelete } from '../../../methods/delete';
import { privateGet } from '../../../methods/get';
import { privatePost } from '../../../methods/post';
import { privatePut } from '../../../methods/put';
import { IAdicionarUnidade, IAlterarUnidade, IExcluirUnidade, IObterListaUnidade } from '../../../models/Configuracoes/Unidade/index';
const basePath = "/configuracao/unidade"

export const adicionarUnidade = (body: IAdicionarUnidade) => {
    return privatePost<string>(`${basePath}/adicionar`, body)
}

export const obterListaUnidade = (idTipoSituacao?: number) => {
    return privateGet<IObterListaUnidade>(`${basePath}/obterlistaunidade?idTipoSituacao=${idTipoSituacao || 3}`)
}

export const alterarUnidade = (body: IAlterarUnidade) => {
    return privatePut<string>(`${basePath}/alterar`, body)
}

export const excluirUnidade = (body: IExcluirUnidade) => {
    return privateDelete<string>(`${basePath}/excluir`, body)
}