import styled from "styled-components";

export const CadastroClienteMacdataContent = styled.form`
    width: 100%;
    padding: 0 3rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: relative;
`

interface IGridItem {
    cols: number;
}

export const GridItem = styled.div<IGridItem>`
    display: grid;
    grid-template-columns: repeat(${props => props.cols},1fr);
    width: ${props => `${props.cols}fr`};
    column-gap: 3rem;
`

interface ISectionName {
    mt?: boolean;
}

export const SectionName = styled.span<ISectionName>`
    font-size: 1rem;
    margin-top: ${props => props.mt ? "1rem" : 0};
`