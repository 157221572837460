import { privatePost } from '../../../methods/post';
import { IAdicionarRiscoFase, IObterListaRiscoFaseResponse, IAlterarRiscoFase, IExcluirRiscoFase } from '../../../models/Configuracoes/RiscoFase/index';
import { privateGet } from '../../../methods/get';
import { privatePut } from '../../../methods/put';
import { privateDelete } from '../../../methods/delete';
const baseApiPath = "/configuracao/riscofase"

export const adicionarRiscoFase = (body: IAdicionarRiscoFase) => {
    return privatePost<string>(`${baseApiPath}/adicionar`, body)
}

export const obterListaRiscoFase = (idTipoSituacao?: number) => {
    return privateGet<IObterListaRiscoFaseResponse>(`${baseApiPath}/obterlistariscofase?idTipoSituacao=${idTipoSituacao || 3}`)
}

export const alterarRiscoFase = (body: IAlterarRiscoFase) => {
    return privatePut<string>(`${baseApiPath}/alterar`, body)
}

export const excluirRiscoFase = (idRiscoFase: IExcluirRiscoFase) => {
    return privateDelete<string>(`${baseApiPath}/excluir`, idRiscoFase)
}