import styled from "styled-components";

export const LoginWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 2.3125rem);
    display: flex; 
    align-items: center;
    justify-content: center;
`

export const LoginContainer = styled.div`
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
    row-gap: 2rem;
    background-color: var(--white);
    border-radius: .25rem;
    border: 1px solid var(--dark-gray);    
    -webkit-box-shadow: 0px 0px 9px 0px #00000060; 
    box-shadow: 0px 0px 9px 0px #00000060;
`

export const LoginTitle = styled.span`
    font-size: 1.5rem;
    color: var(--text-blue);
    text-align: center;
    font-weight: 900;
`

export const LoginItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.2rem;
    width: 100%;
`

export const LoginForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`

export const LoginLabel = styled.label`
    color: var(--text-blue);
    font-size: 1rem;
    font-weight: 600;
`

export const ForgotBox = styled.div`
    display: flex;
    gap: 2rem;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const ForgotLink = styled.button`
    font-size: .8rem;
    text-decoration: none;
    color: var(--text-blue);
    background-color: transparent;

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        outline: 2px solid var(--light-blue);
        border-radius: .1rem;
    }
`

export const Select = styled.select`
    font-size: 1.2rem;
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: .25rem;
    color: var(--medium-gray);
    cursor: pointer;
`

export const OnlyRight = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
`