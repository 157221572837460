import * as S from "./style"

const menulist = ["Item1", "Item2", "Item3", "Item4", "Item5", "Item6", "Item7"]

interface ITabSwitcher {
    list: string[];
    view: number;
    setView: React.Dispatch<React.SetStateAction<number>>;
    minimal?: boolean;
}

const TabSwitcher = ({
    list,
    view,
    setView,
    minimal = false
}: ITabSwitcher) => {
    return (
        <S.TabSwitcherWrapper minimal={minimal}>
            {
                list.map((item, index) => {
                    return (
                        <S.TabSwitcherHandlerButton
                            minimal={minimal}
                            active={index === view}
                            onClick={() => setView(index)}
                        >
                            {item}
                        </S.TabSwitcherHandlerButton>
                    )
                })
            }
        </S.TabSwitcherWrapper>
    )
}

export default TabSwitcher