import { create } from "zustand";
import { autenticar, obterListaClienteMacdata, obterUsuarioAutenticacao } from "../services/api/Autenticar";
import jwt_decode from 'jwt-decode';
import { IObterUsuarioAutenticacaoResponse } from "../services/models/Autenticar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type Client = {
  idClienteMacdata: number
  nome: string
}

type ClientsList = Client[];

type AuthProps = {
  name: string
  idClienteMacdata: number
  loginEmail: string
  senha: string
}

type LoginProps = {
  mail: string
  password: string
}

type User = {
  idUsuario: number
  tipoStatusLogin: string
}

type AuthStore = {
  clients: ClientsList
  authenticated: boolean
  isAuthenticated: () => boolean
  auth: (data: AuthProps) => void
  login: (data: LoginProps) => void
  logout: () => void
  isLoading: boolean
  user: IObterUsuarioAutenticacaoResponse
}

type Token = {
  exp: number
}

export const useAuthStore = create<AuthStore>((set, get) => {

  return {
    clients: undefined,
    authenticated: false,
    isAuthenticated: () => {
    
      if (
        get().user?.tipoStatusLogin !== "Ativo"
      ) {
        set(() => ({ authenticated: false }))
        return false
      }

      
      const storage = localStorage.getItem("user");
      if (storage) {
        const token = JSON.parse(storage).token;
        const decoded = jwt_decode<Token>(token) as any;
        const expirationDate = new Date(decoded.exp * 1000);
        const newDate = new Date();
        const result = expirationDate >= newDate;
        set(() => ({ authenticated: result }));
        return result;
      }
      
      set(() => ({ authenticated: false }));
      return false;
    },
    auth: async ({
      name,
      idClienteMacdata,
      loginEmail,
      senha
    }) => {
      set(() => ({ isLoading: true }));

      await autenticar((idClienteMacdata), {
        loginEmail,
        senha,
        idClienteMacdata
      }).then((res: any) => {
        if (res.status === 601) {
          set(() => ({ isLoading: false }));
        }

        if (
          res && loginEmail && idClienteMacdata && name
        ) {
          const status = get().user?.tipoStatusLogin;
          set(() => ({ authenticated: true }));
          localStorage.setItem("user", JSON.stringify({
            username: loginEmail,
            token: res,
            email: loginEmail,
            authenticated: true,
            cId: idClienteMacdata,
            cName: name
          }));
          if (
            status === "Ativo"
          ) {
            toast.dismiss()
            toast.success("Login efetuado!", { toastId: "postToast" })
          } else {
            console.log("NAO")
          }
        }
      })

      set(() => ({ isLoading: false }));
    },
    login: async ({
      mail,
      password
    }) => {
      set(() => ({ isLoading: true }));
      await obterUsuarioAutenticacao({
        loginEmail: mail,
        senha: password
      }).then(async (user) => {
        set(() => ({ user: user }))
        await obterListaClienteMacdata({
          idUsuario: user.idUsuario
        }).then((clients) => {
          set(() => ({ clients: clients }));
          if (clients.length === 1) {
            const client = clients[0];
            get().auth({
              name: client.nome,
              idClienteMacdata: client.idClienteMacdata,
              loginEmail: mail,
              senha: password
            })
          }
        })
      })

      set(() => ({ isLoading: false }));
    },
    logout: () => {
      localStorage.clear();
      set(() => ({ authenticated: false, clients: undefined, user: undefined }));
    },
    isLoading: false,
    user: undefined
  }
})