import axios from "axios";
import { toast } from "react-toastify";
import getIdClienteMacdata from "../../utils/getIdClienteMacdata";

const put = async<T> (route: string, body: any): Promise<T> => {
    return await axios
        .put(`${process.env.REACT_APP_BACKEND_API}${route}`, body)
        .then((r) => r.data)
        .catch((e) => {
            toast.dismiss()
            toast.error(e.response.data.detail, { toastId: "putErrorToast" })
        })
}

export const resetPassPut = async<T> (route: string, body: any): Promise<T> => {
    return await axios
        .put(`${process.env.REACT_APP_BACKEND_API}${route}`, body)
        .then((r) => {
            toast.dismiss()
            toast.success(r.data, { toastId: "putSuccessToastPass" })
            return r.data
        })
        .catch((e) => {
            toast.dismiss()
            toast.error(e.response.data.detail, { toastId: "putErrorToastPass" })
        })
}


export const privatePut = async<T> (route: string, body?: any): Promise<T> => {

    const user = localStorage.getItem("user")
    const token = user && JSON.parse(user).token

    const privateAxios = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        headers: {
            'Authorization': `Bearer ${token}`,
            idClienteMacdata: getIdClienteMacdata()
        }
    })

    return await privateAxios
        .put(`${process.env.REACT_APP_BACKEND_API}${route}`, body)
        .then((r) => {
            toast.dismiss()
            toast.success(r.data, { toastId: "putToast" })
            return r.data
        })
        .catch((e) => {
            toast.dismiss()
            toast.error(e.response.data.detail, { toastId: "putErrorToast" })
        })
}


export default put