import styled from "styled-components";

export const LegalCasesContent = styled.div`
    width: 100%;
    padding: 1rem 3rem 2rem 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`

export const PageHandlerContainer = styled.div`
    border: 1px solid var(--blue-primary);
    border-radius: .25rem;
    padding: 1rem 0;
    width: 100%;
    /* height: 100%; */
`

export const FloatButtons = styled.div`
    position: absolute;
    top: 1rem;
    right: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
`

export const ItemDataWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 2.3125rem);
    padding: 5rem;
`

export const ItemDataContent = styled.form`
    width: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: relative;
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
`

interface IGridItem {
    cols: number;
}

export const GridItem = styled.div<IGridItem>`
    display: grid;
    grid-template-columns: repeat(${props => props.cols},1fr);
    width: ${props => `${props.cols}fr`};
    column-gap: 3rem;

    .error {
        font-size: .875rem;
        color: var(--red);
    }
`

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    margin: 1rem 0;
`

interface IControlView {
    active: boolean;
}

export const ControlView = styled.div<IControlView>`
    display: ${props => props.active ? "flex" : "none"};
    flex-direction: column;
    gap: .5rem;
`

export const ButtonsContainer = styled.div`
    position: absolute;
    top: 1.5rem;
    right: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
`

export const Line = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
`