import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Button from '../../components/Button';
import Input from '../../components/Input';
import { alterarSenhaUsuario, alterarSenhaUsuarioPorToken, validarToken } from "../../services/api/Autenticar";
import * as S from "./style";
import { useAuthStore } from "../../stores/AuthStore";

const LoginSchema = yup.object({
    newPass: yup.string().required("Senha obrigatória."),
    newPassConfirm: yup.string().required("Senha obrigatória.")
})

const RedefinirSenha = () => {
    const [searchParams] = useSearchParams()
    const [guid] = useState<string | null>(searchParams.get("token"))
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [passwordsDontMatch, setPasswordsDontMatch] = useState<boolean>(false)
    const navigate = useNavigate()

    const [user, logout] = useAuthStore((state) => [state.user, state.logout]);

    const {
        handleSubmit,
        control,
        watch,
        formState: {
            errors,
            isValid
        }
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(LoginSchema)
    })

    useEffect(() => {
        !guid && !user && navigate("/")

        if (guid) {
            verifyGuid(guid)
        }
    }, [guid])

    const verifyGuid = async (guid: string) => {
        let res = await validarToken(guid)
        if (!res) {
            navigate("/")
            toast.error("Link inválido!", { toastId: "toastTokenError" })
        }
    }

    useEffect(() => {
        let newPass = watch("newPass")
        let newPassConfirm = watch("newPassConfirm")

        if (newPass !== newPassConfirm) {
            setPasswordsDontMatch(true)
        } else if (newPass === newPassConfirm) {
            setPasswordsDontMatch(false)
        }
    }, [watch("newPass"), watch("newPassConfirm")])

    const passwordsMatch = () => {
        let newPass = watch("newPass")
        let newPassConfirm = watch("newPassConfirm")
        return newPass === newPassConfirm
    }

    const loginAction = async (data: any) => {
        if (!guid && !user) return
        setIsLoading(true)

        if (
            (user.tipoStatusLogin === "SolicitarNovaSenha") ||
            (user.tipoStatusLogin === "NovaSenha")
        ) {
            await alterarSenhaUsuario({
                idUsuario: user.idUsuario,
                senha: data.newPass
            }).then((res) => {
                if (res) {
                    logout()
                    navigate("/")
                }
                setIsLoading(false)
            })
        } else {
            await alterarSenhaUsuarioPorToken({
                tokenRecuperacaoSenha: guid,
                senha: data.newPass
            }).then((res) => {
                if (res) {
                    logout()
                    navigate("/")
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            })
        }

        

    }

    return (
        <S.RedefinirSenhaWrapper>
            <S.LoginContainer>
                <S.LoginTitle>
                    {
                        user?.tipoStatusLogin === "SolicitarNovaSenha" ? "Nova senha" : "Recuperação de senha"
                    }
                </S.LoginTitle>
                <S.LoginForm id="loginForm" onSubmit={handleSubmit((data) => loginAction(data))}>
                    <S.LoginItem>
                        <Input label="Nova senha" id="newPass" name="newPass" control={control} type="password" placeholder="Insira sua nova senha" error={errors.newPass?.message} />
                    </S.LoginItem>
                    <S.LoginItem>
                        <Input label="Repita a nova senha" id="newPassConfirm" name="newPassConfirm" control={control} type="password" placeholder="Insira sua nova senha novamente" error={errors.newPassConfirm?.message} />
                    </S.LoginItem>
                </S.LoginForm>
                {
                    passwordsDontMatch && (
                        <span className="passNotMatch">As senhas não conferem!</span>
                    )
                }
                <S.LoginItem>
                    <S.ForgotBox>
                        <Button type="submit" form="loginForm" disabled={isLoading || !isValid || !passwordsMatch()} loading={isLoading}>
                            Confirmar
                        </Button>
                    </S.ForgotBox>
                </S.LoginItem>
            </S.LoginContainer>
        </S.RedefinirSenhaWrapper>
    )
}

export default RedefinirSenha