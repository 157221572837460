import styled from "styled-components";

export const PaginatorWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`

export const PaginatorButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
`

export const PaginatorButton = styled.button`
    background-color: var(--blue-primary);
    color: var(--white);
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: filter .2s ease;

    :disabled {
        filter: brightness(.8);
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        filter: brightness(1.1);
    }

    &:not(:disabled):active {
        filter: brightness(.9);
        transform: scale(1.1);
    }
`

export const CenterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    padding: 0 1rem;
    font-size: .8rem;
`

export const PaginatorInput = styled.input`
    font-size: .8rem;
    padding: .1rem .5rem;
    width: 3rem;
    text-align: center;
    border-radius: .25rem;
    border: 1px solid var(--dark-gray);
    font-weight: 600;

    &:focus {
        border-color: var(--light-blue);
        outline: 2px solid var(--light-blue);
    }
`

export const RecordsLabel = styled.span`
    font-size: .8rem;
    font-weight: 500;
`