import post from "../../methods/post";
import put from "../../methods/put";
import get, { privateGet } from "../../methods/get";
import {
    IClienteMacdataAdicionar,
    IClienteMacdataAlterar,
    IClienteMacdataObter
} from '../../models/ClienteMacdata/index';

export const adicionarClienteMacdata = (body: IClienteMacdataAdicionar) => {
    return post<string>("/clientemacdata/adicionar", body)
}

export const alterarClienteMacdata = (body: IClienteMacdataAlterar) => {
    return put<string>("/clientemacdata/alterar", body)
}

export const obterClienteMacdata = (idClienteMacdata: number) => {
    return privateGet<IClienteMacdataObter>(`/clientemacdata/obterclientemacdata?idClienteMacdata=${idClienteMacdata}`)
}