import { Trash } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ActionButton from '../../../../components/ActionButton';
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import ItemsList, { HeaderList, ListItem } from '../../../../components/ItemsList';
import MaskInput from '../../../../components/MaskInput';
import Paginator from '../../../../components/Paginator';
import Popup from '../../../../components/Popup';
import Select from "../../../../components/Select";
import WithLabel from "../../../../components/WithLabel";
import { excluirParteCasoJuridico, obterListaPaginadaPesquisaPessoaCasoJuridico, obterListaParteCasoJuridico, salvarParteCasoJuridico } from '../../../../services/api/CasoJuridico';
import { obterListaPessoaRelacao } from "../../../../services/api/Configuracoes/PessoaRelacao";
import { obterListaPessoaQualificacao } from '../../../../services/api/Pessoa/Qualificacao';
import * as S from "../../style";
import { ISalvarParteCasoJuridico } from '../../../../services/models/CasoJuridico/index';
import { useNavigate } from 'react-router-dom';
import { usePermissionsStore } from '../../../../stores/PermissionsStore';

interface IPartes {
    storedId?: string | null;
}

interface IPessoaSelecionada {
    id: number;
    nome: string;
    cnpjCpf: string;
    sequencial: number;
}

const Partes = ({ storedId }: IPartes) => {
    const [listaPessoaRelacao, setListaPessoaRelacao] = useState<any>()
    const [pessoaSelecionada, setPessoaSelecionada] = useState<IPessoaSelecionada | null>(null)
    const [listaPessoaSelecionada, setListaPessoaSelecionada] = useState<IPessoaSelecionada[] | any>([])
    const [modalSelecionarPessoa, setModalSelecionarPessoa] = useState<boolean>(false)
    const [listaPaginadaPessoa, setListaPaginadaPessoa] = useState<any>(null)
    const [paginaPesquisaPessoa, setPaginaPesquisaPessoa] = useState<number>(1)
    const [totalPaginasPesquisaPessoa, setTotalPaginasPesquisaPessoa] = useState<number>(0)
    const [totalItensPesquisaPessoa, setTotalItensPesquisaPessoa] = useState<number>(0)
    const [listaPessoaQualificacao, setListaPessoaQualificacao] = useState<any>(null)
    const [cnpjCpfMask, setCnpjCpfMask] = useState<string>("999.999.999-99*")
    const [idsPessoasInseridas, setIdsPessoasInseridas] = useState<any>([])
    const [isDirty, setIsDirty] = useState<boolean>(false)

    const navigate = useNavigate()

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isValid
        },
        getValues,
        setValue,
        register,
        getFieldState,
        reset,
        watch,
        setError
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    })

    useEffect(() => {
        if (storedId) {
            ObterListaParteCasoJuridico(+storedId)
        }

        ObterListaPessoaRelacao()
        ObterListaPessoaQualificacao()
    }, [storedId])

    useEffect(() => {
        handleCnpjCpfMask(watch("selecionarPessoaCpfCnpj"))
    }, [watch("selecionarPessoaCpfCnpj")])

    useEffect(() => {
        if (modalSelecionarPessoa) {
            ObterListaPessoaQualificacao()
            ObterListaPaginadaPesquisaPessoa()
        }
    }, [modalSelecionarPessoa])

    useEffect(() => {
        ObterListaPaginadaPesquisaPessoa(true)
    }, [paginaPesquisaPessoa])

    const handleCnpjCpfMask = (cpfCnpj: string) => {
        if (!cpfCnpj) return

        let nDoc = cpfCnpj.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")

        if (nDoc.length > 11) {
            setCnpjCpfMask("99.999.999/9999-99")
        } else {
            setCnpjCpfMask("999.999.999-99*")
        }
    }

    const ObterListaPessoaRelacao = async () => {
        let res = await obterListaPessoaRelacao(1)
        setListaPessoaRelacao(res)
    }

    const ObterListaPessoaQualificacao = async () => {
        let res = await obterListaPessoaQualificacao(1)
        setListaPessoaQualificacao(res)
    }

    const limparCamposModalPessoa = () => {
        setValue("selecionarPessoaNome", null)
        setValue("selecionarPessoaCpfCnpj", "")
        setValue("selecionarPessoaQualificacao", "Selecione")
    }

    const ObterListaPaginadaPesquisaPessoa = async (page?: boolean) => {
        let body: any = {
            limite: 5,
            pagina: page ? paginaPesquisaPessoa : 1,
            tipoSituacao: "Ambos",
            nome: watch("selecionarPessoaNome") && watch("selecionarPessoaNome"),
            cnpjCpf: watch("selecionarPessoaCpfCnpj") && watch("selecionarPessoaCpfCnpj"),
            idPessoaQualificacao: watch("selecionarPessoaQualificacao") !== "Selecione" ? watch("selecionarPessoaQualificacao") : undefined

        }
        let res = await obterListaPaginadaPesquisaPessoaCasoJuridico(body)
        setListaPaginadaPessoa(res.itens)
        setPaginaPesquisaPessoa(res.paginaAtual)
        setTotalItensPesquisaPessoa(res.totalItens)
        setTotalPaginasPesquisaPessoa(res.totalPaginas)
    }

    const SelecionarPessoa = (pessoa: any) => {
        let newPessoa = {
            ...pessoa,
            relacionamento: watch("relacionamento"),
            isPrincipal: watch("isPrincipal") === "true",
        }

        if (!pessoaJaExiste(newPessoa.id)) {
            setPessoaSelecionada(newPessoa)
            setValue("idPessoaSelecionada", newPessoa.id)
        }
    }

    const limparCamposPartes = () => {
        setValue("idPessoaSelecionada", null)
        setValue("selecionarPessoaNome", null)
        setValue("selecionarPessoaCpfCnpj", "")
        setValue("selecionarPessoaQualificacao", "Selecione")
        setValue("nomePessoa", null)
        setValue("relacionamento", "Selecione")
        setValue("isPrincipal", "Selecione")
        setPessoaSelecionada(null)
    }

    const limparCamposModal = () => {
        setValue("idPessoaSelecionada", null)
        setValue("selecionarPessoaNome", null)
        setValue("selecionarPessoaCpfCnpj", "")
        setValue("selecionarPessoaQualificacao", "Selecione")
        setValue("nomePessoa", null)
        setPessoaSelecionada(null)
    }

    useEffect(() => {
        if ((watch("relacionamento") === "Selecione") || (watch("relacionamento") === undefined)) return
        let relacaoP = listaPessoaSelecionada.filter((item: any) => {
            return item.idPessoaRelacao === +watch("relacionamento")
        })

        setValue("isPrincipal", "true")
        if (relacaoP.length <= 0) {
            setValue("isPrincipal", "true")
        } else {
            setValue("isPrincipal", "false")
        }
    }, [watch("relacionamento")])

    const incluirPessoa = () => {
        if (pessoaSelecionada) {
            let newPessoa = {
                idCaso: storedId ? +storedId : 0,
                idPessoa: pessoaSelecionada.id,
                nomePessoa: pessoaSelecionada.nome,
                idPessoaRelacao: +watch("relacionamento"),
                descricaoPessoaRelacao: ObterRelacaoPorId(+watch("relacionamento")),
                isPrincipal: watch("isPrincipal") === "true"
            }

            const selectedList = listaPessoaSelecionada ? [
                ...listaPessoaSelecionada,
                newPessoa
            ] : [newPessoa]

            let listaPessoasPeloFront = [...idsPessoasInseridas]
            listaPessoasPeloFront.push(newPessoa.idPessoa)
            setIdsPessoasInseridas(listaPessoasPeloFront)

            handlerPessoaPrincipal(pessoaSelecionada, selectedList, newPessoa)
        }
    }

    const excluirPessoa = (pessoaIndex: number) => {
        let lista = [...listaPessoaSelecionada]
        lista.splice(pessoaIndex, 1)
        setListaPessoaSelecionada(lista)
    }

    const handlerPessoaPrincipal = (pessoa: any, lista: any, newPessoa: any) => {
        if (listaPessoaSelecionada?.length > 0) {
            if (newPessoa.isPrincipal) {
                toast.info(`"${pessoa.nome}" será principal.`)

                let tempListaPessoaSelecionada = lista
                tempListaPessoaSelecionada.forEach((item: any) => {
                    if (
                        (item.idPessoa !== newPessoa.idPessoa) &&
                        (item.descricaoPessoaRelacao === newPessoa.descricaoPessoaRelacao)
                    ) {
                        item.isPrincipal = false
                    }
                })

                setListaPessoaSelecionada(tempListaPessoaSelecionada)
            } else {
                setListaPessoaSelecionada(lista)
            }
        } else {
            setListaPessoaSelecionada(lista)
        }
        setIsDirty(true)
        limparCamposPartes()
    }

    const pessoaJaExiste = (id: number) => {
        if (listaPessoaSelecionada) {
            let c = 0
            Object.keys(listaPessoaSelecionada).forEach((item: any) => {
                if (listaPessoaSelecionada[item].idPessoa === id) {
                    c += 1
                }
            })
            return c !== 0
        }
        return false
    }

    const canSearchPeople = () => {
        let relacionamento = watch("relacionamento")
        let isValidRel = relacionamento && relacionamento !== "Selecione"
        let isPrincipal = watch("isPrincipal")
        let isValidParte = isPrincipal && isPrincipal !== "Selecione"
        return isValidRel && isValidParte
    }

    const ObterRelacaoPorId = (id: number) => {
        return listaPessoaRelacao.filter((item: any) => {
            return item.id === id
        })[0].descricao
    }

    const ObterListaParteCasoJuridico = async (idCaso: number) => {
        let res = await obterListaParteCasoJuridico(idCaso)
        setListaPessoaSelecionada(res)
    }

    const ExcluirParteCasoJuridico = async (pessoaIndex: number) => {
        if (!storedId) return

        let pessoa = listaPessoaSelecionada[pessoaIndex]

        let listaPNew = [...listaPessoaSelecionada]

        listaPNew = listaPNew.filter(item => item !== pessoa);

        setListaPessoaSelecionada(listaPNew)

        try {
            await excluirParteCasoJuridico(+storedId, pessoa.idPessoa, pessoa.idPessoaRelacao).then((itm: any) => {
                if (itm) {
                    ObterListaParteCasoJuridico(+storedId)
                }
            })
        } catch (error) {
            console.error("ERRO: ", error)
        }
    }

    const SalvarParteCasoJuridico = async (partes: ISalvarParteCasoJuridico[]) => {
        if (!storedId) return
        await salvarParteCasoJuridico(partes)
    }

    const SalvarPagina = () => {
        SalvarParteCasoJuridico(listaPessoaSelecionada)
    }

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(17);

    const {
        permiteAlterar,
        permiteInserir,
        permiteExcluir
    } = permissions

    // const permiteAlterar = false
    // const permiteExcluir = true
    // const permiteInserir = true

    return (
        <>
            {
                (permiteAlterar || permiteInserir) &&
                isDirty && (
                    <div style={{
                        position: "absolute",
                        right: "0rem",
                        top: "-10.5rem"
                    }}>
                        <Button onClick={SalvarPagina}>
                            Salvar Partes
                        </Button>
                    </div>
                )
            }
            <S.GridItem cols={2}>
                {
                    listaPessoaRelacao && (
                        <Select
                            id="relacionamento"
                            name="relacionamento"
                            label="Relacionamento"
                            control={control}
                            disabled={!permiteAlterar || !permiteInserir}
                            options={
                                listaPessoaRelacao.map((item: any) => ({
                                    value: item.id,
                                    label: item.descricao
                                }))
                            }
                        />
                    )
                }
                <Select
                    id="isPrincipal"
                    name="isPrincipal"
                    label="Principal"
                    control={control}
                    disabled={!permiteAlterar || !permiteInserir}
                    options={[
                        { value: "false", label: "Não" },
                        { value: "true", label: "Sim" },
                    ]}
                />
            </S.GridItem>
            <S.GridItem cols={3}>
                <Input
                    id="nomePessoa"
                    name="nomePessoa"
                    label="Pessoa"
                    value={pessoaSelecionada?.nome}
                    control={control}
                    disabled
                />
                {
                    (permiteAlterar || permiteInserir) && (
                        <WithLabel
                            text="&nbsp;"
                        >
                            <Button onClick={() => setModalSelecionarPessoa(true)} disabled={!canSearchPeople() || !permiteAlterar}>Pesquisar</Button>
                        </WithLabel>
                    )
                }
            </S.GridItem>
            {
                modalSelecionarPessoa && (
                    <Popup
                        minWidth='90%'
                        show={modalSelecionarPessoa}
                        onClose={() => {
                            setModalSelecionarPessoa(false)
                            limparCamposModal()
                        }}
                        title="Pesquisar Pessoa"
                        confirmAction={() => setModalSelecionarPessoa(false)}
                        confirmDisabled={pessoaSelecionada === null}
                    >
                        <S.GridItem cols={4}>
                            <Button onClick={() => navigate("/pessoas/dados-pessoa")}>Nova Pessoa</Button>
                        </S.GridItem>
                        <S.GridItem cols={4}>
                            <Input id="selecionarPessoaNome" name="selecionarPessoaNome" label="Nome" control={control} />
                            <MaskInput mask={cnpjCpfMask} id="selecionarPessoaCpfCnpj" name="selecionarPessoaCpfCnpj" label="CPF/CNPJ" control={control} />
                            {
                                listaPessoaQualificacao && (
                                    <Select
                                        id="selecionarPessoaQualificacao"
                                        name="selecionarPessoaQualificacao"
                                        label="Qualificação"
                                        control={control}
                                        options={
                                            listaPessoaQualificacao.map((item: any) => ({
                                                value: item.id,
                                                label: item.descricao
                                            }))
                                        }
                                    />
                                )
                            }
                            <WithLabel text="&nbsp;">
                                <Button onClick={() => limparCamposModalPessoa()}>Limpar</Button>
                                <Button onClick={() => ObterListaPaginadaPesquisaPessoa()}>Pesquisar</Button>
                            </WithLabel>
                        </S.GridItem>
                        {
                            listaPaginadaPessoa && listaPaginadaPessoa.length > 0 ? (
                                <S.GridItem cols={1}>
                                    <ItemsList>
                                        <HeaderList items={[
                                            "CPF/CNPJ",
                                            "Nome",
                                            "Qualificação"
                                        ]} />
                                        {
                                            listaPaginadaPessoa.map((item: any, index: number) => {
                                                return (
                                                    <ListItem key={index} onClick={() => SelecionarPessoa(item)} style={{ backgroundColor: watch("idPessoaSelecionada") === item.id && "var(--light-gray)", color: pessoaJaExiste(item.id) && "var(--medium-gray)" }}>
                                                        <div>{item.cnpjCpf}</div>
                                                        <div>{item.nome}</div>
                                                        <div>{item.descricaoPessoaQualificacao}</div>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                        <br />
                                        <Paginator
                                            totalItens={totalItensPesquisaPessoa}
                                            totalPaginas={totalPaginasPesquisaPessoa}
                                            paginaAtual={paginaPesquisaPessoa}
                                            change={setPaginaPesquisaPessoa}
                                            first={() => setPaginaPesquisaPessoa(1)}
                                            last={() => setPaginaPesquisaPessoa(totalPaginasPesquisaPessoa)}
                                        />
                                    </ItemsList>
                                </S.GridItem>
                            ) : (
                                <span>Sem resultados</span>
                            )
                        }
                        {
                            watch("idPessoaSelecionada") && pessoaSelecionada && (
                                <S.GridItem cols={1}>
                                    <span>Selecionado: {pessoaSelecionada.nome}</span>
                                </S.GridItem>
                            )
                        }
                    </Popup>
                )
            }
            {
                (permiteAlterar || permiteInserir) && (
                    <S.GridItem cols={6}>
                        <WithLabel text="&nbsp;">
                            <Button onClick={limparCamposPartes}>Limpar</Button>
                        </WithLabel>
                        <WithLabel text="&nbsp;">
                            <Button onClick={incluirPessoa} disabled={pessoaSelecionada === null}>Adicionar</Button>
                        </WithLabel>
                    </S.GridItem>
                )
            }
            {
                listaPessoaSelecionada && listaPessoaSelecionada.length > 0 && (
                    <S.GridItem cols={1}>
                        <ItemsList>
                            <HeaderList items={[
                                "Nome",
                                "Relação",
                                "Principal",
                                ...((
                                    permiteExcluir ||
                                    permiteAlterar ||
                                    permiteInserir
                                ) ? ["Remover"] : [])
                            ]} big={[0]} />
                            {
                                listaPessoaSelecionada.map((item: any, index: number) => {
                                    return (
                                        <ListItem key={index}>
                                            <div className='big'>{item.nomePessoa || "N/A"}</div>
                                            <div>{item.descricaoPessoaRelacao || "N/A"}</div>
                                            <div>{item.isPrincipal ? "Sim" : "Não"}</div>
                                            {
                                                (
                                                    permiteExcluir ||
                                                    permiteAlterar ||
                                                    permiteInserir
                                                ) && (
                                                    <div>
                                                        <ActionButton act="delete" onClick={() => ExcluirParteCasoJuridico(index)}>
                                                            <Trash />
                                                        </ActionButton>
                                                    </div>
                                                )
                                            }
                                        </ListItem>
                                    )
                                })
                            }
                        </ItemsList>
                    </S.GridItem>
                )
            }
        </>
    )
}

export default Partes