import { privateDelete } from '../../../methods/delete';
import { privateGet } from '../../../methods/get';
import { privatePost } from '../../../methods/post';
import { privatePut } from '../../../methods/put';
import { IAdicionarRiscoSubtitulo, IAlterarRiscoSubtitulo, IExcluirRiscoSubtitulo, IObterListaRiscoSubtituloResponse } from '../../../models/Configuracoes/RiscoSubtitulo/index';
const baseApiPath = "/configuracao/riscosubtitulo"

export const adicionarRiscoSubtitulo = (body: IAdicionarRiscoSubtitulo) => {
    return privatePost<string>(`${baseApiPath}/adicionar`, body)
}

export const obterListaRiscoSubtitulo = () => {
    return privateGet<IObterListaRiscoSubtituloResponse[]>(`${baseApiPath}/obterlistariscosubtitulo`)
}

export const alterarRiscoSubtitulo = (body: IAlterarRiscoSubtitulo) => {
    return privatePut<string>(`${baseApiPath}/alterar`, body)
}

export const excluirRiscoSubtitulo = (body: IExcluirRiscoSubtitulo) => {
    return privateDelete<string>(`${baseApiPath}/excluir`, body)
}