import * as S from "./style"

interface IItemsList {
    children: JSX.Element | JSX.Element[] | string;
}

const ItemsList = ({
    children
}: IItemsList) => {
    return (
        <S.ItemsListWrapper>
            {children}
        </S.ItemsListWrapper>
    )
}

interface IHeaderList {
    items: string[];
    big?: number[];
    small?: number[];
}

export const HeaderList = ({
    items,
    big,
    small
}: IHeaderList) => {
    return (
        <S.HeaderListWrapper>
            {
                items.map((item, index) => {
                    return (
                        <div key={index} style={{
                            flex: big?.includes(index) ? "1.8" :
                                small?.includes(index) ? ".7" : "1"
                        }}>
                            {item}
                        </div>
                    )
                })
            }
        </S.HeaderListWrapper>
    )
}

interface IListItem {
    children: JSX.Element | JSX.Element[] | string;
    onClick?: () => void;
    [key: string]: any;
}

export const ListItem = ({
    children,
    onClick,
    ...rest
}: IListItem) => {
    return (
        <S.ListItem onClick={onClick} {...rest}>
            {children}
        </S.ListItem>
    )
}

export default ItemsList