import styled from "styled-components";

export const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .2rem;
`

export const StyledLabel = styled.label`
    font-size: .8rem;
    font-weight: 700;
    color: var(--dark-gray);
`

export const StyledError = styled.span`
    color: var(--red);
    font-size: .7rem;
    font-weight: 600;
`

interface IStyledSelect {
    error?: boolean
}

export const StyledSelect = styled.select<IStyledSelect>`
    width: 100%;
    border: ${props => props.error ? "1px solid var(--red)" : "1px solid var(--dark-gray)"};
    padding: .2rem;
    border-radius: .25rem;
    font-size: .8rem;
    /* min-width: 20rem; */

    &:focus {
        border-color: var(--light-blue);
        outline: 2px solid var(--light-blue);
    }
`