import PageTitle from "../../components/PageTitle"
import Template from "../../components/Template"
import styled from "styled-components"
import Button from "../../components/Button"
import { useNavigate } from 'react-router-dom';
import { BiError } from "react-icons/bi"

const Content = styled.div`
    width: 100%;
    padding: 0 3rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    position: relative;
    color: var(--white);
    height: calc(100vh - 7.5rem);

    button {
        max-width: 10rem;
    }
`

const NotFound = () => {
    const navigate = useNavigate()

    return (
        <Content>
            <BiError size={"5rem"} />
            <p>Página não encontrada!</p>
            <Button onClick={() => navigate("/")}>Início</Button>
        </Content>
    )
}

export default NotFound