import deleteMethod from "../../methods/delete";
import { privateGet } from '../../methods/get';
import { privatePost } from '../../methods/post';
import put, { privatePut } from "../../methods/put";
import { privateDelete } from '../../methods/delete';
import {
    IPessoa,
    IPessoaAdicionar,
    IPessoaAlterar,
    IPessoaExcluir,
    IPessoaObterInformacaoAdicional,
    IPessoaObterInformacaoAdicionalResponse,
    IPessoaObterListaPaginadaPesquisaPessoa,
    IPessoaObterListaPaginadaPesquisaPessoaResponse, IPessoaObterListaTipoNaturezaPessoa,
    IPessoaObterListaTipoSexo, IPessoaObterPessoa,
    IPessoaObterPessoaResponse
} from "../../models/Pessoa";

export const obterListaTipoNaturezaPessoa = () => {
    return privateGet<IPessoaObterListaTipoNaturezaPessoa[]>("/pessoa/obterlistatiponaturezapessoa")
}

export const obterListaTipoSexo = () => {
    return privateGet<IPessoaObterListaTipoSexo[]>("/pessoa/obterlistatiposexo")
}

export const adicionarPessoa = (body: any) => {
    return privatePost<string>("/pessoa/adicionar", body)
}

export const alterarPessoa = (body: any) => {
    return privatePut<string>("/pessoa/alterar", body)
}

export const excluirPessoa = (body: IPessoaExcluir) => {
    return privateDelete<string>("/pessoa/excluir", body)
}

export const obterInformacaoAdicionalPessoa = async (body: IPessoaObterInformacaoAdicional) => {
    let res = await privateGet<IPessoaObterInformacaoAdicionalResponse>("/pessoa/obterinformacaoadicional", body)
    let ret = {
        ...res,
        dataInclusao: res.dataInclusao.substring(0, 10),
        dataUltimaAlteracao: res.dataUltimaAlteracao.substring(0, 10)
    }  
    return ret
}

export const obterListaPaginadaPesquisaPessoa = (body: IPessoaObterListaPaginadaPesquisaPessoa) => {
    return privateGet<IPessoaObterListaPaginadaPesquisaPessoaResponse>("/pessoa/obterlistapaginadapesquisapessoa", body)
}

export const obterPessoa = async (body: IPessoaObterPessoa) => {
    let res = await privateGet<IPessoaObterPessoaResponse>("/pessoa/obterpessoa", body)
    let ret = {
        ...res,
        dataNascimento: res.dataNascimento ? res.dataNascimento.substring(0, 10) : res.dataNascimento,
    }
    return ret
}

export const obterPessoaSemUsuario = async (idPessoa: string) => {
    return privateGet<IPessoa>(`/pessoa/obterpessoasemusuario?idPessoa=${idPessoa}`)
}

export const obterPessoaComUsuario = async (idPessoa: string) => {
    return privateGet<any>(`/pessoa/obterpessoacomusuario?idPessoa=${idPessoa}`)
}