import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect, useContext } from 'react';
import { DeepRequired, FieldArray, FieldArrayPath, FieldError, FieldErrorsImpl, FieldValues, FormState, UseFormRegisterReturn, Validate, ValidationRule, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from "yup";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Loader from "../../components/Loader";
import PageTitle from "../../components/PageTitle";
import Paginator from "../../components/Paginator";
import Template from "../../components/Template";
import * as S from "./style";

import ListTable from "../../components/List";
import RadioButton from "../../components/RadioButton";
import WithLabel from "../../components/WithLabel";
import { obterListaPaginadaPesquisaCasoJuridico } from '../../services/api/CasoJuridico/index';
import ItemsList, { HeaderList, ListItem } from "../../components/ItemsList";
import { convertDate } from '../../utils/index';
import getClienteIntegracao from "../../utils/getClienteIntegracao";
import { obterListaPessoaRelacaoPesquisaCasoJuridico } from "../../services/api/Configuracoes/PessoaRelacao";
import { IObterListaPessoaRelacaoResponse } from "../../services/models/Configuracoes/PessoaRelacao";
import Select from "../../components/Select";
import { usePermissionsStore } from "../../stores/PermissionsStore";

const SearchSchema = yup.object({
    TipoVisualizar: yup.string().required("Tipo obrigatório.").oneOf(["Todos", "NaoAtribuidos", "Atribuidos"]),
    Sequencial: yup.string(),
    IdExterno: yup.string(),
    NumeroProcesso: yup.string(),
    TipoSituacao: yup.string().required("Tipo obrigatório.").oneOf(["Ativos", "Inativos", "Ambos"]),
    DataInclusaoInicio: yup.string(),
    DataInclusaoFim: yup.string()
})

const CaseSearch = () => {
    const navigate = useNavigate()

    const findPermission = usePermissionsStore((state) => state.findPermission);

    const permissions = findPermission(17);

    const {
        permiteAlterar,
        permiteInserir,
        permiteExcluir
    } = permissions

    const [searchParams, setSearchParams] = useSearchParams()
    const [showResults, setShowResults] = useState<boolean>(false)
    const [noResults, setNoResults] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [totalItens, setTotalItens] = useState<number>(0)
    const [paginaAtual, setPaginaAtual] = useState<number>(1)
    const [totalPaginas, setTotalPaginas] = useState<number>(0)
    const [listaCasoJuridico, setListaCasoJuridico] = useState<any | null>(null)
    const [listaPessoaRelacao, setListaPessoaRelacao] = useState<IObterListaPessoaRelacaoResponse[] | []>(null)

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            isValid
        },
        getValues,
        reset
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(SearchSchema)
    })

    const resetForm = () => {
        reset()
    }

    const triggerSearch = async (data: any, isSearch?: boolean) => {
        let body = {
            ...data,
            idPessoaRelacao: data.idPessoaRelacao === "Selecione" ? null : data.idPessoaRelacao,
            NomePessoaRelacao: data.NomePessoaRelacao || null,
            Limite: 10,
            Pagina: paginaAtual
        }

        let res = await obterListaPaginadaPesquisaCasoJuridico(body)

        setListaCasoJuridico(res.itens)
        setTotalItens(res.totalItens)
        setTotalPaginas(res.totalPaginas)

        if (isSearch) {
            setPaginaAtual(1)
        }

        setShowResults(true)
        setNoResults(false)
        setLoading(false)

        if (res.totalItens === 0) {
            setShowResults(false)
            setNoResults(true)
        }
    }

    const ObterListaPessoaRelacao = async () => {
        let res = await obterListaPessoaRelacaoPesquisaCasoJuridico();
        setListaPessoaRelacao(res)
    }

    useEffect(() => {
        ObterListaPessoaRelacao()
        triggerSearch(getValues())
    }, [paginaAtual])

    const goDetail = (idCaso: string) => {
        searchParams.set("idCaso", idCaso)
        navigate({
            pathname: "/casos-juridicos/dados",
            search: `?idCaso=${idCaso}`
        })
    }

    const fazBusca = (data: any) => {
        triggerSearch(data, true)
    }

    return (
        <Template backTo="/inicio">
            <PageTitle>
                Pesquisa de Casos Jurídicos
            </PageTitle>
            <S.CaseSearchContent onSubmit={handleSubmit(fazBusca)}>
                <S.FlexLine>
                    <WithLabel text="Visualizar">
                        <RadioButton checked id="viewAll" name="TipoVisualizar" label="Todos" value="Todos" control={control} />
                        <RadioButton id="notAssigned" name="TipoVisualizar" label="Não atribuídos" value="NaoAtribuidos" control={control} />
                        <RadioButton id="assigned" name="TipoVisualizar" label="Atribuídos" value="Atribuidos" control={control} />
                    </WithLabel>
                </S.FlexLine>
                <S.FlexLine>
                    <Input id="Sequencial" name="Sequencial" control={control} label="Sequencial" error={errors.Sequencial?.message} />
                    <Input id="IdExterno" name="IdExterno" label="ID Externo" control={control} error={errors.IdExterno?.message} />
                    <Input id="NumeroProcesso" name="NumeroProcesso" label="Número do Processo" control={control} error={errors.NumeroProcesso?.message} />
                </S.FlexLine>
                <S.FlexLine>
                    <WithLabel text="Situação">
                        <RadioButton checked id="Ativos" name="TipoSituacao" label="Ativos" value="Ativos" control={control} />
                        <RadioButton id="Inativos" name="TipoSituacao" label="Inativos" value="Inativos" control={control} />
                        <RadioButton id="Ambos" name="TipoSituacao" label="Ambos" value="Ambos" control={control} />
                    </WithLabel>
                    <WithLabel text="Data de inclusão">
                        <Input
                            type="date"
                            id="DataInclusaoInicio"
                            name="DataInclusaoInicio"
                            error={errors.DataInclusaoInicio?.message}
                            control={control}
                        />
                        até
                        <Input type="date" id="DataInclusaoFim" name="DataInclusaoFim" error={errors.DataInclusaoFim?.message} control={control} />
                    </WithLabel>
                </S.FlexLine>
                <S.FlexLine>
                    <Select
                        label="Tipo Relação"
                        id={"idPessoaRelacao"}
                        name={"idPessoaRelacao"}
                        control={control}
                        options={
                            listaPessoaRelacao ?
                                listaPessoaRelacao.map((item: IObterListaPessoaRelacaoResponse) => (
                                    { value: item.id, label: item.descricao }
                                )) : []
                        }
                        isDefaultEnabled
                    />
                    <Input
                        type="text"
                        id="NomePessoaRelacao"
                        name="NomePessoaRelacao"
                        control={control}
                        error={errors.NomePessoaRelacao?.message}
                        label="Nome Pessoa Relação"
                    />
                </S.FlexLine>
                {
                    permiteInserir &&
                    !getClienteIntegracao() &&
                    <S.FloatNewButton>
                        <Button onClick={() => navigate("/casos-juridicos/dados")}>Novo</Button>
                    </S.FloatNewButton>
                }
                <S.ActionButtonsContainer>
                    <Button onClick={() => resetForm()}>Limpar</Button>
                    <Button type="submit">Pesquisar</Button>
                </S.ActionButtonsContainer>
                {
                    showResults && listaCasoJuridico && (
                        <>
                            <S.StyledHr />
                            <ItemsList>
                                <HeaderList items={[
                                    "Sequencial",
                                    "Nro. Processo",
                                    "Cliente Principal",
                                    "Parte Contrária",
                                    "Data Entrada"
                                ]} />
                                {
                                    listaCasoJuridico.map((item: any, index: any) => {
                                        return (
                                            <ListItem key={index} onClick={() => goDetail(item.id)}>
                                                <div>{item.numeroSequencial}</div>
                                                <div>{item.numeroProcesso}</div>
                                                <div>{item.nomeClientePrincipal}</div>
                                                <div>{item.nomeParteContrariaPrincipal}</div>
                                                <div>{convertDate(item.dataEntrada)}</div>
                                            </ListItem>
                                        )
                                    })
                                }
                            </ItemsList>
                            <Paginator
                                totalItens={totalItens}
                                totalPaginas={totalPaginas}
                                paginaAtual={paginaAtual}
                                change={setPaginaAtual}
                                first={() => setPaginaAtual(1)}
                                last={() => setPaginaAtual(totalPaginas)}
                            />
                        </>
                    )
                }
                {
                    noResults && (
                        <span>Nenhum resultado.</span>
                    )
                }
            </S.CaseSearchContent>
            {
                loading && (
                    <Loader />
                )
            }
        </Template>
    )
}

export default CaseSearch