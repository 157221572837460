import styled from "styled-components";

export const Content = styled.div`
    width: 100%;
    padding: 0 3rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: relative;
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
`

interface IGridItem {
    cols: number;
}

export const GridItem = styled.div<IGridItem>`
    display: grid;
    grid-template-columns: repeat(${props => props.cols},1fr);
    width: ${props => `${props.cols}fr`};
    column-gap: 3rem;
`

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    margin: 1rem 0;
`

interface IControlView {
    active: boolean;
}

export const ControlView = styled.form<IControlView>`
    display: ${props => props.active ? "flex" : "none"};
    flex-direction: column;
    gap: .5rem;
`

export const ButtonsContainer = styled.div`
    position: absolute;
    top: 1.5rem;
    right: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
`